<template>
  <div class="LogBox">
    <div class="">
      <el-form
        :model="queryParams"
        ref="queryForm"
        :inline="true"
        v-show="showSearch"
      >
        <el-form-item label="交易类型">
          <el-select
            v-model="queryParams.dealType"
            placeholder="请选择所属分类"
            clearable
            style="width: 240px"
          >
            <el-option
              v-for="dict in dealTypeList"
              :key="dict.value"
              :label="dict.name"
              :value="dict.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="交易时间">
          <el-date-picker
            v-model="tempTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00','23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="getTime"
            align="right" clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="资金变动渠道">
          <el-select
            v-model="queryParams.payMenType"
            placeholder="请选择所属分类"
            clearable
            style="width: 240px"
          >
            <el-option
              v-for="item in payTypeList"
              :label="item.name"
              :value="item.value"
              :key="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#f5f5f5',
          color: '#333333',
          fontWeight: '600',
        }"
        style="width: 100%"
      >
        <el-table-column prop="dealType" label="交易类型" width="180">
          <template slot-scope="scope">
            <div class="name-b">{{getTypeVal(dealTypeList,scope.row.dealType+'')}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="moneyChange" label="变动金额(元)" width="180">
          <template slot-scope="scope">
            <div
                    v-if='(scope.row.payType==="refund") || scope.row.dealTypeName=== "后台充值" || scope.row.dealTypeName=== "用户充值" || scope.row.dealTypeName=== "充值赠送"'
              style="color: #D54343;
              font-family: 'FangZhengZhengZhunHei';"
              >+{{scope.row.moneyChange}}</div>
            <div
                    v-if='scope.row.payType==="pay" && scope.row.dealTypeName!== "后台充值" && scope.row.dealTypeName!== "用户充值" && scope.row.dealTypeName!== "充值赠送"'
              style="color: #E99D42;
              font-family: 'FangZhengZhengZhunHei';"
            >-{{scope.row.moneyChange}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="realTimeBalance" label="实时金额(元)" width="180">
        </el-table-column>
        <el-table-column prop="payMenType" label="资金变动渠道" width="180">
          <template slot-scope="scope">
            <div class="name-b">{{getTypeVal(payTypeList,scope.row.payMenType+'')}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="dealTime" label="交易时间"> </el-table-column>
        <el-table-column prop="dealDetailed" label="交易说明"> </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          popper-class='select_bottom'>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { payList, rechargeLog } from "../../api/connectorList";
export default {
  data() {
    return {
      showSearch: true,
      queryParams: {
        userId:"",
        dealType: "",
        payMenType: "",
        pageNum: 1,
        pageSize: null,
        dealStartTime: null,
        dealEndTime: null,
      },
      tempTime:null,
      currentPage: 1,
      total: null,
      pageSize:10,
      tableData: [],
      dealTypeList: [],
      payTypeList: [],
      //时间选择器设置
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      userInfo:{}
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("user"));
    if(this.userInfo==null||this.userInfo.id==null||this.userInfo.id=='undefined'){
      this.$message.error("没有会员的登录信息!");
      return false;
    }
  },
  mounted() {
    this.getDealTypeList();
    this.getPayMenTypeList();
    this.getRechargeLog();
  },
  methods: {
    getDealTypeList() {
      var that = this;
      payList({ name: "DealType" }).then((res) => {
        that.dealTypeList = res.data.data;
      });
    },
    getPayMenTypeList() {
      payList({ name: "PayMenType" }).then((res) => {
        this.payTypeList = res.data.data;
      });
    },
    getRechargeLog() {
      var that = this;
      this.queryParams.userId = this.userInfo.id;
      this.queryParams.pageSize = this.pageSize;
      rechargeLog(this.queryParams).then((res) => {
        that.tableData = res.data.rows;
        that.total = res.data.total;
      });
    },
    //分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryParams.pageNum = 1;
      this.getRechargeLog()
    },
    handleCurrentChange(val) {
      this.queryParams.pageNum = val
      this.getRechargeLog()
    },

    /**
     * 获取日期组件时间
     */
    getTime(e) {
      if (null != e) {
        this.queryParams.dealStartTime = e[0];
        this.queryParams.dealEndTime = e[1];
      }else{
        this.queryParams.dealStartTime = "";
        this.queryParams.dealEndTime = "";
      }
    },
    //交易类型转换
    getTypeVal(arr,key){
      var name = "";
      arr.filter(function(item){
        if(item.value==key){
          name =  item.name
        }
      });
      return  name;
    },
    search() {
      this.getRechargeLog();
    },
    resetForm() {
      this.queryParams.dealType = ''
      this.queryParams.payMenType = ''
      this.queryParams.dealEndTime = null
      this.queryParams.dealStartTime = null
      this.getRechargeLog();
    },
  },
};
</script>

<style lang="less" scoped>
.LogBox {
  padding: 37px 23px;
  background-color: #fff;
  margin-bottom: 100px;
  .tableBox {
    margin-top: 20px;
  }
}
.block{
  height: 110px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/deep/ .el-input__inner:focus {
  border: #D54343 1px solid;
}
/deep/ .el-button--primary {
  color: #FFF;
  background-color: #D54343;
  border-color: #D54343;
}
/deep/ .el-button--primary:hover {
  background-color: #B72C2C;
  border-color: #B72C2C;
}
/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #D54343;
}
/deep/ .el-pager li.active {
  color: #D54343;
}
/deep/ .el-pager li:hover {
  color: #D54343;
}
/deep/ .el-pagination .btn-prev:hover {
  color: #D54343;
}
/deep/ .el-pagination .btn-next:hover {
  color: #D54343;
}
</style>
