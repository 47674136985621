<template>
    <div>
      <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          :disabled="!data.notesShow">
        <div slot="content">
          {{ data.notes }}
        </div>
        <div class="valueMaterials flex-j-c">
          <div class="imgItem" v-show="'' != data.notes && null != data.notes ">
            <img
                v-if="viewFlag == i"
                src="../../../static/icon/annotation.png"
                alt=""/>
            <img
                v-else
                src="../../../static/icon/annotation-null.png"
                alt=""/>
          </div>
          <div>{{ data.name }}</div>
          <div
              v-if="data.fileId != null"
              class="sample cursor"
              :class="viewFlag == i ? 'noSample' : ''"
              @click="sampleOpen(data.fileId)">
            示例图
          </div>
        </div>
      </el-tooltip>
      <el-dialog width="30%" :visible.sync="dialogVisible">
        <img width="100%" :src="newImg" alt="" />
      </el-dialog>
    </div>
</template>

<script>
import {getAttrFile} from "@/api/connectorList";

export default {
  name: "Btn",
  props: [
    "data","i","parent","viewFlag"
  ],
  data() {
    return {
      dialogVisible: false,
      newImg:'',
    };
  },
  created() {
    if(this.data.notes!=null&&this.data.notes!=''){
      this.data.notesShow = true;
    }
  },
  methods:{
    sampleOpen(id){
      getAttrFile({id:id}).then((res) => {
        // 实现预览功能
        this.dialogVisible = true;
        this.newImg = project.baseUrl + res.data.data.url;
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .valueMaterials {
    position: relative;
    padding: 10px 20px;
    width: 150px;
    .imgItem {
      margin-right: 5px;
    }
    .sample {
      border-radius: 0px 4px 0px 4px;
      padding: 2px 5px;
      position: absolute;
      font-size: 10px;
      top: 0;
      right: 0;
      color: rgba(51, 51, 51, 1);
      background-color: #cecece;
    }
    .noSample {
      color: #fff;
      background-color: #d54343;
    }
  }

.materialIsActiVe {
  border: 1px solid rgba(213, 67, 67, 1);
  color: rgba(213, 67, 67, 1);
}
.butBg {
  background-color: #d54343;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #d54343;
  border-color: #d54343;
}
/deep/.el-checkbox__inner:hover {
  border-color: #d54343;
}
/deep/.el-checkbox__label {
  color: #333;
}
/deep/.el-radio__inner:hover {
  border-color: #d54343;
}
/deep/.el-radio__label {
  color: #333;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  background-color: #d54343;
  border-color: #d54343;
}
/deep/.el-input__inner:focus {
  border-color: #d54343 !important;
}
/deep/.el-radio {
  display: flex;
  align-items: center;
}
/deep/.el-checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
/deep/.el-checkbox__label {
  display: flex;
  align-items: center;
}
/deep/.el-radio__label {
  display: flex;
  align-items: center;
}
/deep/ .el-dialog{
  width: 50%!important;
}
</style>
