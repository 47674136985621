<template>
  <div class="diaLogBox">
    <div style="position: relative; width: 100%; height: 100%">
      <div
        ref="getStyle"
        class="dialog"
        :style="{ marginLeft: -(width / 2) + 'px', marginTop: -(height / 2) + 'px' }"
      >
        <div class="title flex-j-sb">
          <p>{{ title }}</p>
          <img @click="cancel" class="cancel" src="../../static/icon/cancel.png" alt="" />
        </div>
        <div class="contentBox" v-if="rendered"><slot></slot></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: 0,
      width: 0,
      rendered:true,
    };
  },

  props: {
    title: String,
    default: null,
  },
  mounted() {
    this.$nextTick(function () {
      this.height = this.$refs.getStyle.offsetHeight;
      this.width = this.$refs.getStyle.offsetWidth;
    });
  },
  methods:{
    cancel(){
      let dialog  = false
      this.$emit('change', dialog);
    }
  }
};
</script>

<style lang="less" scoped>
.diaLogBox {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.4);
  .dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    .title {
      background: #d54343;
      padding: 15px 20px;
      color: #fff;
      .cancel {
        margin-left: 450px;
        cursor: pointer;
      }
    }

    .contentBox {
        box-sizing: border-box;
        width: 100%;
        background: #fff;
        padding: 28px 80px;
    }
  }
}
</style>