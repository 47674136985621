<template>
  <div class="jump flex-d-c">
    <img class="logo" src="../../static/logo.png" alt="" />
    <div class="content flex-d-c">
      <img style="margin-bottom: 21px;" src="../../static/icon/succeed.png" alt="" />
      <p v-if="status == 0" class="text">恭喜您注册成功</p>
      <p v-else-if="status == 1">恭喜您重置密码成功</p>
      <p v-else>恭喜您绑定成功</p>
    </div>
    <div v-if="status != 2" class="btnBox flex-j-sb">
      <div @click="toLogin">立即登录</div>
      <div>{{ time }}秒之后自动跳转登录页面</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: 3,
    };
  },
  props:{
    status:{
        type:Number,
        default:0,
    },
  },
  mounted(){
    this.validateBtn()
  },
  methods:{
    toLogin(){
        this.$router.push({ path: "/loginIndex/login" });
    },
    validateBtn() {
      //倒计时
      let time = this.time;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.toLogin()
        } else {
          this.time = time;
          time--;
        }
      }, 1000);
    },
  }
};
</script>

<style lang="less" scoped>
.jump {
  padding: 28px 100px;
  padding-bottom: 120px;
  .logo {
    width: 186px;
    margin-bottom: 50px;
  }
  .content {
    p{
        font-size: 18px;
        color: #D54343;
        font-weight: 600;
    }
  }
  .btnBox {
    margin-top: 37px;
    width: 100%;
    font-weight: 600;
  }
}
</style>