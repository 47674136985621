<template>
  <div class="typeBox">
    <!-- 材料 -->
    <div class="typeItemBox" v-if="goodsData!=null&&goodsData.length>0">
      <div class="typeItem flex-c" style="align-items: flex-start;">
        <div class="label" style="margin-top: 10px">{{ goodsData[0].oneName[0].name}}</div>
        <div class="utilBox" v-if="goodsData[0].oneName[0].beforeShowType.type==0">
          <div class="flex-c" style="flex-wrap: wrap">
            <div
                class="value flex-c cursor"
                v-for="(data, i) in goodsData[0].oneName[0].children"
                :key="data.id"
                :class="materialIsActiVe == i ? 'materialIsActiVe' : ''"
                @click="materialsClick(data, i)">
              <Btn :data="data" :viewFlag="materialIsActiVe" :i = "i" :parent="1"></Btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--尺寸-->
    <div class="typeItemBox"  v-if="goodsData!=null&&goodsData.length>1">
      <div class="typeItem flex-c">
        <div class="label" >{{ goodsData[1].oneName[0].name}}</div>
        <div  class="flex-c" style="flex-wrap: wrap">
          <div
              class="flex-c"
              style="margin-right: 15px"
              v-if="[4,6,7,9,11].indexOf(parseInt(goodsData[1].oneName[0].beforeShowType.type))>-1||
                  ([12,14,15,20,22,23,17,19,41,42].indexOf(parseInt(goodsData[1].oneName[0].beforeShowType.type))>-1&&sizeSelect == true)">
            <div v-if="goodsData[1].oneName[0].beforeShowType!=null&&goodsData[1].oneName[0].beforeShowType.label!=null"
                 class="sizeItemBox flex-c" style="margin-right: 0"
                 v-for="(data, i) in goodsData[1].oneName[0].beforeShowType.label"
                 :key="i">
              <el-input
                type="number"
                :min="0"
                v-if="sizeParams[i]!=null&&sizeParams[i].size!=undefined"
                v-model="sizeParams[i].size"
                :placeholder="data"
                style="width: 120px"
                :disabled="data.disabled" @input="sizeInputChangeTemp($event)"
                @change="sizeInputChange(sizeParams[i].size, i)"
              ></el-input>
              <div class="multiplication" v-if="goodsData[1].oneName[0].beforeShowType.label!=undefined&&goodsData[1].oneName[0].beforeShowType.label!=null&&i<goodsData[1].oneName[0].beforeShowType.label.length-1" style="margin: 0 10px"><img src="../../../static/multiple.png" /></div>
            </div>
          </div>
          <div
              class="flex-c cursor"
              style="margin-right: 15px"
              v-if="[3,14,15,17,19,41,42].indexOf(parseInt(goodsData[1].oneName[0].beforeShowType.type))>-1&&sizeSelect == false">
            <el-select
                v-model="sizeOption"
                placeholder="请选择尺寸">
              <el-option
                  v-for="(data, i) in sizeListes"
                  :label="data.name"
                  :value="data.id"
                  :key="i"
                  :disabled="data.disabled" @click.native="(val)=>sizeOptionBlur(data,i)"></el-option>
            </el-select>
          </div>
          <div v-if="[12,14,15,20,22,23,17,19,41,42].indexOf(parseInt(goodsData[1].oneName[0].beforeShowType.type))>-1">
            <el-checkbox v-model="sizeSelect" @change="sizeDefined">自定义</el-checkbox>
          </div>
        </div>
      </div>
    </div>

    <!--工艺-->
    <div class="typeItemBox" v-if="goodsData!=null&&goodsData.length>2">
      <div class="typeItem flex-c" style="align-items: flex-start;">
        <div class="label" >{{ goodsData[2].name}}</div>
        <div class="craftBox">
          <div
              class="craftItemBox flex-c"
              v-for="(v, vIndex) in goodsData[2].oneName"
              :key="vIndex">
            <div class="craftTitle">{{ v.name }}</div>
            <div class="flex-c" v-if="v.beforeShowType.type==1">
              <div class="sizeItemBox flex-c" style="margin-right: 40px;">
                <el-radio-group
                    v-model="craftRadioSelect[vIndex]"
                    class="flex-c">
                  <el-radio
                      v-for="(data, j) in v.children"
                      :key="j"
                      :disabled="data.disabled"
                      :label="data.id" @change="(val)=>radioChange(data,val,vIndex)">
                    <div>{{ data.name }}</div>
                  </el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="flex-c" v-if="v.beforeShowType.type==2">
              <el-checkbox-group v-model="craftCheckselectedArr" class="flex-c">
                <el-checkbox
                    v-for="(data,i) in v.children"
                    :label="data.id"
                    :key="i"
                    :disabled="data.disabled"
                    @change="checkChange(data, vIndex,i)">
                  <div>{{ data.name }}</div>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--数量-->
    <div class="typeItemBox" v-if="goodsData!=null&&goodsData.length>3">
      <div class="typeItem flex-c">
        <div class="label" >{{ goodsData[3].oneName[0].name}}</div>
        <div class="craftBox">
          <div class="flex-c">
            <div v-if="[4,42].indexOf(parseInt(goodsData[3].oneName[0].beforeShowType.type))>-1">
              <div style="margin-right: 15px">
                <el-input
                    type="number"
                    :min="0"
                    v-model="numInput"
                    placeholder="请输入数量"
                    @input="numChange"
                ></el-input>
              </div>
            </div>
            <div class="utilBox" v-if="[0,42].indexOf(parseInt(goodsData[3].oneName[0].beforeShowType.type))>-1">
              <div class="flex-c" style="flex-wrap: wrap">
                <div
                    class="value flex-c cursor "
                    :class="sizeIsActiVe == i ? 'materialIsActiVe' : ''"
                    v-for="(data, i) in goodsData[3].oneName[0].children"
                    :key="i"
                    style="margin-right: 15px"
                    @click="sizeValueClick(data, i)">
                  <div class="el-tooltip valueMaterials flex-j-c item">
                    <div>{{ data.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="calculate">
      <div class="calculateBtn cursor" @click="calculate">计算价格</div>
    </div>
    <div class="typeItemBox totalPriceBox" v-if="countPriceShow">
      <div class="typeItem flex-c">
        <div class="label">官网价格</div>
        <p style="color: #d54343">
            <span style="font-size: 12px">￥</span
            ><span style="font-size: 32px;font-family: FangZhengZhengZhunHei;">{{
            countPriceData.totalPrice
          }}</span>
        </p>
      </div>
      <div class="typeItem flex-c">
        <div class="label">人工接单</div>
        <p style="color: #9a9a9a; text-decoration: line-through">
          <span style="font-size: 14px">￥{{ countPriceData.manOrders }}</span>
        </p>
      </div>
      <div class="typeItem flex-start">
        <div class="label">报价明细</div>
        <div class="priceDetail">
          <p style="font-weight: 600">{{ countPriceData.fileName }} - {{ countPriceData.aboutWeight }}</p>
          <p style="font-size: 12px; color: #d54343">
            {{ countPriceData.aboutTime }}
          </p>
        </div>
      </div>
    </div>
    <LoginWhole :visible.sync="dialogVisible" ref="viewLogin"></LoginWhole>
  </div>
</template>

<script>
import { getRelationsData,countPrice,getCraftRelationsByParam } from "../../api/connectorList";
export default {
  components: {},
  props: [
    "goodsData", "productData","dialogFlag","goodIndex"
  ],
  data() {
    return {
      userInfo: null,
      materialIsActiVe: 0,parent:0,type:null,
      //禁选关系暂存数组
      btnSelect:[],
      sizeDataSelect:[],
      craftRadioSelect:[],
      craftRadioSelectMap:[],
      craftCheckselectedArrMap:[],
      //尺寸值
      sizeSelect:false,
      sizeParams: [],
      sizeListes:[],
      sizeOption:null,
      sizeIsActiVe:0,
      //选中材料
      materialId:null,
      //选中尺寸：
      sizeId:null,
      //数量：
      number:null,
      numInput:null,
      //选中工艺id数组
      craftCheckselectedArr:[],
      craftIds:[],
      sizeType:[],
      countPriceShow:false,
      countPriceData:null,
      //弹出登录
      dialogVisible:false,
      showConfirm: false,
    };
  },
  watch: {
    goodsData: function (val) {
      console.log("刷新视图......",val);
      this.goodsData = val;
      this.$set(this,"goodsData",val);
      if(val!=null&&val.length>0) {
        var that = this;
        this.$nextTick(() => {
          that.clearData();
          that.initDatas(val);
          if(this.dialogFlag){
            that.viewEchoData(this.goodIndex,this.productData.id);
          }
        })
      }
    },
  },
  created() {
    var that = this;
    this.$nextTick(()=>{
      console.log("进入create清除");
      that.clearData();
      that.initDatas(that.goodsData);
    })
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    initDatas(val){
      //点击材料第一个按钮
      this.materialsClick(val[0].oneName[0].children[0], 0);
      //初始化自定义尺寸
      if (this.goodsData!=null&&this.goodsData.length>1) {
        this.sizeListes = this.goodsData[1].oneName[0].children;
        if(this.sizeParams.length==0
            &&this.goodsData[1].oneName[0].beforeShowType.label!=undefined
            &&this.goodsData[1].oneName[0].beforeShowType.label!=null
            &&this.goodsData[1].oneName[0].beforeShowType.label.length>0){
          for (let i = 0; this.goodsData[1].oneName[0].beforeShowType.label.length > i; i++) {
            this.sizeParams.push({ size: "" });
          }
        }
      }
      //初始化尺寸下拉
      if(val.length>1){
        console.log(this.sizeListes,"this.sizeLists");
        this.sizeListes = val[1].oneName[0].children;
      }
      //点击第一个数量按钮
      if(val.length>3&&val[3].oneName[0].children!=null){
        console.log(val[3].oneName[0].children,"val[3].oneName[0].children");
        this.sizeValueClick(val[3].oneName[0].children[0], 0);
      }
    },
    clearData(){
      this.materialId = null;

      this.sizeId = null;
      this.sizeSelect = false;
      this.$set(this,"sizeParams",[]);
      this.$set(this,"sizeOption",null);
      this.$set(this,"sizeType",[]);
      console.log(this.craftRadioSelect,"craftRadioSelect");
      if(this.craftRadioSelect!=undefined
          &&this.craftRadioSelect!=null&&this.craftRadioSelect.length>0){
        this.$set(this,"craftRadioSelect",[]);
      }
      console.log(this.craftCheckselectedArr,"craftCheckselectedArr");
      if(this.craftCheckselectedArr!=undefined
          &&this.craftCheckselectedArr!=null&&this.craftCheckselectedArr.length>0){
        this.$set(this,"craftCheckselectedArr",[]);
      }

      this.$set(this,"craftIds",[]);

      this.numInput = null;

      this.countPriceShow = false;

      this.$emit("setCountPriceShow", false,[], null);
    },
    mustFillValid(){
      //判断每一行是否有选项
      var craftDataTempList = this.goodsData[2].oneName;
      console.log(craftDataTempList,"进入价格1");
      var radioIdArr = [];
      console.log(this.craftRadioSelect,"this.craftRadioSelect");
      for(var m = 0;m<this.craftRadioSelect.length;m++){
        if(this.craftRadioSelect[m]!==undefined
            &&this.craftRadioSelect[m]!=null&&this.craftRadioSelect[m]!=''){
          radioIdArr.push(this.craftRadioSelect[m]);
        }
      }
      var errMessage = "";
      for(var k = 0;k<craftDataTempList.length;k++){
        var temp = craftDataTempList[k].beforeShowType.type ;
        var obj = craftDataTempList[k].children;
        var name = craftDataTempList[k].name;
        if(temp==1){
          //如果单选数组中没有包含
          // if(this.craftRadioSelect[k]===undefined||this.craftRadioSelect[k]==null
          //     ||this.craftRadioSelect[k]==''){
          //   console.log(name+'没有选中');
          //   errMessage +=name+" ";
          //   continue;
          // }
          var flag = false;
          var forbidCount = 0;
          for(var l = 0;l<obj.length;l++){
            console.log(obj[l].id,"obj[l]");
            if(obj[l].disabled!=null&&obj[l].disabled){
              forbidCount ++;
            }
            if(radioIdArr.indexOf(obj[l].id)>-1){
              flag = true;
            }
          }
          console.log(forbidCount,"forbidCount");
          console.log(obj.length,"obj.length");
          console.log(flag,"flag");
          if(forbidCount!=obj.length&&!flag){
            errMessage +=name+" ";
          }
        }
      }
      if(errMessage!=''){
        this.$alert(errMessage+'每项至少要选择一个', '小提示', {
          confirmButtonText: '确定',
          callback: action => {}
        });
        return false;
      }
      return true;
    },
    viewEchoData(index,val){
      console.log("要回显的productData：",this.productData);
      if(this.productData&&localStorage.getItem("goods_"+index+"_"+val)){
        var obj = JSON.parse(localStorage.getItem("goods_"+index+"_"+val));
        this.$set(this,"materialIsActiVe",obj.materialIsActiVe);
        this.$set(this,"materialId",obj.materialId);
        this.$set(this,"sizeParams",obj.sizeParams);
        this.$set(this,"craftRadioSelect",obj.craftRadioSelect);
        this.$set(this,"craftCheckselectedArr",obj.craftCheckselectedArr);
        this.$set(this,"numInput",obj.numInput);
        this.$set(this,"sizeOption",obj.sizeOption);
        this.$set(this,"sizeSelect",obj.sizeSelect);
        this.$set(this,"sizeIsActiVe",obj.sizeIsActiVe);
        console.log("回显产品选项值：",obj);
      }
    },
    saveEchoData(goodsIndex,id){
      var obj = {};
      obj.materialIsActiVe = this.materialIsActiVe;
      obj.materialId = this.materialId;
      obj.sizeParams = this.sizeParams;
      obj.craftRadioSelect = this.craftRadioSelect;
      obj.craftCheckselectedArr = this.craftCheckselectedArr;
      obj.numInput = this.numInput;
      obj.sizeOption = this.sizeOption;
      obj.sizeSelect = this.sizeSelect;
      obj.sizeIsActiVe = this.sizeIsActiVe;
      localStorage.setItem("goods_"+goodsIndex+"_"+id,JSON.stringify(obj));
      console.log("保存产品选项","goods_"+goodsIndex+"_"+id);
      console.log("保存产品选项的值：",JSON.parse(localStorage.getItem("goods_"+goodsIndex+"_"+id)));
    },
    calculate(){
      //判断是否登录
      if(this.userInfo==undefined||this.userInfo==null||this.userInfo.id==null){
        if(this.dialogVisible){
          this.dialogVisible = false;
        }
        this.dialogVisible = true;
        return false;
      }
      this.$set(this,"sizeType",[]);
      this.$set(this,"craftIds",[]);
      for(var m = 0;m<this.sizeParams.length;m++){
        var t = this.sizeParams[m];
        if(t!=null&&t.size!=null&&t.size!=''){
          this.sizeType.push(t.size);
        }
      }
      for(var m = 0;m<this.craftRadioSelect.length;m++){
        var t = this.craftRadioSelect[m];
        if(t==undefined||t==null){
          continue;
        }
        var temp = {"id":t,"type":"","children":[]};
        this.craftIds.push(temp);
      }
      for(var m = 0;m<this.craftCheckselectedArr.length;m++){
        var t = this.craftCheckselectedArr[m];
        var temp = {"id":t,"type":"","children":[]};
        this.craftIds.push(temp);
      }
      console.log(this.productData,"工艺参数合起来");
      let params = {
        memberId: this.userInfo.id,
        memberName: this.userInfo.name,
        prodcutName: this.productData.name,
        prodcutCode: this.productData.code,
        prodcutId: this.productData.id,
        floatRatio: this.productData.floatRatio,
        materialId: this.materialId,
        sizeId: this.sizeId,
        sizeType: this.sizeType,
        craftIds: this.craftIds,
        numberId: null,
        number: this.numInput,
      };
      if((this.sizeId==null||this.sizeId=="")&&(this.sizeType==null||this.sizeType.length==0)){
        this.$alert('请选择或者填写尺寸', '小提示', {
          confirmButtonText: '确定',
          callback: action => {}
        });
        return false;
      }
      if(this.numInput==null||this.numInput==""){
        this.$alert('请选择或者填写数量', '小提示', {
          confirmButtonText: '确定',
          callback: action => {}
        });
        return false;
      }
      if(!this.mustFillValid()){
        return false;
      }
      countPrice(params).then((res) => {
        if(res.data.code == 200){
          this.countPriceData = res.data.data
          this.countPriceShow = true
          this.showConfirm = true;
          this.$emit("setCountPriceShow", this.countPriceShow, res.data.data, this.numInput, this.showConfirm);
        }
      });
    },
    numChange(e){
      let boolean = new RegExp("^[1-9][0-9]*$").test(e);
      if(!boolean && e.length>0){
        this.$message.warning('请输入正整数数字！');
        setTimeout(()=>{
          this.numInput = null;
        },500)
        return;
      }
      this.countPriceData = []
      this.countPriceShow = false;
      this.$emit("setCountPriceShow", false,[], null);
      //如果和集合name对不上，去掉高亮
      var tempArr = this.goodsData[3].oneName[0].children;
      if(tempArr!=null&&tempArr.length>0){
        var flag = false;
        for(var i = 0;i<tempArr.length;i++){
          var temp = parseInt(tempArr[i].name);
          if(temp==e){
            flag = true;
            this.sizeIsActiVe = i;
            break;
          }
        }
        if(!flag){
          this.sizeIsActiVe = -1;
        }
      }
    },
    sizeValueClick(val, index) {
      console.log(val);
      console.log(index);
      //清除价格
      this.countPriceData = [];
      this.countPriceShow = false;
      this.$emit("setCountPriceShow", false,[], null);

      this.numInput = val.name;
      this.sizeIsActiVe = index;
      //this.$emit("numIndex", val.name);
    },
    sizeInputChange(e, index) {
      if ( e <= 0 ){
        this.$message.warning('尺寸数字不能小于0！');
        let index = this.sizeParams.findIndex(item=>item.size == e);
        setTimeout(()=>{
          this.sizeParams[index].size = '';
        },500)
        return;
      }
      //清除价格
      this.countPriceData = []
      this.countPriceShow = false;
      this.$emit("setCountPriceShow", false,[], null);
      for (let i = 0; this.sizeParams.length > i; i++) {
        if (this.sizeParams[i] == index) {
          this.sizeParams[i].size = e;
        }
      }
      //this.$emit("priceParamsFrom", this.sizeParams, 2);
    },
    sizeInputChangeTemp(e){
      this.countPriceData = []
      this.countPriceShow = false;
      this.$emit("setCountPriceShow", false,[], null);
    },
    getChildData(val){
      //构造参数，传递，请求
      let arry2 = [];
      arry2.push({priceId: val.price.id,ralationItem: val.id})
      let parame = {
        prodcutCode: val.prodcutCode,
        itemDatas: arry2
      };
      getCraftRelationsByParam(parame).then((res) => {
        let datas = res.data.data
        let radioData = null;
        console.log(datas,"下级关系");
      })
    },
    radioChange(data,val,index){
      //this.getChildData(val);
      //清除价格
      this.countPriceData = []
      this.countPriceShow = false;
      this.$emit("setCountPriceShow", false,[], null);
      this.proessRelations(data,index,"radio",null);
    },

    //****清除禁选****
    //val:当前操作的控件值
    //selectedAttr:缓存中已禁选的数组
    //index:第几组工艺，
    //flag:切换的按钮类型
    // itemIndex:选的是第几个(多选用)
    proessRelations:function (val,index,flag,itemIndex){
      console.log(index);
      console.log(itemIndex);
      var selectedAttr = this.craftRadioSelectMap;
      //如果点击的材料按钮
      if(itemIndex==-9999){
        selectedAttr = this.btnSelect;
      }
      //如果点击的是尺寸按钮
      if(itemIndex==-8888){
        selectedAttr = this.sizeDataSelect;
        console.log(selectedAttr,"切换尺寸");
      }
      var tempFlag = this.clearDisabled(val,selectedAttr,flag,index,itemIndex);
      if(!tempFlag){
        return false;
      }
      var that = this;
      console.log(val);
      let arry2 = [];
      arry2.push({priceId: val.price.id,ralationItem: val.id})
      let parame = {
        prodcutCode: val.prodcutCode,
        itemDatas: arry2
      };
      console.log(parame,"parame");
      getRelationsData(parame).then((res) => {
        let datas = res.data.data
        let radioData = null;
        console.log(datas.nRelations,"nRelations");
        //如果是切换材料按钮,尺寸上要加禁选
        if(itemIndex==-9999&&that.goodsData.length>1){
          console.log(that.goodsData[1].oneName,"console.log(that.goodsData[1].oneName);");
          that.addDisabled(datas,flag,that.goodsData[1].oneName,index,itemIndex,1);
        }
        if(that.goodsData.length>2){
          console.log(that.goodsData[2].oneName,"console.log(that.goodsData[1].oneName);");
          that.addDisabled(datas,flag,that.goodsData[2].oneName,index,itemIndex,2);
        }
      })
    },
    //****清除禁选****
    //val:当前操作的控件值
    //selectedAttr:缓存中已禁选的数组
    //flag:切换的按钮类型
    //index:第几组工艺，
    // itemIndex:选的是第几个(多选用)
    clearDisabled(val,selectedAttr,flag,index,itemIndex){
      //单选同级切换
      if(flag=='radio'){
        if(selectedAttr!=null&&selectedAttr.length>0){
          var tem = selectedAttr.slice();
          console.log(tem,"进入tem尺寸");
          for(var p = 0;p<tem.length;p++){
            if(tem[p].index==index&&tem[p].disabled){
              //尺寸模块、工艺模块清除禁选
              for(var i = 1;i<=2;i++){
                //console.log(i,"循环i=1");
                var tempParentArr = this.goodsData[i].oneName;
                c:for(var m = 0;m<tempParentArr.length;m++){
                  if((itemIndex==null&&m!=index)||itemIndex==-9999||itemIndex==-8888){
                    //console.log("不同一级切换-前面是禁选-尺寸切换");
                    if(tempParentArr[m]==null||tempParentArr[m].children==null||tempParentArr[m].children.length==0){
                      continue c;
                    }
                    var tempChildArr = tempParentArr[m].children;
                    console.log(tempChildArr,"tempChildArr");
                    console.log(tem,"tem");
                    console.log(tem[p],"tem[p]");
                    for(var j = 0;j<tempChildArr.length;j++){
                      if(tempChildArr[j].id==tem[p].id){
                        this.$set(this.goodsData[i].oneName[m].children[j],'disabled',false);
                        if(itemIndex==-9999){
                          this.btnSelect.splice(p,1);
                        }else if(itemIndex==-8888){
                          this.sizeDataSelect.splice(p,1);
                        }else{
                          this.craftRadioSelectMap.splice(p,1);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      //默认checkbox为工艺模块：如果点击的还是该控件，并且是多选清空
      if(flag=='checkbox'&&!this.craftCheckselectedArr.includes(val.id)){
        if(this.craftCheckselectedArrMap!=null&&this.craftCheckselectedArrMap.length>0){
          var tem = this.craftCheckselectedArrMap.slice();
          console.log(tem,"temcheckbox");
          for(var p = 0;p<tem.length;p++){
            if(tem[p].rowIndex==index&&tem[p].itemIndex==itemIndex){
              console.log("同一个checkbox清空");
              var tempParentArr = this.goodsData[2].oneName;
              for(var m = 0;m<tempParentArr.length;m++){
                if(m!=index){
                  console.log("不同一级切换-前面是禁选");
                  var tempChildArr = this.goodsData[2].oneName[m].children;
                  for(var j = 0;j<tempChildArr.length;j++){
                    console.log("同级切换-前面是禁选",tempChildArr[j].id,tem[p].id);
                    if(tempChildArr[j].id==tem[p].id){
                      this.$set(this.goodsData[2].oneName[m].children[j],'disabled',false);
                      this.craftCheckselectedArrMap.splice(p,1);
                    }
                  }
                }
              }
            }
          }
        }
        return false;
      }
      return true;
    },
    //****添加联动禁选****
    //datas：禁选的对象，属性为nRelations
    //flag:切换的按钮类型
    //arr1:要在页面赋禁选值的数组
    //index:第几组（目前工艺才有多组）
    //itemIndex:选的是第几个(多选用)（目前工艺才有多组）
    //ii:要添加禁选效果的索引
    addDisabled(datas,flag,arr1,index,itemIndex,ii){
      if (datas.nRelations.length > 0) {
        for (let y = 0; datas.nRelations.length > y; y++) {
          b:for(let p = 0;p<arr1.length;p++){
            //校验
            if(arr1[p]==null||arr1[p].children==null||arr1[p].children.length==0){
              continue b;
            }
            var subArr1 = arr1[p].children;
            for(let j = 0;j<subArr1.length;j++){
              //第j个元素
              if (subArr1[j].id == datas.nRelations[y].settingId) {
                if(flag=='radio'){
                  //如果是材料按钮
                  if(itemIndex==-9999) {
                    var m = {'group': p, 'id': subArr1[j].id, 'disabled': true, 'index': 0};
                    this.btnSelect.push(m);
                    //下拉尺寸选择
                  }else if(itemIndex==-8888){
                    var m = {'group': p, 'id': subArr1[j].id, 'disabled': true, 'index': 0};
                    this.sizeDataSelect.push(m);
                    //工艺中的单选
                  }else {
                    var m = {'group': p, 'id': subArr1[j].id, 'disabled': true, 'index': index};
                    this.craftRadioSelectMap.push(m);
                  }
                }else if(flag=='checkbox'){
                  var m = {'group':p,'id':subArr1[j].id,'disabled':true,'rowIndex':index,'itemIndex':itemIndex};
                  this.craftCheckselectedArrMap.push(m);
                }
                this.$set(this.goodsData[ii].oneName[p].children[j],'disabled',true);
                //if(flag=='radio'){
                  if(this.craftRadioSelect!=null&&this.craftRadioSelect.length>0){
                    console.log(this.craftRadioSelect,"this.craftRadioSelect");
                    for(var mm = 0;mm<this.craftRadioSelect.length;mm++){
                      if(this.craftRadioSelect[mm]==undefined||this.craftRadioSelect[mm].id==undefined){
                        continue;
                      }
                      if(subArr1[j].disabled&&this.craftRadioSelect[mm].id==subArr1[j].id){
                        this.$set(this.craftRadioSelect,mm,null);
                      }
                    }
                  }
                //}else if(flag=='checkbox'){
                  if(this.craftCheckselectedArr!=null&&this.craftCheckselectedArr.length>0){
                    console.log(this.craftCheckselectedArr,"this.craftCheckselectedArr");
                    for(var mm = 0;mm<this.craftCheckselectedArr.length;mm++){
                      if(this.craftCheckselectedArr[mm]==undefined){
                        continue;
                      }
                      if(subArr1[j].disabled&&this.craftCheckselectedArr[mm]==subArr1[j].id){
                        this.$set(this.craftCheckselectedArr,mm,null);
                      }
                    }
                  }
                //}
              }
            }
          }
        }
      }
    },
    checkChange(data, i,itemIndex){
      console.log(this.craftCheckselectedArr);
      console.log(data,i);
      //清除价格
      this.countPriceData = [];
      this.countPriceShow = false;
      this.$emit("setCountPriceShow", false,[], null);
      this.proessRelations(data,i,"checkbox",itemIndex);
    },
    // 点击自定义
    sizeDefined(e) {
      this.sizeSelect = e;
      console.log(e,"自定义");
      this.$set(this,"sizeType",[]);
      if (e == true) {
        //处理
      } else {
        if (this.goodsData!=null&&this.goodsData.length>1) {
          this.sizeListes = this.goodsData[1].oneName[0].children;
          this.$set(this,"sizeParams",[]);
          for (let i = 0; this.goodsData[1].oneName[0].beforeShowType.label.length > i; i++) {
            this.sizeParams.push({ size: "" });
          }
          console.log(this.sizeParams,"清空sizeParams");
        }
      }
    },
    // 尺寸下拉失去焦点
    sizeOptionBlur(data,index) {
      console.log(data,index);
      this.sizeId = data.id;
      //清除价格
      this.countPriceData = [];
      this.countPriceShow = false;
      this.$emit("setCountPriceShow", false,[], null);

      this.proessRelations(data,0,"radio",-8888);
    },
    // 按钮事件
    materialsClick(val, index) {

      //清除工艺
      if(this.craftRadioSelect!=undefined&&this.craftRadioSelect.length>0) {
        this.$set(this,"craftRadioSelect",[]);
      }
      if(this.craftCheckselectedArr!=undefined&&this.craftRadioSelect.length>0) {
        this.$set(this,"craftCheckselectedArr",[]);
      }

      //清除价格
      this.countPriceData = [];
      this.countPriceShow = false;
      this.$emit("setCountPriceShow", false,[], null);

      //样式
      this.materialIsActiVe = index;
      this.materialId = val.id;
      console.log(this.materialId,"自动点击");
      this.proessRelations(val,0,"radio",-9999);
    },
  }
};
</script>

<style lang="less" scoped>
.typeBox {
  .typeItemBox {
    .typeItem {
      margin-bottom: 22px;
      .label {
        box-sizing: border-box;
        padding: 10px 0;
        width: 110px;
        background-color: rgba(255, 232, 232, 1);
        color: rgba(51, 51, 51, 1);
        text-align: center;
        border-radius: 4px;
        margin-right: 18px;
      }
      .craftBox {
        width:calc(100% - 160px);
        .craftItemBox {
          margin-bottom: 20px;
        }
        .craftTitle {
          border-radius: 4px;
          background-color: #f5f5f5;
          color: rgba(153, 153, 153, 1);
          text-align: center;
          padding: 10px 15px;
          font-size: 12px;
          margin-right: 20px;
        }
        .craftItemBox:last-child {
          margin-bottom: 0px;
        }
      }
      .priceDetail {
        padding: 10px 20px;
        background-color: #f8f8f8;
        width: 90%;
      }
    }
  }
  .totalPriceBox {
    margin-top: 30px;
  }
  .calculate {
    margin-top: 30px;
    margin-left: 128px;
    .calculateBtn {
      width: 140px;
      background-color: #d54343;
      color: #fff;
      text-align: center;
      padding: 10px 0;
      border-radius: 4px;
    }
    .calculateBtn:hover{
      background-color: #b72c2c;
    }
  }
}
.sizeItemBox {
  width: 150px;
  margin-right: 15px;
}

.utilBox {
  width:calc(100% - 160px);
  .value {
    margin-right: 10px;
    border: 1px solid rgba(206, 206, 206, 1);
    border-radius: 4px;
    margin-top: 10px;
    .valueMaterials{
      position: relative;
      padding: 10px 20px;
      width:150px;
    }
  }
  .materialIsActiVe {
    border: 1px solid rgba(213, 67, 67, 1);
    color: rgba(213, 67, 67, 1);
  }
}
/deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #D54343;
}
/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #D54343;
}
/deep/.el-radio__inner:hover {
  border-color: #d54343;
}
/deep/.el-radio__label {
  color: #333;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  background-color: #d54343;
  border-color: #d54343;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #d54343;
  border-color: #d54343;
}
/deep/.el-input__inner:focus {
  border-color: #d54343 !important;
}
/deep/.el-radio {
  display: flex;
  align-items: center;
}
/deep/ .el-aside{
  background-color: #282828;
}
</style>
