<template>
  <div>
    <div class="tabsBox">
      <div class="tabsName">
        <p
          v-for="(item, index) in loginTag"
          :key="index"
          :class="isActiVe == index ? 'isActiVe' : ''"
          @click="switchLogin(index)"
        >
          {{ item.name }}
        </p>
      </div>
      <div
        class="active"
        :style="isActiVe == 0 ? 'left: 25px;' : 'left: 195px;'"
      ></div>
    </div>
    <el-form
      v-if="isActiVe == 0"
      label-position="left"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="0px"
      class="demo-ruleForm"
      :validate-on-rule-change="false"
    >
      <el-form-item prop="phone">
        <el-input
          type="text"
          v-model="ruleForm.phone"
          auto-complete="off"
          placeholder="手机号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          auto-complete="off"
          placeholder="（6-16个字符，包含字母和数字）"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-form
      v-else
      label-position="left"
      :model="smsForm"
      :rules="smsRules"
      ref="smsForm"
      label-width="0px"
      class="demo-ruleForm"
      :validate-on-rule-change="false"
    >
      <el-form-item prop="phone">
        <el-input
          type="text"
          v-model="smsForm.phone"
          auto-complete="off"
          placeholder="手机号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <div class="flex-j-sb">
          <div style="width: 224px">
            <el-input
              type="number"
              v-model="smsForm.code"
              auto-complete="off"
              placeholder="验证码"
            ></el-input>
          </div>
          <div
            class="smsText"
            @click="getCode"
            :class="disabled == true ? 'disabled' : ''">
            {{ smsText }}
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="loginOption flex-j-sb">
      <div class="flex-j-sb">
        <p style="margin-right: 20px" @click="to(1)">免费注册</p>
        <p @click="to(2)" v-if="num !== '1'">忘记密码</p>
      </div>
      <p @click="to(2)" v-if="num == '1'">忘记密码</p>
      <p @click="to(3)" v-else>游客看看</p>
    </div>
    <div v-if="isActiVe == 0" class="loginBtnBox">
      <div style="width: 100%" class="loginBtn flex-j-c" @click="submitForm('ruleForm')">
        登录
      </div>
    </div>
    <div v-else class="loginBtnBox">
      <div style="width: 100%" class="loginBtn flex-j-c" @click="submitSmsForm('smsForm')">
        登录
      </div>
    </div>
    <div class="authBox flex-j-c">
      <div class="wechat flex-j-c" @click="wechat">
        <img src="../../../static/login/wechat.png" alt="" />
      </div>
      <div class="qq flex-j-c">
        <img src="../../../static/login/QQ.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { login, sendSms,smsLogin } from "../../api/connectorList";
export default {
  props: ['num'],
  data() {
    const checkTelPhone = (rule, value, callback) => {
      if (value) {
        let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      rememberpwd:true,
      isActiVe: 0,
      smsText: "获取验证码",
      disabled: false,
      loginTag: [
        {
          name: "账号登录",
        },
        {
          name: "验证码登录",
        },
      ],
      ruleForm: {
        //username和password默认为空
        phone: "",
        password: "",
      },
      //rules前端验证
      rules: {
        phone: [
            { required: true, message: "请输入手机号", trigger: ["change"] },
            { required: true,trigger: "blur",message: '手机号不合法',validator: checkTelPhone}
        ],
        password: [{ required: true, message: "请输入密码", trigger: ["blur","change"] }],
      },
      smsForm: {
        phone: "",
        code: "",
      },
      //rules前端验证
      smsRules: {
        phone: [
            { required: true, message: "请输入手机号", trigger: ["change" ]},
            { required: true,trigger: "blur",message: '手机号不合法',validator: checkTelPhone}
        ],
        code: [{ required: true, message: "验证码不正确", trigger: ["blur","change"] }],
      },
    };
  },
  created() {
    // 获取图形验证码
    // this.getcode()
    // 获取存在本地的用户名密码
  },
  mounted() {
  },
  methods: {
    switchLogin(index) {
      this.isActiVe = index;
    },
    getCode() {
      if (this.smsForm.phone != "") {
        this.validateBtn();
        // 注释
        sendSms({ telphone: this.smsForm.phone }).then((res) => {
          let userInfo = res.data.data.member
          if (res.data.code == "200") {
          } else {
            this.$message.error(res.data.msg);
            return false;
          }
        });
      } else {
        this.$message.error("请填写手机号");
      }
    },
    validateBtn() {
      //倒计时
      let time = 60;
      this.smsText = time + "秒后重试";
      this.disabled = true;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.smsText = "获取验证码";
        } else {
          this.smsText = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    // 获取用户名密码
    // getuserpwd() {
    //   if (getCookie("user") != "" && getCookie("pwd") != "") {
    //     this.ruleForm.phone = getCookie("user");
    //     this.ruleForm.password = getCookie("pwd");
    //     this.rememberpwd = true;
    //   }
    // },
    //获取info列表
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            telphone: this.ruleForm.phone,
            password: this.ruleForm.password,

          };
          // 注释
          login(params).then((res) => {
            if (res.data.code == "200") {
              let userInfo = res.data.data.member
              //如果请求成功就让他2秒跳转路由
              setTimeout(() => {
                  this.$message({
                    message: "登录成功",
                    type: "success",
                  });
                // 缓存token
                localStorage.setItem('user', JSON.stringify(userInfo));
                localStorage.setItem("token", res.data.data.token);
                this.$store.commit("login", "true");
                //如果是游客在弹出界面提交
                var currentUrl = this.$route.path;
                if(currentUrl.indexOf("goods")!=-1){
                  this.$router.go(0);
                }else{
                  this.$router.push({ path: "/goods" }, () => {});
                }
              }, 100);
            } else {
              this.$message.error(res.data.msg);
              // this.logining = false;
              return false;
            }
          });
        } else {
          // 获取图形验证码
          // this.getcode()
          this.$message.error("请输入正确的手机号及密码！");
          this.logining = false;
          return false;
        }
      });
    },
    submitSmsForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.logining = true;
          // 测试通道，不为空直接登录
          // setTimeout(() => {
          //   this.logining = false
          //   this.$store.commit('login', 'true')
          //   this.$router.push({ path: '/goods' })
          // }, 1000)
          let params = {
            telphone: this.smsForm.phone,
            code: this.smsForm.code,
          };
          // 注释
          smsLogin(params).then((res) => {
            if (res.data.code == "200") {
              let userInfo = res.data.data.member
              //如果请求成功就让他2秒跳转路由
              setTimeout(() => {
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                // 缓存token
                localStorage.setItem('user', JSON.stringify(userInfo));
                localStorage.setItem("token", res.data.data.token);
                this.$store.commit("login", "true");
                this.$router.push({ path: "/goods" });
              }, 1000);
            } else {
              this.$message.error(res.data.msg);
              // this.logining = false;
              return false;
            }
          });
        } else {
          // 获取图形验证码
          // this.getcode()
          this.$message.error("请输入规范的手机号和验证码！");
          this.logining = false;
          return false;
        }
      });
    },
    wechat() {
      this.$emit("isWechat", true);
    },
    to(name) {
      if(this.num == 1) {
        if(name == 1) {
          this.$router.push({path: 'loginIndex/register'})
        } else if(name == 2) {
          this.$router.push({path: 'loginIndex/resetpwd'})
        }
      } else {
        if(name == 1) {
          this.$router.push({path: 'register'})
        } else if(name == 2) {
          this.$router.push({path: 'resetpwd'})
        } else if(name == 3) {
          localStorage.setItem("temporaryUser","true");
          this.$emit("viewDialog", false);
          this.$router.push({path: '/goods'})
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.login-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #d54343;
  position: relative;
  /* background-repeat: no-repeat; */
  /* background-position: center right; */
  /* background-size: 100%; */
  .loginWrap-top {
    margin-bottom: 32px;
    .QR-box {
      width: 100%;
      margin-bottom: 6px;
      img {
        width: 42px;
        cursor: pointer;
      }
    }
    .logo {
      width: 100%;
      img {
        width: 186px;
      }
    }
  }
}

.login-container {
  margin: 0px auto;
  position: absolute;
  top: 22%;
  left: calc(50% - 240px);
  width: 460px;
  padding: 10px;
  background: #fff;
  text-align: left;
}
.tabsBox {
  width: 250px;
  margin: 0 auto;
  font-size: 18px;
  font-family: SourceHanSansSC-regular;
  position: relative;
  .tabsName {
    display: flex;
    justify-content: space-between;
    p {
      cursor: pointer;
    }
    .isActiVe {
      color: #d54343;
    }
  }

  .active {
    width: 20px;
    height: 4px;
    background-color: #d54343;
    position: absolute;
    bottom: -10px;
    transition: all 0.28s ease;
  }
}
.demo-ruleForm {
  padding: 0 57px;
  margin-top: 30px;
  /deep/ .el-input__inner {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 50px;
  }
}
.smsText {
  box-sizing: border-box;
  width: 112px;
  margin-left: 10px;
  text-align: center;
  background: #d54343;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
}
.disabled {
  background: #cecece;
  cursor: pointer;
}
.loginOption {
  padding: 0 57px;
  font-size: 14px;
  margin-bottom: 17px;
  p {
    height: 20px;
    line-height: 20px;
    cursor: pointer;
  }
  p:hover {
    color: #d54343;
  }
}
.loginBtnBox {
  box-sizing: border-box;
  padding: 0 57px;
  margin-bottom: 22px;
  .loginBtn {
    padding: 13px 0;
    background-color: #d54343;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
  }
  .loginBtn:hover {
    background-color: #b72c2c;
  }
}

.authBox {
  margin-bottom: 32px;
  div {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    cursor: pointer;
  }
  .wechat {
    background: #81b337;
    margin-right: 32px;
    img {
      width: 28px;
    }
  }
  .wechat:hover {
    background: #689722;
  }
  .qq {
    background: #4095e5;
    img {
      width: 28px;
    }
  }
  .qq:hover {
    background: #2a7ecd;
  }
}
</style>
