// 优惠券列表
import request from "@/api/request";

export function couponList(data) {
    return request({
        url: '/biz/coupon/list',
        method: 'POST',
        data: data,
    })
}

export function couponCount(data) {
    return request({
        url: '/biz/coupon/couponCount?userId='+data,
        method: 'GET'
    })
}

//领券中心
export function couponCenterList(id) {
    return request({
        url: '/api/coupon/getCouponList/'+id,
        method: 'GET'
    })
}

//立即领取
export function receiveCoupon(data) {
    return request({
        url: '/api/userCoupon/coupon/receiveCoupon',
        method: 'POST',
        data: data
    })
}
