<template>
  <div class="orderList" :style="{ height: orderHeight - 120 + 'px' }">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in editableList"
        :key="index"
        :label="item.stateName + '(' +  item.num  + ')'"
        :value="item.state"
        :name="String(item.state)"
        >
        <div class="orderList-con">
          <el-form
            class="nav-form"
            :inline="true"
            :model="ruleForm"
            ref="ruleForm"
            :hide-required-asterisk="true">
            <el-form-item label="下单时间">
              <el-date-picker
                v-model="ruleForm.orderTime"
                type="datetimerange"
                align="right"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="getBirthTime"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="订单编号">
              <el-input v-model="ruleForm.orderNumber" placeholder="请输入订单编号"></el-input>
            </el-form-item>
            <el-form-item label="送货方式">
              <el-select v-model="ruleForm.delivery" placeholder="全部">
                <el-option
                  v-for="(item, index) in deliveryList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收货信息">
              <el-input v-model="ruleForm.receiveInfo" placeholder="请输入收货人或电话"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">搜索</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
          <div class="list-nav">
            <span style="width: 210px;">产品类别</span>
            <span style="width: 500px;">产品明细</span>
            <span style="width: 120px;">理论重量</span>
            <span style="width: 130px;">产品价格</span>
            <span style="width: 150px;">订单金额</span>
            <!-- <span style="width: 160px;">订购人电话</span> -->
            <span style="width: 160px;">预计出货时间</span>
            <span style="width: 160px;text-align: center;">订单状态</span>
            <span style="width: 140px;text-align: center;">操作</span>
          </div>
          <div class="list-li" v-for="(item, index) in pageList" :key="index">
            <div class="li-title">
              <span>订单编号：{{ item.orderCode}}</span>
              <span>下单时间：{{ item.orderTime}}</span>
              <span>{{ item.distributionModeName }} </span>
<!--              <span>{{ item.distributionModeClassName }}</span>-->
<!--              <span>{{ item.memberName }} - {{ item.memberPhone }} - {{ item.addressDesc }}</span>-->
              <span>{{ item.consigneePerson }} - {{ item.consigneePhone }} - {{ item.addressDesc.length>15?item.addressDesc.substring(0,15)+'...':item.addressDesc }}</span>
<!--              <span v-if="item.dispatcherName || item.dispatcherPhone">接单人：{{ item.dispatcherName }} {{ item.dispatcherName }}</span>-->
              <span v-if="item.dispatcherName || item.dispatcherPhone">配送员：{{ item.dispatcherName }} {{ item.dispatcherPhone }}</span>
              <span v-if="item.hwbNumber!=undefined&&item.hwbNumber!=null">运单号：{{ item.hwbNumber }}</span>
              <span @click="goToDetail(item.id)">订单详情</span>
            </div>
            <div class="li-tr">
              <p style="width: 210px;">{{ item.productCategoryName }}</p>
              <div style="display: flex;flex-direction: column;padding: 20px 0px;">
                <div style="display: flex;align-items: center;" v-for="(i, index) in item.items" :key="index">
                  <p style="width: 500px;">{{ i.productDetail }}</p>
                  <p style="width: 120px;">{{ i.theoryWeight }}kg</p>
                  <p style="width: 130px;">￥{{ i.productPrice }}</p>
                </div>
              </div>
              <p class="li-tr-price" style="width: 150px;">
                <span style="font-family : 'FangZhengZhengZhunHei';">￥{{ item.orderCountMoney }}</span>
                <span>货款：￥{{ item.goodsMoney }}</span>
                <span>运费：￥{{ item.moveMoney }}</span>
                <span>优惠：￥{{ item.favorable }}</span>
              </p>
              <!-- <p style="width: 140px;">{{ item.consumerPhone }}</p> -->
              <p style="width: 160px;">{{ item.anticipateShippingTime }}</p>
              <p class="li-tr-status" style="width: 160px;text-align: center;">
                <span style="color: #D54343;">{{ item.tradeOrderStateName }}</span>
                <!-- <span style="color: #D54343;" v-if="item.tradeOrderState == 17">已取消</span> -->
                <span style="color: #4095E5;" v-if="item.afterSalesType == true">售后订单生产中</span>
              </p>
              <p class="li-tr-but" style="width: 140px;">
                <span class="li-tr-but1" v-if="item.tradeOrderState == 10" @click="toPay(item.id)">支付</span>
                <span class="li-tr-but2"
                  v-if="item.tradeOrderState == 10 || item.tradeOrderState == 11 || item.tradeOrderState == 12"
                  @click="cancellation(item.id)">取消订单</span>
                <!-- <span class="li-tr-but2" v-if="item.tradeOrderState == 16 && !item.productAfterStatus" @click="getAss(item.id)">申请售后</span> -->
                <span class="li-tr-but3" v-if="item.tradeOrderState == 16 && item.afterSalesType == true">取消售后</span>
                <span class="li-tr-but2" v-if="item.tradeOrderState == 16 && item.afterSalesType == true">售后详情</span>
              </p>
            </div>
          </div>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              popper-class='select_bottom'>
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <hbw-diaLog
      v-if="dialog"
      :title="addressTitle"
      @change="dialogCancel($event)">
      <div class="diaLog-con">
        <p>您确定要取消订单吗？</p>
        <p>
          <span @click="confirm">确定</span>
          <span @click="dialog = false">取消</span>
        </p>
      </div>
    </hbw-diaLog>
  </div>
</template>
<script>
import {
    getOrderNewStatus,
    getPage,
    cancelOrder,
    getSendDict,
    isCancelOrder
  } from "../../api/connectorList";
  export default {
    data() {
      return {
        orderHeight: '',
        activeName: '5',
        editableList: null,
        pageList: null,
        stus: 5,
        deliveryList: null,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value: '',
        ruleForm: {
          orderTime: '',
          orderNumber: '',
          delivery: '',
          receiveInfo: ''
        },
        startTime: '',
        endTime: '',
        total: null,
        pageNum: 1,
        pageSize: 10,
        orderId: null,
        dialog: false,
        addressTitle: "取消订单确认",
        currentPage: 1
      };
    },
    created() {
      this.activeName = this.$route.query.state ? String(this.$route.query.state) : '5';
      this.orderHeight = document.documentElement.clientHeight
    },
    mounted() {
      this.getOrderStatus()
      this.getSendDict()
    },
    methods: {
      async getOrderStatus() {
        await getOrderNewStatus({
          type: '1',
          id: JSON.parse(localStorage.getItem('user')).id
        }).then((res) => {
          if (res.data.code == 200) {
            let arr = res.data.data;
            // 计算全部订单数
            let total = 0;
            arr.map((item,index)=>{
              return total += item.num
            })
            arr.unshift({
              state: 5, 
              stateName: '全部' ,
              num : total
            })
            this.editableList = arr
          }
        })
        await this.getOrderList();
      },
      handleClick(tab) {
        this.stus = tab.$attrs.value
        this.ruleForm.orderTime = []
        this.ruleForm.orderNumber = ''
        this.ruleForm.delivery = ''
        this.ruleForm.receiveInfo = ''
        this.pageNum = 1;
        this.getOrderList()
      },
      getOrderList() {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        this.pageList = [];
        let params = {
          userId: userInfo.id,
          status: this.stus,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        getPage(params).then((res) => {
          if(res.data.code == 200) {
            this.pageList = res.data.rows
            this.total = res.data.total
          }
        })
      },
      getSendDict() {
        getSendDict().then((res) => {
          if(res.data.code == 200) {
            this.deliveryList = res.data.rows
          }
        })
      },
      getBirthTime (val) {
        this.startTime = val[0]
        this.endTime = val[1]
      },
      search() {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let params = {
          userId: userInfo.id,
          status: this.stus,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          orderStartTime: this.startTime,
          orderEndTime: this.endTime,
          orderCode: this.ruleForm.orderNumber,
          distributionMode: this.ruleForm.delivery,
          receiveInfo: this.ruleForm.receiveInfo
        }
        getPage(params).then((res) => {
          if(res.data.code == 200) {
            this.pageList = res.data.rows
            this.total = res.data.total
          }
        })
      },
      resetForm() {
        this.ruleForm.orderTime = []
        this.ruleForm.orderNumber = ''
        this.ruleForm.delivery = ''
        this.ruleForm.receiveInfo = ''
        this.getOrderList()
      },
      cancellation(id) {
        isCancelOrder(id).then((res)=>{ 
          if(!res.data.data){
            this.dialog = true
            this.orderId = id
          } else {
            this.$alert('当前订单状态已改变，无法取消订单!', '', {
              confirmButtonText: '确定',
              callback: action => {
                this.getOrderStatus();
              }
            });
          }
        })
      },
      dialogCancel(val) {
        this.dialog = val;
      },
      confirm() {
        cancelOrder({id:this.orderId}).then((res) => {
          if(res.data.code == 200) {
            this.dialog = false
            this.$message({
              type: 'success',
              message: '取消成功！'
            });
            this.getOrderStatus();
          }
        })
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.getOrderList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.getOrderList()
      },
      goToDetail(id) {
        this.$emit('orderListShow', true, id)
      },
      toPay(id) {
        this.$emit('orderDetailShow', true, id)
      },
      getAss(id) {
        this.$emit('assFormShow', true, id)
      }
    },
  }
</script>
<style lang="less" scoped>
  .orderList-con {
    padding: 30px 20px;
    margin-bottom: 100px;
    .list-nav {
      height: 50px;
      margin-top: 10px;
      padding: 0 20px;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        color: #333;
        font-weight: 600;
      }
    }
    .list-li {
      .li-title {
        height: 34px;
        background: #f5f5f5;
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin-top: 10px;
        position: relative;
        span {
          color: #999;
          font-size: 14px;
          margin-right: 26px;
        }
        span:last-child {
          cursor: pointer;
          position: absolute;
          right: 0;
        }
        span:last-child:hover {
          color: #D54343;
        }
      }
      .li-tr {
        display: flex;
        align-items: center;
        padding: 0 20px;
        min-height: 110px;
        border: 1px solid #EBEBEB;
        font-size: 14px;
        .li-tr-img {
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            margin-right: 14px;
          }
          span {
            width: 150px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
          }
        }
        .li-tr-more {
          span {
            width: 150px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
          }
        }
        .li-tr-price {
          display: flex;
          flex-direction: column;
          span {
            font-size: 12px;
            color: #999;
          }
          span:first-child {
            color: #D54343;
            font-size: 18px;
          }
        }
        .li-tr-status {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .li-tr-but {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            width: 100px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 30px;
            font-size: 12px;
            margin-bottom: 6px;
            cursor: pointer;
            box-sizing: border-box;
          }
          .li-tr-but1 {
            background: #D54343;
            color: #fff;
          }
          .li-tr-but1:hover{
            background-color: #B72C2C;
          }
          .li-tr-but2 {
            border: 1px solid #E67E01;
            color: #E67E01;
            background: #FFF9F2;
          }
          .li-tr-but3 {
            border: 1px solid #D54343;
            color: #D54343;
            background: #FFF3F3;
          }
        }
      }
    }
    .block {
      height: 110px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .diaLog-con {
    font-size: 14px;
    p:first-child {
      font-weight: 600;
      color: #101010;
      padding: 40px 0 60px 0;
    }
    p:last-child {
      display: flex;
      justify-content: flex-end;
      span {
        width: 84px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
      span:first-child {
        background: #D54343;
        color: #fff;
        margin-right: 18px;
      }
      span:last-child {
        background: #efefef;
        color: #333;
      }
    }
  }
  /deep/ .el-tabs__header {
    background: #fff;
    height: 56px;
  }
  /deep/ .el-tabs__content {
    background: #fff;
  }
  /deep/ .el-tabs__nav {
    padding: 0px 30px;
  }
  /deep/ .el-tabs__nav-wrap::after {
    height: 0;
  }
  /deep/ .el-tabs__nav-scroll {
    height: 56px;
    line-height: 56px;
  }
  /deep/ .el-tabs__active-bar {
    background: #D54343;
    left: 30px;
  }
  /deep/ .el-tabs__item {
    padding: 0 40px;
    font-size: 16px;
    color: #333;
  }
  /deep/ .el-tabs__item.is-active {
    color: #D54343;
  }
  /deep/ .el-input__inner:focus {
    border: #D54343 1px solid;
  }
  /deep/ .el-button--primary {
    color: #FFF;
    background-color: #D54343;
    border-color: #D54343;
  }
  /deep/ .el-button--primary:hover {
    background-color: #B72C2C;
    border-color: #B72C2C;
  }
  /deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #D54343;
  }
  /deep/ .el-pager li.active {
    color: #D54343;
  }
  /deep/ .el-pager li:hover {
    color: #D54343;
  }
  /deep/ .el-pagination .btn-prev:hover {
    color: #D54343;
  }
  
</style>
<style lang="less">
  .select_bottom{
    .el-select-dropdown__item.selected{
      color: #D54343;
    }
  }
</style>

