<template>
  <div class="myInvoice">
    <el-form :inline="true" :model="ruleForm" ref="ruleForm" :hide-required-asterisk="true">
      <el-form-item label="申请时间">
        <el-date-picker
          v-model="ruleForm.orderTime"
          type="datetimerange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="getBirthTime"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="抬头类型">
        <el-select v-model="ruleForm.type" placeholder="全部">
          <el-option v-for="(item, index) in invoiceType" :key="index" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开票状态">
        <el-select v-model="ruleForm.state" placeholder="全部">
          <el-option v-for="(item, index) in invoiceState" :key="index" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票抬头">
        <el-input v-model="ruleForm.receiveInfo" placeholder="请输入关键字"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="toInvoice">申请发票</el-button>
    <el-table
      :data="pageList"
      border
      style="width: 100%;margin-top: 20px;"
      :header-cell-style="{background:'#f5f5f5',color:'#333','font-weight':'600'}">
      <el-table-column
        prop="invoiceCode"
        label="发票ID"
        width="180">
      </el-table-column>
      <el-table-column
        prop="orderNum"
        label="订单数量"
        width="180">
      </el-table-column>
      <el-table-column
        prop="invoiceOwnershipName"
        label="抬头类型">
      </el-table-column>
      <el-table-column
        prop="invoiceTitle"
        label="发票抬头">
      </el-table-column>
      <el-table-column
        prop="applyTime"
        label="申请时间">
      </el-table-column>
      <el-table-column
        prop="invoiceAmount"
        label="发票金额">
        <template slot-scope="scope">￥{{ scope.row.invoiceAmount }}</template>
      </el-table-column>
      <el-table-column
        prop="billingTax"
        label="税费">
        <template slot-scope="scope">￥{{ scope.row.billingTax }}</template>
      </el-table-column>
      <el-table-column
        prop="billStateName"
        label="开票状态">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total" popper-class='select_bottom'>
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    payList,
    invoiceList
  } from "../../api/connectorList";
  export default {
    data() {
      return {
        InvoiceOwnerShip: 'InvoiceOwnerShip', //开票类型
        invoiceType: [], //类型
        AuditState: 'AuditState', //开票状态
        invoiceState: [], //状态
        pageList: [],
        stus: '',
        ruleForm: {
          orderTime: '', //日期
          type: '', //类型
          state: '', //状态
          receiveInfo: '' //输入
        },
        //日历
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value: '', //日期
        startTime: '',
        endTime: '',
        total: null,
        pageNum: 1,
        pageSize: 10,
        currentPage: 1
      };
    },
    mounted() {
      this.getType()
      this.getState()
      this.getList()
    },
    methods: {
      getType() {
        payList({ name: this.InvoiceOwnerShip }).then((res) => {
          if (res.data.code == 200) {
            this.invoiceType = res.data.data
          }
        })
      },
      getState() {
        payList({ name: this.AuditState }).then((res) => {
          if (res.data.code == 200) {
            this.invoiceState = res.data.data
          }
        })
      },
      getList() {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let params = {
          userId: userInfo.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          applyStartTime: this.startTime,
          applyEndTime: this.endTime,
          headerType: this.ruleForm.type,
          invoiceTitle: this.ruleForm.state,
          billState: this.ruleForm.receiveInfo,
        }
        invoiceList(params).then((res) => {
          if (res.data.code == 200) {
            this.pageList = res.data.rows
            this.total = res.data.total
          }
        })
      },
      getBirthTime(val) {
        this.startTime = val[0]
        this.endTime = val[1]
      },
      search() {
        this.getList()
      },
      resetForm() {
        this.startTime = '',
        this.endTime = '',
        this.ruleForm.type = ''
        this.ruleForm.state = ''
        this.ruleForm.receiveInfo = '';
        this.ruleForm.orderTime = '';
        this.getList()
      },
      toInvoice() {
        this.$emit('myInvoiceListEmit', true)
      },
      handleClick(row) {
        this.$emit('invoiceDetailEmit', true, row.id)
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.getList()
      }
    },
  }
</script>
<style lang="less" scoped>
  .myInvoice {
    background: #fff;
    padding: 30px 20px;
    .block {
      height: 110px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  /deep/ .el-tabs__header {
    background: #fff;
  }
  /deep/ .el-tabs__content {
    background: #fff;
  }
  /deep/ .el-tabs__nav {
    padding: 0px 30px;
  }
  /deep/ .el-tabs__active-bar {
    background: #D54343;
    left: 30px;
  }
  /deep/ .el-tabs__item {
    padding: 0 40px;
    font-size: 16px;
    color: #333;
  }
  /deep/ .el-tabs__item.is-active {
    color: #D54343;
  }
  /deep/ .el-input__inner:focus {
    border: #D54343 1px solid;
  }
  /deep/ .el-button--primary {
    color: #FFF;
    background-color: #D54343;
    border-color: #D54343;
  }
  /deep/ .el-button--primary:hover {
    background-color: #B72C2C;
    border-color: #B72C2C;
  }
  /deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #D54343;
  }
  /deep/ .el-pager li.active {
    color: #D54343;
  }
  /deep/ .el-pager li:hover {
    color: #D54343;
  }
  /deep/ .el-pagination .btn-prev:hover {
    color: #D54343;
  }
  /deep/ .el-pagination .btn-next:hover {
    color: #D54343;
  }
  /deep/ .el-button--text {
    color: #333;
  }
  /deep/ .el-button--text:hover {
    color: #D54343;
  }
</style>
<style lang="less">
  .select_bottom {
    .el-select-dropdown__item.selected {
      color: #D54343;
    }
  }
</style>
