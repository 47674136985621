import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
    meta: {
      requireAuth: true
    },
    redirect:'/goods',
    children: [{
      path: '/goods',
      name: '商品管理',
      component: () => import(/* webpackChunkName: "index" */ '../views/goods/index.vue'),
      meta: {
        requireAuth: false
      }
    }],
  },
  {
    path: '/userHome',
    name: 'UserHome',
    component: () => import(/* webpackChunkName: "index" */ '../views/userHome/index.vue'),
    meta: {
      requireAuth: true
    },
    children: [{
      path: 'userInfo',
      name: 'UserInfo',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/user/index.vue'),
      meta: {
        requireAuth: true
      }
    }, {
      path: 'address',
      name: 'Address',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/address/index.vue'),
      meta: {
        requireAuth: true
      }
    }, {
      path: 'attestation',
      name: 'Attestation',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/attestation/index.vue'),
      meta: {
        requireAuth: true
      }
    }, {
      path: 'recharge',
      name: 'Recharge',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/recharge/index.vue'),
      meta: {
        requireAuth: true
      }
    }, {
      path: 'coupon',
      name: 'Coupon',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/coupon/index.vue'),
      meta: {
        requireAuth: true
      }
    }, {
      path: 'getCoupon',
      name: 'GetCoupon',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/coupon/couponCenter.vue'),
      meta: {
        requireAuth: true
      }
    }, {
      path: 'myOrder',
      name: 'MyOrder',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/myOrder/index.vue'),
      meta: {
        requireAuth: true
      }
    }, {
      path: 'userSetting',
      name: 'UserSetting',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/usersetting/index.vue'),
      meta: {
        requireAuth: true
      }
    }, {
      path: 'assOrder',
      name: 'AssOrder',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/assOrder/index.vue'),
      meta: {
        requireAuth: true
      }
    }, {
      path: 'myInvoice',
      name: 'MyInvoice',
      component: () => import(/* webpackChunkName: "index" */ '../views/userHome/myInvoice/index.vue'),
      meta: {
        requireAuth: true
      }
    },]
  },
  {
    path: '/confirmOrder',
    name: 'ConfirmOrder',
    component: () => import(/* webpackChunkName: "index" */ '../views/confirmOrder/index.vue'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/loginIndex',
    name: 'LoginIndex',
    component: () => import(/* webpackChunkName: "index" */ '../views/logins/index.vue'),
    meta: {
      requireAuth: false
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "index" */ '../views/logins/login.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "index" */ '../views/logins/register.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'resetpwd',
        name: 'Resetpwd',
        component: () => import(/* webpackChunkName: "index" */ '../views/logins/resetpwd.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'bingUser',
        name: 'BingUser',
        component: () => import(/* webpackChunkName: "index" */ '../views/logins/bingUser.vue'),
        meta: {
          requireAuth: false
        }
      }
    ]
  },
  {
    path: '/stateMent',
    name: 'StateMent',
    component: () => import(/* webpackChunkName: "index" */ '../views/stateMent/index.vue'),
    meta: {
      requireAuth: false
    },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "index" */ '../views/feedback/index.vue'),
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "index" */ '../views/feedback/index.vue'),
    meta: {
      requireAuth: false
    }
  },
  // 批量下单
  {
    path: '/batchOrder',
    name: 'batchOrder',
    component: () => import(/* webpackChunkName: "index" */ '../views/batchOrder/index.vue'),
    meta: {
      keepAlive: true,
      requireAuth: false
    }
  },
]
const router = new VueRouter({
  routes,
  mode: 'hash'
})

export default router

router.beforeEach((to, form, next) => {
  if (to.meta.requireAuth) {
    const userInfo = window.localStorage.getItem("userInfo")
    if (!userInfo) {
      next("/loginIndex/login?redirect=" + to.fullPath)
    } else {
      next()
    }
  } else {
    next()
  }
})
