<template>
  <div>
    <div class="tabsBox">
          账号绑定
    </div>
    <el-form
      label-position="left"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="0px"
      class="demo-ruleForm"
      :validate-on-rule-change="false"
    >
      <el-form-item prop="phone">
        <el-input
          type="text"
          v-model="ruleForm.phone"
          auto-complete="off"
          placeholder="手机号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          auto-complete="off"
          placeholder="（6-16个字符，包含字母和数字）"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="loginOption flex-j-sb">
      <div class="flex-j-sb">
        <p style="margin-right: 140px" @click="to('/loginIndex/register')">没有账号，立即注册</p>
        <p @click="to('/loginIndex/resetpwd')">忘记密码</p>
      </div>
      <!--<p>游客看看</p>-->
    </div>
    <div class="loginBtnBox">
      <div style="width: 100%" class="loginBtn flex-j-c" @click="submitForm('ruleForm')">
        绑定
      </div>
    </div>
  </div>
</template>

<script>
import { login} from "../../api/connectorList";
import {memberScanBind} from "@/api/user";
export default {
  props:{
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      rememberpwd:true,
      isActiVe: 0,
      disabled: false,
      ruleForm: {
        //username和password默认为空
        phone: "",
        password: "",
      },
      //rules前端验证
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: ["blur","change"] }],
        password: [{ required: true, message: "请输入密码", trigger: ["blur","change"] }],
      }
    };
  },
  created() {
    //获取存在本地的用户名密码
    console.log(this.code);
  },
  methods: {
    //获取info列表
    submitForm(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            telphone: this.ruleForm.phone,
            password: this.ruleForm.password,
            wxCode:that.code
          };
          // 注释
          memberScanBind(params).then((res) => {
            let userInfo = res.data.data.member
            if (res.data.code == "200") {
              setTimeout(() => {
                localStorage.setItem('user', JSON.stringify(userInfo));
                localStorage.setItem("token", res.data.data.token);
                this.$store.commit("login", "true");
                this.$router.push({ path: "/goods" });
              }, 1000);
            } else {
              this.$message.error(res.data.msg);
              return false;
            }
          });
        } else {
          // 获取图形验证码
          this.$message.error("请输入用户名密码！");
          this.logining = false;
          return false;
        }
      });
    },
    to(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="less">
.login-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #d54343;
  position: relative;
  /* background-repeat: no-repeat; */
  /* background-position: center right; */
  /* background-size: 100%; */
  .loginWrap-top {
    margin-bottom: 32px;
    .QR-box {
      width: 100%;
      margin-bottom: 6px;
      img {
        width: 42px;
        cursor: pointer;
      }
    }
    .logo {
      width: 100%;
      img {
        width: 186px;
      }
    }
  }
}

.login-container {
  margin: 0px auto;
  position: absolute;
  top: 22%;
  left: calc(50% - 240px);
  width: 460px;
  padding: 10px;
  background: #fff;
  text-align: left;
}
.tabsBox {
  width: 250px;
  margin-left:80px;
  font-size: 18px;
  font-family: SourceHanSansSC-regular;
  position: relative;
  .tabsName {
    display: flex;
    justify-content: space-between;
    p {
      cursor: pointer;
    }
    .isActiVe {
      color: #d54343;
    }
  }

  .active {
    width: 20px;
    height: 4px;
    background-color: #d54343;
    position: absolute;
    bottom: -10px;
    transition: all 0.28s ease;
  }
}
.demo-ruleForm {
  padding: 0 57px;
  margin-top: 30px;
  /deep/ .el-input__inner {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 50px;
  }
}
.smsText {
  box-sizing: border-box;
  width: 112px;
  margin-left: 10px;
  text-align: center;
  background: #d54343;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
}
.disabled {
  background: #cecece;
  cursor: pointer;
}
.loginOption {
  padding: 0 57px;
  font-size: 14px;
  margin-bottom: 17px;
  p {
    height: 20px;
    line-height: 20px;
    cursor: pointer;
  }
  p:hover {
    color: #d54343;
  }
}
.loginBtnBox {
  box-sizing: border-box;
  padding: 0 57px;
  margin-bottom: 22px;
  .loginBtn {
    padding: 13px 0;
    background-color: #d54343;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
  }
  .loginBtn:hover {
    background-color: #b72c2c;
  }
}

.authBox {
  margin-bottom: 32px;
  div {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    cursor: pointer;
  }
  .wechat {
    background: #81b337;
    margin-right: 32px;
    img {
      width: 28px;
    }
  }
  .wechat:hover {
    background: #689722;
  }
  .qq {
    background: #4095e5;
    img {
      width: 28px;
    }
  }
  .qq:hover {
    background: #2a7ecd;
  }
}
</style>
