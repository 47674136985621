<template>
  <div class="flex-d-c" style="padding-bottom: 45px">
    <div class="QRcode" id="qrcode">
    </div>
    <div class="annotation">
<!--      <p>使用微信扫一扫登录</p>-->
<!--      <p>如未注册建议先注册账号</p>-->
    </div>
    <div class="register flex-j-sb">
      <p @click="toRegister">免费注册</p>
      <p v-if="num == '1'" @click="login">账号登录</p>
      <p v-else  @click="to('/goods')" >游客看看</p>
    </div>
  </div>
</template>

<script>
//import project from '../../../public/project.config.json'
export default {
  props: ['num'],
  data() {
    return {};
  },
  mounted() {
    this.weixinClick();
  },
  methods:{
    toRegister(){
      this.$router.push('/loginIndex/register')
    },
    weixinClick(){// 点击切换微信扫码登录这一项，并向微信扫码登录
      // 微信登录第一步：申请微信登录二维码
      console.log("开始扫描");
      let _this = this;
      new WxLogin({
        id: "qrcode",
        appid: wx.appid,
        scope: "snsapi_login",
        // 扫码成功后重定向的接口
        redirect_uri: encodeURIComponent(project.wxUrl+"/#/loginIndex/bingUser"),
        // state填写编码后的url
        //state: encodeURIComponent(window.btoa("http://127.0.0.1:8080" + _this.$route.path)),
        state: encodeURIComponent(window.btoa(project.wxUrl+ _this.$route.path)),
        // 调用样式文件
        href: project.wxCssUrl+"/css/wechatimg.css",
      });
    },
    login() {
      this.$emit('accountLogin', false)
    },
    to(url) {
      localStorage.setItem("temporaryUser","true");
      this.$router.push({ path: url });
    }
  }
};
</script>

<style lang="less" scoped>
.QRcode {
  width: 220px;
  height: 220px;
  background-color: #fff;
}
.annotation {
  margin-top: 15px;
  text-align: center;
  color: #9a9a9a;
  p:first-child {
    margin-bottom: 7px;
  }
}
.register {
  margin-top: 50px;
  p{
    cursor: pointer;
  }
  p:hover {
    color: #d54343;
  }
  p:first-child {
    margin-right: 103px;
  }
}
</style>
