import { render, staticRenderFns } from "./offerPlaceNew.vue?vue&type=template&id=40e148b6&scoped=true&"
import script from "./offerPlaceNew.vue?vue&type=script&lang=js&"
export * from "./offerPlaceNew.vue?vue&type=script&lang=js&"
import style0 from "./offerPlaceNew.vue?vue&type=style&index=0&id=40e148b6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e148b6",
  null
  
)

export default component.exports