<template>
  <div class="myInvoice">
    <div class="myInvoice-nav">
      <p @click="goBack">我的发票</p>
      <p>  >  申请发票</p>
    </div>
    <div class="myInvoice-header">
      <p>1，订单完成3个月之内可自助申请开票。如有售后申请中的订单及售后退款通过审核的订单均不可申请发票。售后取消，售后驳回，售后重做通过审核的订单可申请开票。</p>
      <p>2，发票抬头相同，建议选择合并开具发票。</p>
    </div>
    <el-form :inline="true" :model="ruleForm" ref="ruleForm" :hide-required-asterisk="true">
      <el-form-item label="下单时间">
        <el-date-picker 
          v-model="ruleForm.orderTime" 
          type="datetimerange" 
          align="right" 
          range-separator="至"
          start-placeholder="开始日期" 
          end-placeholder="结束日期" 
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="getBirthTime"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="产品类别">
        <el-select v-model="ruleForm.type" placeholder="全部">
          <el-option v-for="(item, index) in invoiceType" :key="index" :label="item.name" :value="item.classId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单编号">
        <el-input v-model="ruleForm.receiveInfo" placeholder=" 请输入订单编号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="pageList"
      border
      style="width: 100%;margin-top: 20px;"
      :header-cell-style="{background:'#f5f5f5',color:'#333','font-weight':'600'}"
      @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="orderCode"
        label="订单编号"
        width="130">
      </el-table-column>
      <el-table-column
        prop="productCategoryName"
        label="产品类别"
        width="160">
      </el-table-column>
      <el-table-column
        label="产品明细">
        <template slot-scope="scope">
          <div v-for="(val,index) in scope.row.items" :key="index">
            <div>{{val.productDetail}}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderCountMoney"
        label="订单金额"
        width="140">
        <template slot-scope="scope">￥{{ scope.row.orderCountMoney }}</template>
      </el-table-column>
      <el-table-column
        prop="tradeOrderStateName"
        label="订单状态"
        width="140">
      </el-table-column>
      <el-table-column
        prop="orderTime"
        label="下单时间"
        width="180">
      </el-table-column>
    </el-table>
    <div class="block">
      <div class="block-left">
        <span class="block-span1" v-if="stepShow1">下一步</span>
        <span class="block-span2" v-if="stepShow2" @click="toStep">下一步</span>
        <span>待开票金额： ￥{{ selectPrice }}，共选择{{ selectLength }}条数据</span>
      </div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total" popper-class='select_bottom'>
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    queryAllList,
    getPage,
  } from "../../api/connectorList";
  export default {
    data() {
      return {
        invoiceType: [],
        pageList: [],
        stus: '',
        ruleForm: {
          orderTime: '',
          type: '',
          receiveInfo: ''
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value: '',
        startTime: '',
        endTime: '',
        total: null,
        pageNum: 1,
        pageSize: 10,
        currentPage: 1,
        stepShow1: true,
        stepShow2: false,
        selectPrice: 0,
        selectLength: 0,
        selectionList: []
      };
    },
    mounted() {
      this.getType()
      this.getOrderList()
    },
    methods: {
      goBack() {
        this.$router.go(0)
      },
      getType() {
        queryAllList().then((res) => {
          if (res.data.code == 200) {
            this.invoiceType = res.data.data
          }
        })
      },
      getOrderList() {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let params = {
          userId: userInfo.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          status: 4,
          orderStartTime: this.startTime,
          orderEndTime: this.endTime,
          headerType: this.ruleForm.type,
          orderCode: this.ruleForm.receiveInfo,
        }
        getPage(params).then((res) => {
          if(res.data.code == 200) {
            this.pageList = res.data.rows
            this.total = res.data.total
          }
        })
      },
      getBirthTime(val) {
        this.startTime = val[0]
        this.endTime = val[1]
      },
      search() {
        this.getOrderList()
      },
      resetForm() {
        this.startTime = '';
        this.endTime = '';
        this.ruleForm.type = '';
        this.ruleForm.receiveInfo = '';
        this.ruleForm.orderTime = '';
        this.getOrderList();
      },
      handleSelectionChange(selection) {
        if(selection.length > 0) {
          this.stepShow1 = false
          this.stepShow2 = true
          let arr = selection.map(item => (item.orderCountMoney))
          let price = arr.reduce((n,m) => ( n*100 + m*100 ) / 100)
          this.selectPrice = price
        } else {
          this.stepShow1 = true
          this.stepShow2 = false
          this.selectPrice = 0
        }
        this.selectLength = selection.length
        this.selectionList = selection
      },
      toStep() {
        this.$emit('invoiceListEmit', true, this.selectionList, this.selectPrice)
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.getOrderList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.getOrderList()
      }
    },
  }
</script>
<style lang="less" scoped>
  .myInvoice {
    background: #fff;
    padding: 30px 20px;
    .myInvoice-nav {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      p:first-child {
        cursor: pointer;
        font-weight: 600;
      }
    }
    .myInvoice-header {
      height: 50px;
      padding: 15px 21px;
      margin-bottom: 16px;
      background: #fff3f3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        color: #D54343;
        font-size: 14px;
      }
    }
    .block {
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .block-left {
        display: flex;
        align-items: center;
        font-size: 12px;
        .block-span1 {
          width: 104px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background: #cecece;
          color: #fff;
          margin-right: 26px;
        }
        .block-span2 {
          width: 104px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background: #d54343;
          color: #fff;
          margin-right: 26px;
          cursor: pointer;
        }
        span:last-child {
          color: #333;
        }
      }
    }
  }
  /deep/ .el-tabs__header {
    background: #fff;
  }
  /deep/ .el-tabs__content {
    background: #fff;
  }
  /deep/ .el-tabs__nav {
    padding: 0px 30px;
  }
  /deep/ .el-tabs__active-bar {
    background: #D54343;
    left: 30px;
  }
  /deep/ .el-tabs__item {
    padding: 0 40px;
    font-size: 16px;
    color: #333;
  }
  /deep/ .el-tabs__item.is-active {
    color: #D54343;
  }
  /deep/ .el-input__inner:focus {
    border: #D54343 1px solid;
  }
  /deep/ .el-button--primary {
    color: #FFF;
    background-color: #D54343;
    border-color: #D54343;
  }
  /deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #D54343;
  }
  /deep/ .el-pager li.active {
    color: #D54343;
  }
  /deep/ .el-pager li:hover {
    color: #D54343;
  }
  /deep/ .el-pagination .btn-prev:hover {
    color: #D54343;
  }
  /deep/ .el-pagination .btn-next:hover {
    color: #D54343;
  }
  /deep/ .el-button--text {
    color: #333;
  }
  /deep/ .el-button--text:hover {
    color: #D54343;
  }
</style>
<style lang="less">
  .select_bottom {
    .el-select-dropdown__item.selected {
      color: #D54343;
    }
  }
</style>