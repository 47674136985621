import request from './request'
// 登录
export function login(data) {
  return request({
    url: '/member/phoneLogin',
    method: 'POST',
    data: data
  })
}
export function smsLogin(data) {
  return request({
    url: '/member/smsLogin',
    method: 'POST',
    data: data
  })
}
// 获取验证码
export function sendSms(data) {
  return request({
    url: '/member/sendSms',
    method: 'POST',
    data: data
  })
}
// 注册
export function register(data) {
  return request({
    url: '/member/register',
    method: 'POST',
    data: data
  })
}
// 重置密码
export function resetPwd(data) {
  return request({
    url: '/member/pwdReset',
    method: 'PUT',
    data: data
  })
}
// 字典表数据查询
export function queryDict(data) {
  return request({
    url: '/system/dict/data/type/' + data,
    method: 'GET',
  })
}
// 获取会员信息
export function getInfo(data) {
  return request({
    url: '/member/getInfo?id=' + data.id,
    method: 'GET',
  })
}
// 更新会员联系人及手机号
export function updateMember(data) {
  return request({
    url: '/member/updateMember',
    method: 'POST',
    data: data
  })
}
/**
 * 报价下单
 * */

// 查询导航栏
export function getPlaceList(data) {
  return request({
    url: '/placeOrder/list',
    method: 'GET',
    params: data
  })
}
// 查询分类详情
export function getPlaceData(data) {
  return request({
    url: '/placeOrder/getFirestByParam/' + '?id=' + data.id + '&code=' + data.code,
    method: 'GET',
  })
}
// 查询二级选项信息
export function getOtherData(data) {
  return request({
    url: '/placeOrder/getOtherByParam/' + '?treeId=' + data.treeId + '&prodcutCode=' + data.prodcutCode,
    method: 'GET',
  })
}
// 查询禁选项及必选项
// export function getRelationsData(data) {
//   return request({
//     // url: '/placeOrder/getRelationsByParam/' + '?settingType=' + data.settingType + '&prodcutCode=' + data.prodcutCode + '&priceId=' + data.priceId + '&ralationItem=' + data.ralationItem,
//     url: '/placeOrder/getRelationsByParam?itemIds=' + data.itemIds + '&prodcutCode=' + data.prodcutCode,
//     method: 'GET',
//   })
// }
// 查询禁选项及必选项
export function getRelationsData(data) {
  return request({
    url: '/placeOrder/getRelationsByParam',
    method: 'POST',
    data:data
  })
}

export function countPrice(data) {
  return request({
    url: '/placeOrder/countPrice',
    method: 'POST',
    data:data
  })
}

// 查询子集禁选项及必选项
export function getCraftRelationsByParam(data) {
  return request({
    url: '/placeOrder/getCraftRelationsByParam',
    method: 'POST',
    data:data
  })
}

// 示例图查询
export function getAttrFile(data) {
  return request({
    url: '/placeOrder/showImage/' + data.id,
    method: 'GET',
  })
}

/**
 * 个人中心
 * */

// 获取用户余额
export function userBalance(data) {
  return request({
    url: '/biz/card/getCardByUserId',
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
}
// 获取用户优惠券数量
export function userCoupon(data) {
  return request({
    url: '/biz/coupon/userCouponCount',
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
}
export function priceList(data) {
  return request({
    url: '/biz/rechargeManager/getAll',
    method: 'POST',
    data: data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
  })
}

// 多个枚举类接口
export function payList(data) {
  return request({
    url: '/biz/enum/list'+ '?name=' + data.name,
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
  })
}
// 支付
export function rechargePay(data) {
  return request({
    url: '/biz/card/recharge',
    method: 'POST',
    data: data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
  })
}
// 交易订单列表
export function rechargeLog(data) {
  return request({
    url: '/biz/api/record/list',
    method: 'POST',
    data: data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
  })
}

// 省市区列表
export function allArea(data) {
  return request({
    url: '/placeOrder/getAreasByParam?areaId=' + data.areaId,
    method: 'GET',
  })
}
// 收货地址列表
export function addressList(data) {
  return request({
    url: '/member/address/list/' + '?memberId=' + data.memberId,
    method: 'GET',
  })
}
// 下单收货地址查询列表
export function addressListNew(data) {
  return request({
    url: '/member/address/list/' + '?memberId=' + data.memberId + '&defaultFlag=' + data.defaultFlag,
    method: 'GET',
  })
}
// 新增收货地址
export function addAddress(data) {
  return request({
    url: '/member/address/add',
    method: 'POST',
    data: data,
  })
}
// 编辑收货地址
export function editAddress(data) {
  return request({
    url: '/member/address/update',
    method: 'POST',
    data: data,
  })
}
// 删除收货地址
export function deleteAddress(data) {
  return request({
    url: '/member/address/' + data.ids,
    method: 'DELETE',
  })
}
//查询可用配送方式
export function getSendDict(data) {
  return request({
    url: '/placeOrder/getSendDict',
    method: 'GET',
    params: data
  })
}


export function getFileConfig(data) {
  return request({
    url: '/file/sts',
    method: 'GET',
  })
}


export function saveFile(data) {
  return request({
    url: '/file/saveFile',
    method: 'POST',
    data:data
  })
}

// 查询认证信息
export function enterprise(data) {
  return request({
    url: '/member/auth/getAuth?memberId=' + data.memberId ,
    method: 'GET',
  })
}

export function addEnterprise(data) {
  return request({
    url: '/member/auth/add',
    method: 'POST',
    data:data
  })
}

export function saveEnterprise(data) {
  return request({
    url: '/member/auth/update',
    method: 'POST',
    data:data
  })
}

// 获取订单状态
export function getOrderStatus(data) {
  return request({
    url: '/trade/order/getOrderStatus',
    method: 'POST',
    data: data
  })
}
// 获取订单状态新  实时获取订单数据
export function getOrderNewStatus(data) {
  return request({
    url: '/biz/order/getOrderNumByStatus/?type=' + data.type,
    method: 'GET',
    params: {
      userId: data.id
    }
  })
}
//计算运费
export function countSendPrice(data) {
  return request({
    url: '/placeOrder/countSendPrice',
    method: 'POST',
    data: data
  })
}

//查询优惠劵
export function getRightCoupon(data) {
  return request({
    url: '/biz/coupon/getRightCoupon',
    method: 'POST',
    data: data
  })
}

//确认下单
export function createOrder(data) {
  return request({
    url: '/trade/order/create',
    method: 'POST',
    data: data
  })
}

// 余额支付--获订单详情
export function getById(data) {
  return request({
    url: '/trade/order/getById?id=' + data.id ,
    method: 'GET',
  })
}

// 余额支付--订单支付提交
export function orderSubmit(data) {
  return request({
    url: '/trade/order/pay/submit',
    method: 'POST',
    data: data
  })
}

// 订单列表查询
export function getPage(data) {
  return request({
    url: '/trade/order/page',
    method: 'POST',
    data: data
  })
}

// 取消订单
export function cancelOrder(data) {
  return request({
    url: '/biz/order/cancelOrder?id=' + data.id ,
    method: 'GET',
  })
}

// 创建售后订单
export function createApplyAfterSale(data) {
  return request({
    url: '/trade/order/createApplyAfterSale',
    method: 'POST',
    data: data
  })
}

// 取消订单
export function assOrder(data) {
  return request({
    url: '/biz/order',
    method: 'PUT',
    data: data
  })
}

// 取消订单
export function invoiceList(data) {
  return request({
    url: '/biz/invoice/list',
    method: 'POST',
    data: data
  })
}

// 取消订单前查询状态
export function isCancelOrder(data) {
  return request({
    url: '/biz/order/isCancelOrder'+ '?id=' + data,
    method: 'GET',
  })
}

//发票-查询产品类别
export function queryAllList() {
  return request({
    url: '/prodcut/prodcut/queryAllList',
    method: 'GET',
  })
}

//查询税率
export function taxRateSet() {
  return request({
    url: '/taxRateSet/1',
    method: 'GET',
  })
}

//申请发票提交
export function applyBill(data) {
  return request({
    url: '/biz/invoice/applyBill',
    method: 'POST',
    data: data
  })
}

//查询发票详情
export function invoiceDeteil(data) {
  return request({
    url: '/biz/invoice/' + data.id,
    method: 'GET',
  })
}

//税费提交
export function payTaxes(data) {
  return request({
    url: '/biz/invoice/payTaxes',
    method: 'POST',
    data: data
  })
}

//富文本
export function feedback(data) {
  return request({
    url: '/base/feedback',
    method: 'POST',
    data: data
  })
}

//根据id查询产品
export function getProdcutByParam(data) {
  return request({
    url: '/placeOrder/getProdcutByParam'+ '?id=' + data.id,
    method: 'GET',
    data: data
  })
}

// 批量计算运费
export function batchCountSendPrice(data) {
  return request({
    url: '/placeOrder/batchCountSendPrice',
    method: 'POST',
    data: data
  })
}
// 批量查询优惠券
export function getRightCoupons(data) {
  return request({
    url: '/biz/coupon/getRightCoupons',
    method: 'POST',
    data: data
  })
}
// 批量选择产品侧边导航
export function getNoNeedFileList(data) {
  return request({
    url: '/placeOrder/noNeedFileList',
    method: 'GET',
    params: data
  })
}
// 批量下单
export function createOrderBefore(data) {
  return request({
    url: '/trade/order/createOrderBefore',
    method: 'POST',
    data: data
  })
}
// 批量订单查询
export function getBetchOrderByIds(data) {
  return request({
    url: '/trade/order/getByIds'+ '?id=' + data,
    method: 'GET',
    data: data
  })
}
// 批量获取订单金额
export function getOrderMoney(data) {
  return request({
    url: '/trade/order/getOrderMoney'+ '?id=' + data,
    method: 'GET',
    data: data
  })
}

// 头部banner
export function getShowPoster() {
  return request({
    url: '/biz/showPoster/listNoPage'+ '?status=Y' ,
    method: 'GET',
  })
}
