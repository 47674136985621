<template>
    <div class="offLineTable">
        <happy-scroll color="rgba(75,75,75,1)" size="8" resize :hide-horizontal="false">
            <el-menu collapse-transition :default-active="$route.path" unique-opened
                class="el-menu-vertical-demo" background-color="#282828" text-color="#fff" active-text-color="#fff"
                @select="menuSelect" :default-openeds="opends">
                <div>
                    <div v-for="menu in list" :key="menu.id">
                        <el-submenu :index="menu.id + ''" v-if="menu.children != null">
                            <template slot="title">
                                <i class=""></i>
                                <span>{{ menu.name }}</span>
                            </template>
                            <el-menu-item v-for="chmenu in menu.children" class="menuItem"
                                :index="chmenu.id + ',' + chmenu.code" :key="chmenu.id"
                                :class="isMenu == chmenu.id ? 'isMenu' : ''">
                                <i class="iconfont"></i>
                                <span>{{ chmenu.name }}</span>
                            </el-menu-item>
                        </el-submenu>

                        <el-menu-item v-else :index="menu.id" :class="isMenuMember == '/' + menu.url ? 'isMenu' : ''" :key="menu.id">
                            <img style="margin-right: 10px;" :src="menu.icon" alt="">
                            <span slot="title">{{ menu.name }}</span>
                        </el-menu-item>
                    </div>
                </div>
            </el-menu>
        </happy-scroll>
    </div>
</template>
<script>
import { HappyScroll } from "vue-happy-scroll";
import "vue-happy-scroll/docs/happy-scroll.css";
export default {
    name: "leftnav",
    components: {
        "happy-scroll": HappyScroll,
    },
    data() {
        return {
            params: {},
            isMenu: null,
            opends: [],
            isMenuMember: ''
        };
    },
    props: {
        list: Array,
        leftnavHeight: Number,
        flag: {
            type: Boolean,
            default: false,
        }, // 是否是批量下单的标识
    },
    watch: {
        list: function (val) {
            var that = this;
            this.$nextTick(() => {
                console.log("重新执行：",this.list);
                this.initData();
            })
        }
    },
    mounted() {
        this.initData();
    },
    methods: {
        initData() {
            if (this.list != null && this.list.length > 0) {
                //如果是报价下单对应的菜单
                if (this.list[0].children != null && this.list[0].children.length > 0) {
                    this.$set(this.params, "id", this.list[0].children[0].id);
                    this.$set(this.params, "code", this.list[0].children[0].code);
                    this.$set(this.params, "openId", this.list[0].id);
                    this.$set(this,"isMenu",this.list[0].children[0].id);
                    this.$set(this.opends, 0, this.list[0].id + '');
                    var obj = {"id":this.list[0].children[0].id,"code":this.list[0].children[0].code,"openId":this.list[0].id};
                    this.$emit("navParams", obj);
                    //如果是会员对应的菜单
                } else {
                    var item = JSON.parse(sessionStorage.getItem('isMenuMember'));
                    if (item != null) {
                        this.isMenuMember = item;
                    }
                }
            }
        },
        menuSelect(index) {
            console.log('asddvfads',index)
            var openId = "";
            if (index.includes(",")) {
                //点击产品分类菜单
                let data = index.split(",");
                this.params = {
                    id: data[0],
                    code: data[1], openId: ''
                };
                if (this.list != null && this.list.length > 0) {
                    for (let i = 0; this.list.length > i; i++) {
                        for (let j = 0; this.list[i].children.length > j; j++) {
                            if (this.params.id == this.list[i].children[j].id) {
                                openId = this.list[i].id + '';
                                this.params.name = this.list[i].children[j].name
                                this.params.floatRatio = this.list[i].children[j].floatRatio
                                this.params.orderExplain = this.list[i].children[j].orderExplain
                                this.params.telphone = this.list[i].children[j].telphone
                                this.params.needFile = this.list[i].children[j].needFile
                                this.params.fileExplain = this.list[i].children[j].fileExplain
                                break;
                            }
                        }
                    }
                    this.$set(this, "isMenu", data[0]);
                    this.$set(this.params, "id", data[0]);
                    this.$set(this.params, "code", data[1]);
                    this.$set(this.params, "openId", openId);
                    this.$set(this.opends, 0, openId);
                }
                console.log("切换后this.params",this.params);
                this.$emit("navParams", this.params);
            } else {
                //点击会员中心那10个菜单
                // this.isMenuMember = "/" + this.list[index - 1].url;
                // console.log(this.isMenuMember, "this.isMenuMember");
                this.$nextTick(() => {
                    this.eventBus.$emit('sendDataBack', index);
                })
                sessionStorage.setItem('isMenuMember', JSON.stringify(this.isMenuMember));
            }
        },
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
    },
    // 创建完毕状态(里面是操作)
    created() {
        //接收点击顶部菜单参数
        this.eventBus.$on('sendData', val => {
            console.log(val, "this.val");
            if (val != undefined && val != null) {
                console.log(val);
                this.isMenuMember = val;
                sessionStorage.setItem('isMenuMember', JSON.stringify(val));
            }
        });
    },
};
</script>
<style lang="less" scoped>
.offLineTable {
    height: 850px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 240px;
    min-height: 400px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    border: none;
    text-align: left;
}

/deep/.el-menu-item-group__title {
    padding: 0px !important;
}

// .el-menu-bg {
//   background-color: #1f2d3d !important;
// }
.el-menu {
    border: none;
}

.menuItem {
    padding-left: 30px !important;
    background-color: #202020;
    color: #bebebe!important;
}

/deep/.el-menu-item:hover {
    background-color: #d54343 !important;
    color: #fff !important;
    // border-right: 4px solid #fff;
}

// /deep/.is-active{
//   background-color: #d54343 ;
//   color: #fff ;
// }
.logobox {
    padding: 20px 15px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    border-left: 5px solid #d54343;
    color: #d54343;
    font-size: 16px;
    font-weight: 600;
}

.indexIcon {
    width: 20px;
    margin-right: 12px;
}

.isMenu {
    background-color: #d54343 !important;
    color: #fff !important;
}
</style>
