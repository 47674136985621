<template>
  <div class="step">
    <el-steps :active="active" align-center v-if="orderShow">
      <el-step title="提交订单" :description="orderTime"></el-step>
      <el-step title="付款成功" description=""></el-step>
      <el-step title="开始生产" description=""></el-step>
      <el-step title="生产结束" description=""></el-step>
      <el-step title="安排发货" description=""></el-step>
      <el-step title="订单完成" description=""></el-step>
    </el-steps>
    <el-steps :active="active" align-center v-if="assShow">
      <el-step title="提交申请" description=""></el-step>
      <el-step title="客服处理" description=""></el-step>
      <el-step title="处理完成" description=""></el-step>
    </el-steps>
    <el-steps :active="active" align-center v-if="assLiistShow">
      <el-step title="提交申请" :description="orderTime"></el-step>
      <el-step title="客服处理" description=""></el-step>
      <el-step title="处理完成" description=""></el-step>
    </el-steps>
    <el-steps :active="active" align-center v-if="invoiceFormShow">
      <el-step title="填写资料" description=""></el-step>
      <el-step title="支付税费 提交申请" description=""></el-step>
      <el-step title="财务处理" description=""></el-step>
      <el-step title="开票成功" description=""></el-step>
    </el-steps>
  </div>
</template>
<script>
  export default {
    props: {
      active: Number,
      orderTime: String,
      assShow: Boolean,
      orderShow: Boolean,
      assLiistShow: Boolean,
      invoiceFormShow: Boolean
    },
    data() {
      return {
        
      };
    },
    mounted() {
      
    },
    methods: {
      
    },
  }
</script>
<style lang="less" scoped>
  /deep/ .el-steps{
    .el-step__icon{
      width: 30px;
      height: 30px;
      background: #D54343;
      box-shadow: 0px 0px 4px 10px #F7D9D9;
    }
    .el-step__icon.is-text {
      border: 0;
    }
    .el-step__line {
      height: 1px;
      background-color: #BBBBBB;
    }
    .el-step__main {
      margin-top: 8px;
    }
    .el-step__title {
      font-size: 14px;
    }
    //已完成
    .el-step__head.is-finish{
      border-color: #D54343;
      color: #fff;
    }
    .el-step__title.is-finish{
      color: #D54343;
    }
    .el-step__description.is-finish {
      color: #D54343;
    }
    //进行中
    .el-step__head.is-process{
      color: #fff;
    }
    .el-step__title.is-process{
      color: #666;
      font-weight: 400;
    }
    .el-step__head.is-process .el-step__icon{
      background: #CECECE;
      box-shadow: 0px 0px 4px 10px #EFEFEF;
    }
    //未开始
    .el-step__head.is-wait{
      color: #fff;
    }
    .el-step__title.is-wait{
      color: #666;
      font-weight: 400;
    }
    .el-step__head.is-wait .el-step__icon{
      background: #CECECE;
      box-shadow: 0px 0px 4px 10px #EFEFEF;
    }
  }
</style>