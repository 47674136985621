import { render, staticRenderFns } from "./invoiceForm.vue?vue&type=template&id=137b6b6a&scoped=true&"
import script from "./invoiceForm.vue?vue&type=script&lang=js&"
export * from "./invoiceForm.vue?vue&type=script&lang=js&"
import style0 from "./invoiceForm.vue?vue&type=style&index=0&id=137b6b6a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137b6b6a",
  null
  
)

export default component.exports