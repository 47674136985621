import request from "@/api/request";

//会员中心
export function orderCount(data) {
    return request({
        url: '/trade/order/orderStatistic',
        method: 'POST',
        data:data
    })
}

//会员 认证
export function enterprise(data) {
    return request({
        url: '/member/auth/getAuth?memberId=' + data.memberId ,
        method: 'GET',
    })
}

// 会员绑定
export function memberBind(data) {
    return request({
        url: '/bindLogin/memberBind?code='+data.code+'&state='+data.id,
        method: 'GET'
    })
}

//查询会员绑定记录
export function memberBindRecord(data) {
    return request({
        url: '/bindLogin/list?bindType='+data.bindType+'&memberId='+data.memberId,
        method: 'GET'
    })
}

//会员解除绑定
export function memberUnBind(id) {
    return request({
        url: '/bindLogin/'+id,
        method: 'DELETE'
    })
}

//扫码登录
export function qrCodeLogin(code) {
    return request({
        url: '/bindLogin/selectAndLogin?code='+code,
        method: 'GET'
    })
}

export function addEnterprise(data) {
    return request({
        url: '/member/auth/add',
        method: 'POST',
        data:data
    })
}

export function saveEnterprise(data) {
    return request({
        url: '/member/auth/update',
        method: 'POST',
        data:data
    })
}

//绑定用户
export function memberScanBind(data) {
    return request({
        url: '/bindLogin/comapareAndLogin',
        method: 'POST',
        data:data
    })
}
