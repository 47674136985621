<template>
  <div class="invoiceForm">
    <div class="invoiceForm-nav">
      <p @click="goBack">我的发票</p>
      <p>  >  申请发票</p>
    </div>
    <div class="invoiceForm-step">
      <hb-steps
        :active="active"
        :invoiceFormShow="invoiceFormShow">
      </hb-steps>
    </div>
    <div class="invoiceForm-tab">
      <div class="tab-title">
        <span></span>
        <span>订单信息</span>
        <span>待开票金额：￥{{ selectPrice }}，共选择{{ selectionList.length}}条记录 </span>
      </div>
      <el-table
        :data="newVal"
        border
        :header-cell-style="{background:'#f5f5f5',color:'#333','font-weight':'600'}">
        <el-table-column
          prop="orderCode"
          label="订单编号"
          width="130">
        </el-table-column>
        <el-table-column
          prop="productCategoryName"
          label="产品类别"
          width="160">
        </el-table-column>
        <el-table-column
          label="产品明细">
          <template slot-scope="scope">
            <div v-for="(val,index) in scope.row.items" :key="index">
              <div>{{val.productDetail}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderCountMoney"
          label="订单金额"
          width="140">
          <template slot-scope="scope">￥{{ scope.row.orderCountMoney }}</template>
        </el-table-column>
      </el-table>
      <div class="tab-but" v-if="show" @click="more">更多</div>
      <div class="tab-but" v-if="show2" @click="fold">收起</div>
    </div>
    <div class="invoiceForm-form">
      <div class="tab-title">
        <span></span>
        <span>开票信息</span>
      </div>
      <div class="tab-prompt">
        <img src="../../../static//icon/warning.png" alt="">
        <span>电子发票与纸质发票具有同等法律效力，可支持报销入账；同时根据增值税管理办法要求，如需为企业开具增值税发票，需提供纳税人识别号，否则该发票无法作为税收凭证。</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="发票金额：">
          <span style="color: #D54343;">￥{{ selectPrice }}</span>
        </el-form-item>
        <el-form-item label="税费：">
          <span style="color: #D54343;">￥{{ taxation }}</span>
        </el-form-item>
        <el-form-item label="发票类型：" prop="invoiceType">
          <el-radio-group v-model="ruleForm.invoiceType">
            <el-radio label="增值税电子普通发票"></el-radio>
          </el-radio-group>
        </el-form-item>
<!--        headingType-->
        <el-form-item label="抬头类型：" prop="headingType">
          <el-radio-group v-model="ruleForm.invoiceOwnership" @input="radioChange">
            <el-radio label="企业"></el-radio>
            <el-radio label="个人"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发票抬头：" prop="invoiceTitle">
          <el-input v-model="ruleForm.invoiceTitle" placeholder="发票抬头"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号：" prop="taxpayerNum" v-if="type == 0">
          <el-input v-model="ruleForm.taxpayerNum" placeholder="纳税人识别号"></el-input>
          <span style="color: #E99D42;font-size: 12px;cursor: pointer;" @click="expand" v-if="show3">展开非必填信息</span>
          <span style="color: #E99D42;font-size: 12px;cursor: pointer;" @click="retract" v-if="show4">收起非必填信息</span>
        </el-form-item>
        <el-form-item label="地址" v-if="type == 0 && show4 == true">
          <el-input v-model="ruleForm.companyAddress" placeholder="公司地址"></el-input>
        </el-form-item>
        <el-form-item label="电话" v-if="type == 0 && show4 == true">
          <el-input v-model="ruleForm.companyPhone" placeholder="公司电话"></el-input>
        </el-form-item>
        <el-form-item label="开户行" v-if="type == 0 && show4 == true">
          <el-input v-model="ruleForm.openingBank" placeholder="开户行"></el-input>
        </el-form-item>
        <el-form-item label="开户行账号" v-if="type == 0 && show4 == true">
          <el-input v-model="ruleForm.bankAccount" placeholder="开户行账号"></el-input>
        </el-form-item>
        <el-form-item label="身份证号：" prop="card" v-if="type == 1">
          <el-input v-model="ruleForm.card" placeholder="身份证号"></el-input>
          <span style="color: #bebebe;font-size: 12px;">您所提供的信息将得到绝对保护，仅限审核使用。</span>
        </el-form-item>
        <el-form-item label="收票邮箱：" prop="email">
          <el-input v-model="ruleForm.email" placeholder="请输入准确邮箱地址，发票将发送至您的邮箱"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
      <div style="height: 120px;"></div>
    </div>
  </div>
</template>
<script>
  import {
    taxRateSet,
    applyBill
  } from "../../api/connectorList";
  export default {
    props: {
      selectionList: Array,
      selectPrice: Number
    },
    data() {
      return {
        active: 1,
        invoiceFormShow: true,
        ruleForm: {
          invoiceType: '增值税电子普通发票',
          invoiceOwnership: '企业',
          headingType: '企业',
          invoiceTitle: '',
          taxpayerNum: '',
          companyAddress: '',
          companyPhone: '',
          openingBank: '',
          bankAccount: '',
          card: '',
          email: '',
        },
        rules: {
          invoiceType: [
            { required: true, message: '请选择发票类型', trigger: 'change' }
          ],
          invoiceOwnership: [
            { required: true, message: '请选择抬头类型', trigger: 'change' }
          ],
          invoiceTitle: [
            { required: true, message: '请输入发票抬头', trigger: 'blur' }
          ],
          headingType: [
            { required: true, trigger: 'blur' }
          ],
          taxpayerNum: [
            { required: true, message: '请输入纳税人识别号', trigger: 'blur' }
          ],
          card: [
            { required: true, message: '请填写证件号码', trigger: 'blur' },//证件号码校验
            {
              pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
              message: '证件号码格式有误！',
              trigger: 'blur'
            }
          ],
          email: [
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
          ]
        },
        type: 0,
        newVal: [],
        show: false,
        show2: false,
        show3: true,
        show4: false,
        taxation: '',
        headerType: '',
        invoiceOwnership: '0'
      };
    },
    mounted() {
      this.taxRateSet()
      let arr = this.selectionList.slice(0,3)
      if(this.selectionList.length > 3 ) {
        this.show = true
      }
      this.newVal = arr
    },
    methods: {
      goBack() {
        this.$router.go(0)
      },
      taxRateSet() {
        taxRateSet().then((res) => {
          if(res.data.code == 200) {
            let taxRateSize = res.data.data.taxRateSize;
            console.log(taxRateSize,"taxRateSize");
            let taxation = (this.selectPrice * taxRateSize)/ 100;
            //保留2位小数
            taxation = taxation.toFixed(2);
            this.taxation = parseFloat(taxation);
          }
        })
      },
      more() {
        this.newVal = this.selectionList
        this.show = false
        this.show2 = true
      },
      fold() {
        let arr = this.selectionList.slice(0,3)
        this.newVal = arr
        this.show = true
        this.show2 = false
      },
      radioChange(e) {
        this.invoiceOwnership = e
        if(e == '企业') {
          this.invoiceOwnership = '0'
          this.type = 0
        } else if(e == '个人') {
          this.invoiceOwnership = '1'
          this.type = 1
        }
        console.log(this.invoiceOwnership,'===invoiceOwnership====')
      },
      expand() {
        this.show3 = false
        this.show4 = true
      },
      retract() {
        this.show3 = true
        this.show4 = false
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let userInfo = JSON.parse(localStorage.getItem("user"))
            let orderIds = this.selectionList.map(item => (item.id))
            let params = {
              "orderIds": orderIds.toString(),
              "orderNum": this.selectionList.length.toString(),
              "memberId": userInfo.id,
              "memberName": userInfo.name,
              "memberPhone": userInfo.telphone,
              "invoiceAmount": this.selectPrice,
              "billingTax": this.taxation*100,
              "invoiceOwnership": this.invoiceOwnership,
              // "headerType": this.headerType,
              "invoiceTitle": this.ruleForm.invoiceTitle,
              "taxpayerNum": this.ruleForm.taxpayerNum,
              "receiptMail": this.ruleForm.email,
              "memberIdCard": this.ruleForm.card,
              "companyPhone": this.ruleForm.companyPhone,
              "companyAddress": this.ruleForm.companyAddress,
              "openingBank": this.ruleForm.openingBank,
              "bankAccount": this.ruleForm.bankAccount,
              "invoiceOwnership": this.invoiceOwnership,
            }
            this.$emit('invoicePayEmit', true, params)
          } else {
            return false;
          }
        });
      }
    }
  }
</script>
<style lang="less" scoped>
  .invoiceForm {
    padding: 10px 0;
    .invoiceForm-nav {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p:first-child {
        cursor: pointer;
        font-weight: 600;
      }
    }
    .invoiceForm-step {
      background: #fff;
      padding: 50px 0 30px 0;
    }
    .invoiceForm-tab {
      margin-top: 12px;
      background: #fff;
      padding: 0 20px;
    }
    .tab-title {
      height: 58px;
      display: flex;
      align-items: center;
      color: #333;
      font-size: 14px;
      span:first-child {
        width: 4px;
        height: 20px;
        background: #D54343;
      }
      span:nth-child(2) {
        font-weight: 600;
        margin: 0 30px 0 10px;
      }
    }
    .tab-but {
      height: 56px;
      line-height: 56px;
      font-size: 14px;
      color: #D54343;
      cursor: pointer;
    }
    .tab-prompt {
      height: 44px;
      display: flex;
      align-items: center;
      background: #fff3f3;
      color: #D54343;
      font-size: 12px;
      margin-bottom: 10px;
      img {
        width: 18px;
        height: 18px;
        margin: 0 10px;
      }
    }
    .invoiceForm-form {
      margin-top: 12px;
      background: #fff;
      padding: 0 20px;
    }
  }
  /deep/ .el-input__inner {
    width: 400px;
  }
  /deep/ .el-form-item__label {
    width: 140px!important
  }
  /deep/ .el-form-item__content {
    margin-left: 140px!important
  }
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #D54343;
    background: #D54343;
  }
  /deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #D54343;
  }
  /deep/ .el-button--primary {
    color: #FFF;
    background-color: #D54343;
    border-color: #D54343;
  }
</style>
