import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
//import project from '../../public/project.config.json'
import router  from "@/router";
import store from '@/store'
// import store from '@/store'
import { getCookie } from '@/utils/util'
// import errorCode from '@/utils/errorCode'
// axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
// 创建axios实例
export let isRelogin = { show: false };
// 缓存token
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: project.baseUrl,
  // 超时
  timeout: 300000,
});


// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = localStorage.getItem("token") != null ? 'Bearer' + ' ' + localStorage.getItem("token"):'';
    // get请求映射params参数
    // if (config.method === 'get' && config.params) {
    //   let url = config.url + '?';
    //   for (const propName of Object.keys(config.params)) {
    //     const value = config.params[propName];
    //     var part = encodeURIComponent(propName) + "=";
    //     if (value !== null && typeof(value) !== "undefined") {
    //       if (typeof value === 'object') {
    //         for (const key of Object.keys(value)) {
    //           if (value[key] !== null && typeof (value[key]) !== 'undefined') {
    //             let params = propName + '[' + key + ']';
    //             let subPart = encodeURIComponent(params) + '=';
    //             url += subPart + encodeURIComponent(value[key]) + '&';
    //           }
    //         }
    //       } else {
    //         url += part + encodeURIComponent(value) + "&";
    //       }
    //     }
    //   }
    //   url = url.slice(0, -1);
    //   config.params = {};
    //   config.url = url;
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// // 响应拦截器
service.interceptors.response.use((response) => {

  /**
   * response.data对象是服务器返回结果对象
   * code: 200-成功，400-失败，401-重新登录，403-未认证，404-接口不存在，500-服务器内部错误
   */

  /**
   * 打印错误信息
   * console.log(error.request);
   * console.log(error.response);
   */

  const res = response || {};
  if (res.data.code === 200) {
    return Promise.resolve(res);

  }else if(res.data.code === 500|| res.data.code === 501 || res.data.code === 502){
    Message({
      message: res.data.msg,
      type: "error",
      duration: 3 * 1000,
    });
    return Promise.resolve(res);
  }
  else if(res.data.code === 401){
    if (!isRelogin.show) {
      isRelogin.show = true;
      //MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
        //localStorage.setItem("userInfo", null);
      store.dispatch('logout').then(() => {
        isRelogin.show = false;
        //location.href = '/#/loginIndex/login';
        router.replace({
          path: '/loginIndex/login'
          // ,query: {redirect: router.currentRoute.fullPath}
        })
      })
    }
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    // Message({
    //   message: "登录已过期!",
    //   type: "error",
    //   duration: 3 * 1000,
    // });
    // router.replace({
    //   path: '/loginIndex/login'
    //   // ,query: {redirect: router.currentRoute.fullPath}
    // })
   // console.log(res,"res");
    //return Promise.reject(res)
    //location.href="/#/loginIndex/login"
  }
  else {
    Message({
      message: res.data.msg,
      type: "error",
      duration: 3 * 1000,
    });
  }
},
error => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        router.replace({
          path: '/loginIndex/login'
          //,query: {redirect: router.currentRoute.fullPath}
        })
    }
  }
  return Promise.reject(error.response.data)
}
);

// let loginModal = false; //重复弹出要屏蔽

/**
 * 处理响应错误
 * res: 服务器返回对象
 * message: 错误信息
 */
// const handleResponseError = ((res, message) => {
//   //需要重新登录
//   if (res && res.code && (res.code === 401 || res.code === 403)) {
//     if (loginModal) {
//       return;
//     }
//     loginModal = true;
//     setTimeout(() => {
//       loginModal = false;
//     }, 5000);

//     let msg = res.code === 401 ? '检测到你的账号异常，请联系管理员' : '你的登录凭证已过期，可以取消继续留在该页面，或者重新登录';
//     MessageBox.confirm(msg, '重新登录', {
//       closeOnClickModal: false,
//       confirmButtonText: '重新登录',
//       cancelButtonText: '取消',
//       type: 'warning',
//     }).then(() => {

//       router.replace({ name: 'login' });

//     }).catch(() => {

//     });

//   }
//   else if (res.code === 403) //无权限
//   {
//     Message({
//       message: message || '无权访问',
//       type: 'error',
//       duration: 3 * 1000
//     });

//     //router.replace({name:'404'});
//   }
//   else {
//     Message({
//       message: message || '请求失败,请稍后重试',
//       type: 'error',
//       duration: 3 * 1000
//     });
//   }

//   return Promise.reject(res);

// });

export default service;
