<template>
  <div class="typeBox">
    <div class="typeItemBox totalPriceBox">
      <div class="prompt" v-if="productData.fileExplain">
        <img src="../../../static//icon/warning.png" alt="">
        <p>
          <span>上传说明</span>
          <span>{{ productData.fileExplain }}</span>
        </p>
      </div>
      <div class="typeItem flex-c" v-if="productData.needFile == 'Y'">
        <div class="label">文件上传</div>
        <div class="itemUpload">
          <!-- <div>
            <el-upload ref="upload" drag action="#" :headers="headers" :on-change="uploadFile" :on-remove="handleRemove"
              :before-upload="beforeUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
            <el-progress style="margin: 20px 0" :text-inside="true" :stroke-width="26"
              :percentage="progress"></el-progress>
          </div> -->
          <div class="noFile">
            <el-upload
              class="upload-demo"
              drag action="#"
              :on-change="uploadFile"
              :auto-upload="false"
               multiple
              :before-upload="beforeUpload">
              <template v-if="showFlag">
                <img src="../../../static/icon/upload_B.png" class="uploadAdd" />
                <div class="el-upload__text">
                  <p class="upload">请将文件拖拽至此或点击此处开始上传</p>
                  <p class="support">为了更有效率的服务，请务必保证文件实际尺寸和下单尺寸相符</p>
                </div>
              </template>
              <div class="uploaded" v-else>
                <p class="upload">文件正在上传，当前上传进度{{ progress }}%</p>
              </div>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="typeItem flex-c" v-if="productData.needFile == 'Y'">
        <div class="label">文件备注</div>
        <div>
          <el-input
            style="width: 644px;"
            type="textarea"
            :rows="2"
            placeholder="请填写文件备注信息，如有特殊要求务必备注清楚"
            v-model="uploadParams.notes">
          </el-input>
        </div>
      </div>
      <div class="typeItem flex-start">
        <div class="label">订单备注</div>
        <div>
          <el-input
            style="width: 644px;"
            class="type-input"
            type="textarea"
            placeholder="请填写订单备注，如：下单人电话，平台提供夜间配送可填写相关要求及夜间配送放置位置等备注信息"
            v-model="uploadParams.phone"
            maxlength="200"
            clearable>
          </el-input>
        </div>
      </div>
      <div class="typeItem flex-start">
        <div class="label">收货地址</div>
        <div>
          <div class="address" v-for="(item, index) in addressList" :key="index">
            <div class="address-li">
              <img src="../../../static/icon/contacts.png" alt="">
              <span>{{ item.linkman }}</span>
            </div>
            <div class="address-li">
              <img src="../../../static/icon/phone.png" alt="">
              <span>{{ item.telphone }}</span>
            </div>
            <div class="address-li">
              <img src="../../../static/icon/address.png" alt="">
              <span>{{ item.areaNames }}{{ item.address }}</span>
            </div>
          </div>
          <div class="newAddress" @click="allAddresses">
            <img src="../../../static/icon/edit.png" alt="">
            <span>更换地址</span>
          </div>
        </div>
      </div>
      <div class="typeItem flex-start">
        <div class="label">配送方式</div>
        <div class="content">
          <el-radio
            v-model="radio"
            :label="item.value"
            v-for="(item, index) in deliveryList"
            :key="index"
            @change="delivery(item)"
            :disabled='item.disable'
            >{{ item.label }}
          </el-radio>
        </div>
      </div>
      <div class="typeItem flex-start">
        <div class="label">运费</div>
        <div class="freight">
          <div class="freightMoney">
            <span>￥</span>
            <span>{{ Number(endPrice) }}</span>
          </div>
          <!-- 当配送方式是第三方配送时 展示下面的提示信息 1--自有配送  2--第三方配送  3--自提 -->
          <div class="freightInfo" v-if="chooseAddress.type == '2'">
            <i class="el-icon-info">&nbsp;&nbsp;温馨提示：第三方配送时效以实际快递/物流等配送为准，系统计算运费如果与工厂打包发货的实际运费相差较大，客服人员可能会联系您补差运费哦，望理解！</i>
          </div>
        </div>
      </div>
      <div class="typeItem flex-start">
        <div class="label">货款</div>
        <div class="money">
          <span style="font-size: 14px;">￥</span>
          <span style="font-size: 20px;">{{ priceDetails.totalPrice }}</span>
        </div>
      </div>
      <div class="typeItem flex-start">
        <div class="label">优惠券</div>
        <div>
          <p style="line-height: 38px;" v-if="couponList == null || couponList == ''">暂无优惠券可用，请时刻关注领券中心哦！</p>
          <el-select
            v-else
            v-model="couponData"
            style="width: 300px;"
            placeholder="请选择优惠券"
            @change="selecT"
            value-key="id"
            :popper-append-to-body="false">
            <el-option
              v-for="item in couponList"
              :key="item.id"
              :label="item.desc"
              :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="typeItem flex-start">
        <div class="label">总计</div>
        <div class="money2">
          <span style="font-size: 12px;">￥</span>
          <span style="font-size: 32px;font-family: 'FangZhengZhengZhunHei';">{{ preferentialPrice || amount }}</span>
        </div>
      </div>
      <div class="calculate">
        <div class="calculateBtn cursor" @click="submit">确认下单</div>
      </div>
    </div>
    <el-dialog
      title="收货地址"
      :visible.sync="dialogVisible"
      z-index="2000"
      width="50%">
      <div class="dialog-con">
        <!-- 注册地址 -->
        <div class="dialog-address">
          <div class="address-title">注册地址</div>
          <div class="address-con"
            v-for="(item, index) in addressLists"
            :key="index"
            :class="isActiVes == index ? 'isActiVes' : ''"
            @click="checkAss(index,item)">
            <p>
              <span style="margin-right: 10px;">{{ item.linkman }}</span>
              <span>{{ item.telphone }}</span>
            </p>
            <p>{{ item.address }}</p>
          </div>
        </div>
        <!-- 常用地址 -->
        <div class="dialog-useAddress">
          <div class="useAddress-title">
            <span>常用收货地址</span>
            <span @click="newAddress">新增收货地址</span>
          </div>
          <div class="useAddress-list">
            <div class="useAddress-list-li"
              v-for="(item, index) in options"
              :key="index"
              :class="isActiVe == index ? 'isActiVe' : ''"
              @click="checkImg(index,item)">
              <div class="useAddress-list-li-left">
                <p>
                  <span style="margin-right: 10px;">{{ item.linkman }}</span>
                  <span>{{ item.telphone }}</span>
                </p>
                <p>{{item.areaNames}}{{ item.address }}</p>
              </div>
              <div class="useAddress-list-li-right">
                <span @click="edit(item)">编辑</span>
                <span @click="deleTe(item)">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="determine">确 定</el-button>
      </div>
    </el-dialog>
    <hbw-diaLog
      v-if="dialog"
      :title="addressTitle"
      @change="dialogCancel($event)"
      >
      <div class="form">
        <el-form
          label-position="right"
          ref="addressForm"
          label-width="80px"
          :model="addressForm"
          :rules="rules"
          >
          <el-form-item label="联系人" prop="linkman">
            <el-input v-model="addressForm.linkman" placeholder="联系人"></el-input>
          </el-form-item>
          <el-form-item label="手机" prop="telphone">
            <el-input v-model="addressForm.telphone" placeholder="联系人手机"></el-input>
          </el-form-item>
          <el-form-item label="所在区域" prop="areaId">
            <el-cascader
              style="width: 100%"
              v-model="addressForm.areaId"
              placeholder="请选择省市区"
              :options="addressOptions"
              ref="ssqArr"
              @change="handleChange2"
              ></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <!--              <el-popover-->
            <!--                ref="dcPopover"-->
            <!--                placement="top"-->
            <!--                width="340"-->
            <!--                trigger="manual"-->
            <!--                v-model="visible"-->
            <!--              >-->
            <!--                <div class="popoverItemBox">-->
            <!--                  <div-->
            <!--                    class="item"-->
            <!--                    v-for="(item, index) in remoteMethodOptions"-->
            <!--                    :key="index"-->
            <!--                    @click="popoverAddress(item)"-->
            <!--                  >-->
            <!--                    <div class="title">{{ item.title }}</div>-->
            <!--                    <div class="address">{{ item.address }}</div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <el-input-->
            <!--                  slot="reference"-->
            <!--                  type="textarea"-->
            <!--                  v-model="addressForm.address"-->
            <!--                  auto-complete="off"-->
            <!--                  resize="none"-->
            <!--                  placeholder="请准确填写，避免影响订单配送。如具体街道，门牌号，小区，楼号，单元，楼层等信息"-->
            <!--                  @input="addressChange"-->
            <!--                  @blur="closeAddress"-->
            <!--                ></el-input>-->
            <!--              </el-popover>-->
            <el-input
              type="textarea"
              v-model.trim="addressForm.address"
              :maxlength="200" show-word-limit
              placeholder="请准确填写，避免影响订单配送。如具体街道，门牌号，小区，楼号，单元，楼层等信息"
              ></el-input>
          </el-form-item>
        </el-form>
        <div class="formBtn flex-j-e">
          <div class="cancel" @click="FormCancel">取消</div>
          <div class="save" @click="FormSub('addressForm')">保存</div>
        </div>
      </div>
    </hbw-diaLog>
  </div>
</template>

<script>
import cityData from "../../utils/city";
//import project from "../../../public/project.config.json";
import { jsonp } from "vue-jsonp";
import {
  addressListNew,
  addAddress,
  addressList,
  getSendDict,
  deleteAddress,
  editAddress,
  countSendPrice,
  getRightCoupon,
  createOrder, getProdcutByParam, getFileConfig, saveFile
} from "../../api/connectorList";
import user from "@/views/userHome/user";
import axios from "axios";
import qs from 'qs'
export default {
  props: ["priceDetails", "numInput", "productData"],
  watch: {
    priceDetails(val) {
      this.priceDetails = val
    },
    numInput(val) {
      this.numInput = val
    },
    productData(val) {
      this.productData = val
    }
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    }
    return {
      myFileId:null,
      progress: 0,
      uploadId: "",
      file_name: "",
      auto:false,
      listHidden: 'display: none',
      // uploadId: 0,
      maxCounts: 0,
      options: {
        singleFile: true,
        forceChunkSize: false,
        timeout: 120000,//设置超时时间
        target:  this.baseUrl +'/file/big/chunk',
        testChunks: false,//是否开启服务器分片校验
        simultaneousUploads: 10,  //并发上传块数
        chunkSize: 1 * 1024 * 1024,  //每块大小
        headers: {  Authorization: "Bearer " + localStorage.getItem("token") },
        query: {uploadId: 0}
      },
      attrs: {
        // accept: 'image/*'
      },
      selectFileName: '',


      userInfo: null,
      fileListDate: [],
      uploadParams:{
        notes: null,
        phone: null
      },
      baseUrl: "",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      deliveryList: [],
      dialogVisible: false,
      dialog: false,
      addressList: [],
      addressLists: [],
      isActiVe: '-1',
      isActiVes: '-1',
      addressTitle: "新增收货地址",
      addressForm: {
        linkman: "",
        telphone: "",
        areaId: "",
        address: "",
        id: ''
      },
      idClass: '1',
      newAddressList: [],
      sendList: [],
      couponList: [],
      couponData: null,
      couponId: null,
      addressOptions: null,
      rules: {
        linkman: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { min: 2, max: 10, message: "长度在 2 到 10 个字符", trigger: "blur" },
          { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }
        ],
        telphone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
        ],
        areaId: [
          { type: 'array', required: true, message: '请至少选择一个区域', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
      },
      mapData:null,
      visible: false,
      remoteMethodOptions: [],
      regionValue: "",
      radio: '',
      endPrice: '',
      amount: '',
      preferentialPrice: '',
      areaValue:'',
      ssqValue:'',
      ssqArr:[],
      chooseAddress:{},//选中的配送方式
      showFlag: true,
    };
  },

  beforeCreate() {
    this.baseUrl = project.baseUrl;
  },
  created() {
    this.baseUrl = project.baseUrl;
    this.addressOptions = cityData;
    this.userInfo = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    this.getAddressListNew();
  },
  methods: {

    async uploadFile(files, fileList) {
      let that = this;
      that.progress = 0;
      let file1 = files.raw;
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.fileListDate = fileList;
      var fileExtension = file1.name.substring(file1.name.lastIndexOf('.') + 1);
      var name = that.priceDetails.fileName + '.' + fileExtension;

      let number = Date.parse(new Date());
      var split = '/';
      var folder = split + that.userInfo.id + split + number + split;
      let file = new File([file1], folder + name, { type: file1.type });
      console.log("文件信息")
      console.log(file)
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      // const fileTypeArr = ["png", 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'ZIP', 'zip', 'TIFF', 'tiff', 'tif','TIF','rar','RAR','7z','cdr','CDR','7Z'];// 支持得格式
      const fileTypeArr = ["js", 'vbs', 'vbe', 'exe', 'bat', 'com', 'sh', 'dll', 'txt', 'txt']; // 不支持得格式
      const extension = fileTypeArr.some(item => testmsg === item);
      const isLt3G = file.size / 1024 / 1024 < (1024 * 3);
      if (extension) {
        this.$message.error("此文件格式不支持上传！");
        return;
      } else if(!isLt3G){
        this.$message.error("上传文件大小不能超过3G! ");
        return;
      } else {
        this.showFlag = false;
        let OSS = require("ali-oss");
        let res = await getFileConfig();//向后端发请求获取信息
        let client = new OSS({
          bucket: alioss.bucket,
          region: alioss.region,
          accessKeyId: res.data.data.accessKeyId,
          accessKeySecret: res.data.data.accessKeySecret,
          stsToken: res.data.data.securityToken,
          expiration: res.data.data.expiration,
        });
        // 100MB以内，分片大小1MB    100-500MB——分片大小3MB  500-1G——分片大小5MB  1G-3G分片大小10M.
        let partSize;
        let fileSize = file.size / 1024 / 1024;
        if(fileSize < 100){
          partSize = 1024 * 1024;
        } else if(fileSize > 100 && fileSize < 500){
          partSize = (1024 * 1024) * 3;
        } else if (fileSize > 500 && fileSize < 1024){
          partSize = (1024 * 1024) * 5;
        } else if (fileSize > 1024 && fileSize < 1024 * 3){
          partSize = (1024 * 1024) * 10;
        }
        const options = {
          // 获取分片上传进度、断点和返回值。
          progress: (p, cpt, res) => {
            console.log(p)
            if (p == 1) {
              console.log("上传完成")
              that.progressWord = '上传完成';
              setTimeout(() => {
                this.showFlag = true;
              }, 2000);
            }
            that.progress = Number((p * 100).toFixed(1));//进度条
          },
          // 设置并发上传的分片数量。
          parallel: 9999,
          // 设置分片大小。默认值为1 MB，最小值为100 KB。
          partSize: partSize,
          timeout: 1800000,//设置超时时间
          // headers,
          // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
          // meta: { year: 2020, people: "test" },
          // ,
          // callback: {
          //     // 设置回调请求的服务器地址，且要求必须为公网地址。
          //     url: this.baseUrl + "/file/config",
          //     // 设置发起回调时请求body的值。
          //     // body: res.data.body.callback.body,
          //     // // 设置发起回调请求的Content-Type。
          //     // contentType: res.data.body.callback.contentType,
          // },
        };
        async function multipartUpload() {
          console.log("开始分片上传")
          console.log(res)
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
          var name = that.priceDetails.fileName + '.' + fileExtension;
          // let number = Date.parse(new Date());
          // var split = '/';
          // var folder = split+that.userInfo.id + split + number + split;
          console.log("folder")
          console.log(folder)
          name = name.trim();

          var fileName = folder + file.name;
          try {
            //object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
            let result = await client.multipartUpload(
              file.name,
              file,
              {
                ...options,
              }
            );
            console.log("返回结果");
            console.log(result);
            let head = await client.head(file.name);
            console.log(result.res.requestUrls);
            var url = result.res.requestUrls[0];
            console.log("文件地址")
            console.log(url)
            // var size = result.res.headers.content-length;
            // var type = result.res.headers.content-type;

            let fileParm = {
              "fileCloudPath": url,
              "fileName": file1.name,
              "fileBusinessName": name,
              "fileSuffix": "." + fileExtension
            }
            saveFile(fileParm).then((res) => {
              if (res.data.code == 200) {
                let id = res.data.data.id
                that.myFileId = id;
              }
            })
            console.log(head);
          } catch (e) {
            // 捕获超时异常。
            console.log("上传异常");
            that.progress = 0;
            console.log(e);
          }
        }
        multipartUpload(); //调用函数
      }
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      // const fileTypeArr = ["png", 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'ZIP', 'zip', 'TIFF', 'tiff', 'tif','TIF','rar','RAR','7z','cdr','CDR','7Z']; //支持文件格式
      const fileTypeArr = ["js", 'vbs', 'vbe', 'exe', 'bat', 'com', 'sh', 'dll', 'txt', 'txt']; // 不支持得格式
      const extension = fileTypeArr.some(item => testmsg === item);
      const isLt3G = file.size / 1024 / 1024 < (1024 * 3);
      if (extension) {
        this.$message.error("此文件格式不支持上传！");
        return;
      }
      if(!isLt3G){
        this.$message.error("上传文件大小不能超过3G! ");
        return;
      }
      return extension || isLt3G;
    },
    fileRemove() {
      var that = this;
      console.log('移除文件')
      // that.maxCounts -=that.maxCounts
      that.fileListDate = null;
    },

    // onFileError(){
    //     console.log("出错提示");
    // },
    // 上传完成
    complete() {
      console.log('complete', arguments)
    },
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    fileAdded(file) {

      // if (this.maxCounts >= 1) {
      //     this.$message({
      //         type: 'error',
      //         message: '只能上传一个文件,请移除后选择!'
      //     })
      //     file.cancel()
      //     return false
      // }
      file.pause()
      var me = this
      me.maxCounts += 1
      me.selectFileName = file.name
      var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
      console.log(file.name)

      var name = this.priceDetails.fileName + '.' + fileExtension;
      console.log(qs.stringify({ "fileName": name, "name": file.name, }))
      axios.post(this.baseUrl + '/file/big/init', qs.stringify({ "fileName": name, "name": file.name, }), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          // 可以写自己的头
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      }).then(function (response) {
        console.log(response.data)
        me.uploadId = response.data
        me.options.query.uploadId = response.data
        me.listHidden = 'display: block'

        file.resume() //开始上传
        me.auto = true //文件计算完成开始上传
      }).catch(function (error) {
        console.log(error)
      })
      return true
    },

    // // 一个根文件（文件夹）成功上传完成。
    // fileSuccess (rootFile, file, message, chunk) {
    //     var element = document.getElementsByClassName('uploader-file-remove');
    //     if(element!=null&&element.length>0){
    //         element[0].style.display="block";
    //     }else{
    //         console.log("没有找到删除图标节点");
    //     }
    //     var me = this
    //     console.log('file complete', arguments)
    //     axios.post(this.baseUrl +'/file/big/merge', qs.stringify({
    //         uploadId: me.uploadId
    //     }), {
    //         headers: {
    //             'content-type': 'application/x-www-form-urlencoded' ,
    //             Authorization: "Bearer " + localStorage.getItem("token"),
    //         }
    //     }).then(function (response) {
    //         console.log("response")
    //         console.log(response)
    //         if(response.data.code ==200){
    //             console.log("1111111111")
    //             me.fileListDate = response.data.data.id
    //             console.log( me.fileListDate )
    //         }
    //
    //     }).catch(function (error) {
    //         console.log(error)
    //     })
    // },
    // handleChange(file, fileList) {
    //   this.fileListDate = fileList.slice(-1);
    // },
    fileSuccess(res, file) {
      console.log("res上传成功")
      console.log(res)
      if (
        res.data.fileSuffix == ".png" ||
        res.data.fileSuffix == ".jpg" ||
        res.data.fileSuffix == ".jpge" ||
        res.data.fileSuffix == ".tif"
      ) {
        this.fileListDate.push({
          name: res.data.fileName,
          url: res.data.fileCloudPath,
        });
      } else {
        this.fileListDate.push({
          name: res.data.fileName,
          url: "../../../static/icon/file.png",
        });
      }
    },
    handleRemove(file) {
      console.log(file, "====file=====")
    },
    getAddressListNew() {
      let params = {
        defaultFlag: 'Y',
        memberId: this.userInfo.id
      }
      addressListNew(params).then((res) => {
        if (res.data.code == 200) {
          this.addressList = res.data.rows;
          this.addressLists = res.data.rows;
          this.sendList = res.data.rows[0]
          this.getSendDict();
        }
      });
    },
    getSendDict() {
      getSendDict({
        areaId: this.sendList.areaId
      }).then((res) => {
        if (res.data.code == 200) {
          this.deliveryList = res.data.rows
          // 判断是否可以配送 可以的话默认选中郑州市区配送
          let newExpress = [];
          this.deliveryList.map((item, index) => {
            // 过滤可以选中的数组 默认选中首位
            if (!item.disable) {
              newExpress.push(item);
              this.radio = newExpress[0].value;
              this.chooseAddress = newExpress[0];
            }
          })
          this.countSendPrice()
        }
      })
    },
    allAddresses() {
      this.dialogVisible = true
      this.allAddressList();
    },
    delivery(item) {
      this.radio = item.value
      this.chooseAddress = item;
      this.preferentialPrice = null
      this.couponData = null
      this.countSendPrice()
    },
    allAddressList() {
      let params = {
        defaultFlag: 'N',
        memberId: this.userInfo.id
      }
      addressListNew(params).then((res) => {
        if (res.data.code == 200) {
          this.options = res.data.rows
        }
      })
    },
    newAddress() {
      this.dialogVisible = true
      this.dialog = true
      this.addressForm.linkman = ''
      this.addressForm.address = ''
      this.addressForm.telphone = ''
      this.addressForm.areaId = ''
      this.idClass = '1'
    },
    checkAss(index, item) {
      this.isActiVe = '-1'
      this.isActiVes = index
      this.sendList = item
      let arr = []
      arr.push(item)
      this.newAddressList = arr
    },
    checkImg(index, item) {
      this.isActiVes = '-1'
      this.isActiVe = index
      this.sendList = item
      let arr = []
      arr.push(item)
      this.newAddressList = arr
    },
    edit(item) {
      this.dialogVisible = true
      this.dialog = true
      this.addressForm.linkman = item.linkman
      this.addressForm.address = item.address
      this.addressForm.telphone = item.telphone
      this.addressForm.id = item.id
      this.idClass = '2'
      let arr = item.areaId.split(",");
      for (let i = 0; arr.length > i; i++) {
        arr[i] = arr[i] * 1;
      }
      this.addressForm.areaId = arr;
      this.ssqArr = item.areaNameList;
    },
    deleTe(item) {
      this.$confirm('确定删除地址吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteAddress(item.id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleteAddress(id) {
      let ids = id
      deleteAddress({ ids }).then((res) => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.allAddressList();
      });
    },
    selecT(item) {
      let parice = (Number(this.amount)) * 100
      let couponPrice = (Number(item.amount)) * 100
      let amount = (parice - couponPrice) / 100
      if (amount < 0) {
        amount = 0
      }
      this.preferentialPrice = amount.toFixed(2)
      this.couponId = item.id
    },
    getRightCoupon() {
      let productId = JSON.parse(this.productData.id)
      let params = {
        memberId: this.userInfo.id,
        productId: productId,
        amount: this.amount
      }
      getRightCoupon(params).then((res) => {
        if (res.data.code == 200) {
          this.couponList = res.data.data
        }
      })
    },
    determine() {
      // if(this.isActiVe == '-1') {
      //   this.$message({
      //     message: "请选择地址",
      //     type: "warning",
      //   });
      // } else {
      //   this.dialogVisible = false
      //   this.addressList = this.newAddressList
      //   this.countSendPrice()
      // }

      this.dialogVisible = false
      this.addressList = this.newAddressList;
      // 重新调用或许配送方式
      this.getSendDict();
      this.countSendPrice();
    },
    countSendPrice() {
      let params = {
        areaId: this.sendList.areaId,
        addressId: this.sendList.id,
        sendTypeId: this.radio,
        countWeight: this.priceDetails.nextAboutWeight,
        number: this.numInput
      }
      countSendPrice(params).then((res) => {
        if (res.data.code == 200) {
          this.endPrice = res.data.data.endPrice
          let totalPrice = (Number(this.priceDetails.totalPrice)) * 100
          let endPrice = (Number(res.data.data.endPrice)) * 100
          this.amount = (totalPrice + endPrice) / 100
          this.getRightCoupon()
        }
      })
    },
    dialogCancel(val) {
      this.dialog = val;
    },
    FormCancel() {
      this.dialog = false;
      this.dialogVisible = true;
      // if (this.idClass == '1') {
      //   this.dialogVisible = false
      // } else {
      //   this.dialogVisible = true
      // }
    },
    FormSub(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.idClass == '1') {
            this.addAddress()
          } else {
            this.editAddress()
          }
        } else {
          this.$message({
            message: "必填项不能为空",
            type: "warning",
          });
          return false;
        }
      });
    },
    addAddress() {
      let params = {
        memberId: this.userInfo.id,
        linkman: this.addressForm.linkman,
        address: this.addressForm.address,
        telphone: this.addressForm.telphone,
        areaId: this.addressForm.areaId.toString(),
        mapData: "" == this.mapData ? null : this.mapData,
        defaultFlag: 'N'
      }
      addAddress(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.dialog = false;
          this.isActiVe = '-1'
        }
      });
    },
    editAddress() {
      let params = {
        id: this.addressForm.id,
        linkman: this.addressForm.linkman,
        address: this.addressForm.address,
        telphone: this.addressForm.telphone,
        areaId: this.addressForm.areaId.toString(),
        mapData: "" == this.mapData ? null : this.mapData
      }
      editAddress(params).then((res) => {
        if (res.data.code == 200) {
          this.allAddressList();
          this.dialog = false;
          this.dialogVisible = true
          this.isActiVe = '-1'
        }
      })
    },
    handleChange2(value) {
      const checkedNode = this.$refs["ssqArr"].getCheckedNodes();
      var tempArr = checkedNode[0].pathLabels;
      var temp = "";
      for (var i = 0; i < tempArr.length; i++) {
        temp += tempArr[i];
      }
      this.ssqValue = temp;
      this.ssqArr = tempArr;
    },
    popoverAddress(data) {
      //TODO 0922 去除地址校验
      // if(data!=null&&data.district!=this.ssqArr[2]){
      //   this.$alert("您选择地址在"+data.district+",不在所选择的区域，请重新选择!", {
      //     confirmButtonText: '确定',
      //     callback: action => {
      //     }
      //   });
      // }
      this.mapData = "" == data ? null : data;
      //TODO 修改选择地址展示
      let add = data.title;
      //let add=data.address+data.title;
      this.addressForm.address = add;
      //this.addressForm.address = data!=undefined&&data.title!=undefined?data.title:''
      this.visible = false;
    },
    addressChange(val) {
      if (val.length !== 0) {
        this.visible = true;
        this.remoteMethod(val);
      } else {
        this.visible = false;
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        if (this.ssqArr == undefined || this.ssqArr == null || this.ssqArr.length == 0) {
          const checkedNode = this.$refs["ssqArr"].getCheckedNodes();
          this.ssqArr = checkedNode[0].pathLabels;
        }
        const params = {
          output: "jsonp",
          region: this.ssqArr[1],
          page_size: "20",
          region_fix: 1,
          keyword: query,
          key: "P3XBZ-JQAWL-KEQPI-MW7M6-OWX6V-KSBYQ",
        };
        let url = `https://apis.map.qq.com/ws/place/v1/suggestion/`;
        var that = this;
        jsonp(url, params)
          .then((res) => {
            if (res.status == 0) {
              if (res.data != null && res.data != 'undefined' && res.data != '') {
                var tempArr = [];
                tempArr = res.data.map((item) => {
                  return item;
                });
                this.remoteMethodOptions = tempArr;
                if (this.remoteMethodOptions == null || this.remoteMethodOptions.length == 0) {
                  this.visible = false;
                }
                this.$nextTick(() => {
                  this.$refs.dcPopover.updatePopper();
                });
              }
            }
          })
          .catch((err) => {
          });
      } else {
        this.remoteMethodOptions = [];
      }
    },
    closeAddress() {
      this.visible = false;
    },

    // onProgressTest (event, file, fileList) {
    //     let index = 0
    //     // 只有fileList 跟file 里面的uid是唯一的互相对应的
    //     fileList.forEach((v, i) => {
    //         if (v.uid === file.uid) {
    //             index = i
    //         }
    //     })
    //     // 获取动态的进度
    //     let percent = parseInt(event.percent)
    //     this.$nextTick(() => {
    //         setTimeout(() => {
    //             if (percent >= 99) {
    //                 // 当动态的进度超过99就获取dom 让值一直显示99直到上传成功
    //                 let dom = document.getElementsByClassName('el-upload-list__item')[index]
    //                 let progressText = dom.getElementsByClassName('el-progress__text')
    //                 if (progressText && progressText.length) {
    //                     progressText[0].style.opacity = 0
    //                     progressText[0].innerText = '99%'
    //                     progressText[0].style.opacity = 1
    //                 }
    //             }
    //         })
    //     })
    // },
    //确认下单
    submit() {
      if (undefined == this.productData.needFile) {
        this.$message({
          message: "未读取到文件验证信息",
          type: "warning",
        });
        return
      }
      let fileId = "";
      if ('Y' == this.productData.needFile && null == this.myFileId) {
        this.$message({
          message: "请上传文件",
          type: "warning",
        });
        return
      } else if ('Y' == this.productData.needFile && null != this.myFileId) {
        fileId = this.myFileId;
        // fileId = this.fileListDate[0].response.data.id
      }
      let orderCountMoney = ''

      if (!this.preferentialPrice) {
        orderCountMoney = this.amount ? this.amount : this.amount.toFixed(2)
      } else {
        orderCountMoney = this.preferentialPrice ? this.preferentialPrice : this.preferentialPrice.toFixed(2)
      }
      let productId = JSON.parse(this.productData.id)
      //TODO 去除多余拼接 0925 复原拼接
      //let address =  this.sendList.address
      let address = this.sendList.areaNames + this.sendList.address
      let params = {
        "loginUserId": this.userInfo.id,
        "orderType": "0",
        "list": [{
          "userId": this.userInfo.id,
          "productId": productId,
          "couponId": this.couponId,
          "distributionMode": this.radio,
          "goodsMoney": this.priceDetails.totalPrice,
          "moveMoney": this.endPrice,
          "orderCountMoney": orderCountMoney,
          //"orderCountMoney": '0.01',
          "consumerPhone": this.uploadParams.phone,
          "addressId": this.sendList.id,
          "consigneePerson": this.sendList.linkman,
          "consigneePhone": this.sendList.telphone,
          "addressDesc": address,
          "memberName": this.userInfo.name,
          "memberPhone": this.userInfo.telphone,
          "anticipateShippingTime": this.priceDetails.aboutSaveTime,
          "fileNum": null,
          "quoteRecordId": this.priceDetails.placeOrderCode,
          "areaId": this.sendList.areaId,
          "items": [{
            "productCategory": productId,
            "count": this.numInput,
            "productDetail": this.priceDetails.fileName,
            "theoryWeight": this.priceDetails.nextAboutWeight,
            "fileId": fileId,
            "productPrice": this.priceDetails.totalPrice,
            "fileRemark": this.uploadParams.notes
          }]
        }]
      }
      createOrder(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "下单成功",
            type: "success",
          })
          let id = res.data.data.toString()
          let orderShow = '1'
          this.$router.push({ path: 'userHome/myOrder', query: { id: id, orderShow: orderShow } });
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.typeBox {
  .typeItemBox {
    .prompt {
      background: #FFF3F3;
      padding: 12px;
      margin-bottom: 18px;
      display: flex;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
        margin-right: 16px;
      }

      p {
        display: flex;
        flex-direction: column;
        color: #D54343;

        span:first-child {
          font-size: 14px;
          font-weight: 600;
          line-height: 28px;
        }

        span:last-child {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }

    .typeItem {
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 22px;
      display: flex;
      align-items: flex-start;

      .label {
        box-sizing: border-box;
        padding: 10px 0;
        width: 110px;
        background-color: rgba(255, 232, 232, 1);
        color: rgba(51, 51, 51, 1);
        text-align: center;
        border-radius: 4px;
        margin-right: 18px;
      }

      .content {
        padding: 10px 0;
      }

      .money {
        color: #D54343;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .money2 {
        color: #D54343;
        height: 38px;
      }

      .itemUpload {
        width: 100%;

        /deep/.el-upload {
          width: 100%;
        }

        /deep/.el-upload-dragger {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          background-color: #f5f5f5;
        }

        /deep/.el-upload-dragger:hover {
          border-color: #d54343;
          background-color: #fff3f3;
        }
      }

      .priceDetail {
        padding: 10px 20px;
        background-color: #f8f8f8;
        width: 90%;
      }

      .address {
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;

        .address-li {
          margin-right: 38px;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            margin-right: 12px;
          }

          span {
            line-height: 20px;
          }
        }
      }

      .newAddress {
        font-size: 14px;
        color: #D54343;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }

        span {
          line-height: 20px;
        }
      }
    }

    .calculate {
      margin-top: 48px;
      margin-left: 128px;

      .calculateBtn {
        width: 140px;
        background-color: #d54343;
        color: #fff;
        text-align: center;
        padding: 10px 0;
        border-radius: 4px;
      }

      .calculateBtn:hover {
        background: #B72C2C;
      }
    }
  }

  .dialog-con {
    font-size: 14px;

    .dialog-address {
      .address-title {
        color: #6C6C6C;
        margin-bottom: 5px;
      }

      .address-con {
        padding: 16px;
        background: #EFEFEF;
        border: 1px solid #EFEFEF;
        color: #666;
        line-height: 22px;

        p:first-child span {
          color: #333;
          font-weight: 600;
        }
      }

      .address-con:hover {
        background: #FFF3F3;
      }

      .isActiVes {
        border: 1px solid #D54343;
        background: #FFF3F3;
      }
    }

    .dialog-useAddress {
      margin-top: 34px;

      .useAddress-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:last-child {
          width: 110px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border: 1px solid #D54343;
          background: #FFF3F3;
          font-size: 12px;
          color: #D54343;
          border-radius: 4px;
          cursor: pointer;
        }
      }

      .useAddress-list {
        margin-top: 12px;

        .useAddress-list-li {
          padding: 16px;
          background: #EFEFEF;
          border: 1px solid #EFEFEF;
          color: #666;
          line-height: 22px;
          margin-bottom: 12px;
          display: flex;
          justify-content: space-between;

          .useAddress-list-li-left {
            width: 76%;

            p:first-child span {
              color: #333;
              font-weight: 600;
            }
          }

          .useAddress-list-li-right {
            width: 22%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 12px;
            font-weight: 600;

            span {
              cursor: pointer;
              color: #000;
            }

            span:hover {
              color: #D54343;
            }
          }
        }

        .useAddress-list-li:hover {
          background: #FFF3F3;
        }

        .isActiVe {
          border: 1px solid #D54343;
          background: #FFF3F3;
        }
      }
    }
  }

  .dialog-footer {
    margin-top: 20px;
  }

  .formBtn {
    div {
      cursor: pointer;
      padding: 5px 20px;
      border-radius: 4px;
    }

    .cancel {
      background-color: #efefef;
    }

    .save {
      color: #fff;
      margin-left: 20px;
      background-color: #d54343;
    }
  }
}

.popoverItemBox {
  height: 260px;
  overflow-y: auto;

  .item {
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;

    .address {
      color: #999;
      font-size: 12px;
    }
  }

  .item:hover {
    .title {
      color: #b72c2c;
    }
  }
}

// 运费
.freight {
  display: flex;
  flex-direction: column;
  .freightMoney {
    color: #D54343;
    height: 38px;
    font-size: 18px;
  }

  .freightInfo {
    color: #D54343;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    background-color: #fff3f3;
    box-sizing: border-box;
    font-size: 14px;
  }
}

// 上传
.noFile {
  margin: 0 auto;
  .upload {
    color: #333;
    font-size: 22px;
  }
  .support {
    color: #666;
    font-size: 16px;
    margin-top: 8px;
  }
}

/deep/ .el-textarea__inner:focus {
  border: #D54343 1px solid;
}

/deep/ .el-input__inner:focus {
  border: #D54343 1px solid;
}

/deep/ .el-cascader .el-input .el-input__inner:focus,
.el-cascader .el-input.is-focus .el-input__inner {
  border-color: #D54343;
}

/deep/ .el-dialog__header {
  padding: 14px 22px 16px;
  background: #D54343;
}

/deep/ .el-dialog__title {
  font-size: 14px;
  color: #fff;
}

/deep/ .el-dialog__headerbtn {
  top: 16px;
}

/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
  margin-bottom: 2px;
}

/deep/ .el-dialog__footer {
  text-align: center;
}

/deep/ .el-button--primary {
  background: #D54343;
  border-color: #D54343;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #D54343;
  background: #D54343;
}

/deep/ .el-radio__input .el-radio__inner:hover {
  border-color: #D54343;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #D54343;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #D54343;
}

/deep/ .el-select-dropdown__item.selected {
  color: #d54343;
  font-weight: 700;
}

/deep/ .el-select-dropdown__item {
  color: #000;
}

/deep/ .diaLogBox {
  z-index: 2003;
}

/deep/ .el-cascader__dropdown {
  z-index: 2021 !important;
}
.uploader-example {
  width: 880px;
  padding: 15px;
  margin: 40px auto 0;
  font-size: 12px;
}
</style>
