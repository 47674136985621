<template>
  <el-dialog
      :show-close="false"
      :visible.sync="visible"
      width="480px" 
      >
    <div class="login-container">
      <div class="loginWrap-top">
        <div class="QR-box flex-j-e">
          <img
              v-if="loginPattern == false"
              @click="loginPatterns"
              src="../../../static/login/switch-QR.png"
              alt=""
          />
          <img
              v-else
              @click="loginPatterns"
              src="../../../static/login/switch-PC.png"
              alt=""
          />
        </div>
        <div class="logo flex-j-c">
          <img src="../../../static/logo.png" alt="" />
        </div>
      </div>
      <Login @isWechat="wechatFunction" @viewDialog="viewDialog" :num="num" v-if="loginPattern == false"></Login>
      <WechatLogin @accountLogin="accountLogin" v-else :num="num"></WechatLogin>
    </div>
    <div class="close">
      <img src="../../../static/icon/close.png" alt="" @click="close">
    </div>
  </el-dialog>
</template>
<script type="text/ecmascript-6">
import {qrCodeLogin} from "@/api/user";

export default {
  name: "LoginWhole",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loginPattern: false,
      num: '1'
    };
  },
  created() {
  },
  methods: {
    loginPatterns() {
      this.loginPattern = !this.loginPattern;
    },
    wechatFunction(val){
      this.loginPattern = val
    },
    viewDialog(val){
      this.visible = val;
    },
    accountLogin(val) {
      this.loginPattern = val
      console.log(val)
    },
    close() {
      this.visible = false
    },
  },
};
</script>

<style scoped lang="less">
  .loginWrap-top {
    margin-bottom: 32px;
    .QR-box {
      width: 100%;
      margin-bottom: 6px;
      img {
        width: 42px;
        cursor: pointer;
      }
    }
    .logo {
      width: 100%;
      img {
        width: 186px;
      }
    }
  }
  .login-container {
    margin: 0px auto;
    position: absolute;
    top: 38%;
    left: calc(50% - 240px);
    width: 460px;
    padding: 10px;
    background: #fff;
    text-align: left;
  }
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
  .close {
    position: absolute;
    left: 220px;
    top: 560px;
    height: 200px;
    display: flex;
    justify-content: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
</style>
