<template>
  <div class="goodBox">
    <!-- 报价 -->
    <div class="quotationBox">
      <div class="titleBox">
        <div class="flex-c">
          <div class="vertical"></div>
          <p style="
                font-size: 18px;
                font-weight: 600;
                color: rgba(79, 79, 79, 1);
              ">
            在线报价
          </p>
          <p style="font-size: 16px; color: #666; margin-left: 45px">
            {{ null == productData ? null : productData.telphone }}
          </p>
        </div>
      </div>
      <div class="quotationContent">
        <div class="hint flex-j-s" v-if="productData != null && productData != ''
          && productData.orderExplain != null && productData.orderExplain != ''
          ">
          <img src="../../../static/icon/warning.png" alt="" />
          <div class="flex-d-start-c">
            <p style="font-size: 14px; font-weight: 600; margin-bottom: 5px">
              下单注意
            </p>
            <p>{{ productData.orderExplain }}</p>
          </div>
        </div>
        <offer-place-new
          ref='offer_place_child'
          :goodsData="goodsData"
          :goodIndex="goodIndex"
          :productData="productData"
          v-if="goodsData && goodsData.length > 0"
          :dialogFlag=true
          @setCountPriceShow="setShow"
          />
          <!-- 底部 -->
        <div class="footer" v-show="showConfirm">
          <div class="confirm" @click="confirm()">确定</div>
          <div class="cancel" @click="cancel()">取消</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlaceData, getProdcutByParam } from '@/api/connectorList';

export default {
  props: {
    project: Object,
    goodIndex:{
      type:[ Number, Array ],
    },
    isBetchFlag: Boolean,
  },
  data() {
    return {
      goodsData: false || null,
      productData: false || null,
      CountPriceShow: false,
      priceDetails: [],
      priceParams: '',
      showConfirm: false,
    };
  },
  watch: {
    project: function (val) {
      //console.log("切换回调后goodsDetail:",val);
      this.numInput = ''
      this.$store.state.radioCodeList.length = [];
      this.$store.state.craftData = [];
      this.$nextTick(()=>{
        this.getByParam(val);
        //this.selectProductData(val);
      })
    },
  },
  mounted() {
    this.numInput = ''
    this.$store.state.radioCodeList.length = [];
    this.$store.state.navconRightIndex = 1;
  },
  methods: {
    async getByParam(data) {
      if(!data){
        console.log("数据为空",data);
        return false;
      }
      let that = this;
      let params = {
        id: data.id,
        code: data.code,
      };
      const { data: res } = await getPlaceData(params);
      const { data: res1 } = await getProdcutByParam({ id: params.id });

      let arr = res.data;
      arr.sort(that.compare("id"));
      that.$set(that,"goodsData",arr);
      that.$set(that,"productData",res1.data);
      console.log("切换回调后productData:",this.productData);
      console.log("切换回调后goodsData:",this.goodsData);
    },
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    setShow(val, item, num, flag) {
      this.priceDetails = item;
      this.numInput = num
      this.priceParams = []
      this.showConfirm = flag;
      sessionStorage.setItem('priceDetails', this.priceDetails)
    },
    async selectProductData(val) {
      //根据id查询产品
      var that = this;
      if (val != null && val.id != undefined && val.id != null) {
        await getProdcutByParam({ id: val.id }).then((res) => {
          if (res.data.code == 200) {
            //that.productData = res.data.data;
            that.$set(that,"productData",res.data.data);
          }
        });
      } else {
        this.$message.error("没有取到产品数据...");
      }
    },
    confirm() {
      // 不是批量选品时再进行存储
      if(this.isBetchFlag){
          if(this.goodIndex!=null&&this.goodIndex.length>0){
            this.goodIndex.forEach(item=>{
              if(this.project){
                console.log("全选保存索引项：","menu_"+item);
                console.log("全选保存索引值：",JSON.stringify(this.project));
                localStorage.setItem("menu_"+item,JSON.stringify(this.project));
                this.$refs.offer_place_child.saveEchoData(item,this.project.id);
              }
            })
          }
      }else{
        if(this.project){
          console.log("保存索引项：","menu_"+this.goodIndex);
          console.log("保存索引值：",JSON.stringify(this.project));
          localStorage.setItem("menu_"+this.goodIndex,JSON.stringify(this.project));
          this.$refs.offer_place_child.saveEchoData(this.goodIndex,this.project.id);
        }
      }
      this.$emit('confirm', this.priceDetails, this.productData)
    },
    cancel() {
      this.$emit('cancle')
    }
  },
};
</script>

<style lang="less" scoped>
.goodBox {
  width: 100%!important;
  .quotationBox {
    .quotationContent {
      box-sizing: border-box;
      padding: 12px 16px;
      background-color: #fff;
      min-height: 900px;

      .hint {
        background-color: #FFF3F3;
        padding: 13px;
        color: #d54343;
        font-size: 12px;
        margin-bottom: 22px;

        p {
          margin-left: 12px;
        }
      }
    }
  }

  .placeBox {
    margin-top: 12px;

    .placeContent {
      box-sizing: border-box;
      padding: 12px 16px;
      background-color: #fff;
      height: 1150px;
    }
  }

  .titleBox {
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid rgba(239, 239, 239, 1);

    .vertical {
      width: 5px;
      height: 50px;
      margin-right: 17px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    div {
      width: 100px;
      height: 38px;
      line-height: 38px;
      border-radius: 4px;
      text-align: center;
    }

    .confirm {
      background-color: #d54343;
      color: #fff;
      margin-right: 25px;
      cursor: pointer;
    }

    .cancel {
      background-color: #efefef;
      color: #333;
      cursor: pointer;
    }
  }
}
</style>
