<template>
  <div class="orderPayment">
    <el-container class="confirmOrder">
      <el-header class="confirmOrder-header">
        <p @click="goBack">我的订单</p>
        <p>  >  确认付款</p>
      </el-header>
      <el-main>
        <div class="orderList">
          <div class="orderList-li" v-if="showForm">
            <div class="li-title">
              <img src="../../../static/icon/warning.png" alt="">
              <p>当前订单状态：待付款</p>
            </div>
            <div class="li-nav">
              <span style="width: 130px;">产品类别</span>
              <span style="width: 420px;">产品明细</span>
              <span style="width: 100px;">理论重量</span>
              <span style="width: 220px;">文件信息</span>
              <span style="width: 200px;">文件备注</span>
              <span style="width: 120px;">订单备注</span>
              <span style="width: 100px;">产品价格</span>
              <span style="width: 120px;">订单金额</span>
              <span style="width: 140px;">预计出货时间</span>
            </div>
            <div v-for="(item,index) in orderData" :key="index">
              <div class="li-name">
                <span>订单编号：{{ item.orderCode }}</span>
                <span>下单时间：{{ item.orderTime }}</span>
                <span>{{ item.distributionModeClassName }}</span>
                <span>{{ item.consigneePerson }} - {{ item.consigneePhone }} - {{ item.addressDesc }}</span>
              </div>
              <div class="li-tr">
                <p style="width: 130px;">{{ item.productCategoryName }}</p>
                <div style="display: flex;flex-direction: column;">
                  <div style="display: flex;align-items: center; margin: 8px 0px;" v-for="(eve, index) in item.items" :key="index">
                    <p style="width: 420px;">{{ eve.productDetail }}</p>
                    <p style="width: 100px;">{{ eve.theoryWeight }}kg</p>
                    <p class="li-tr-img" style="width: 220px;">
                      <!-- <img v-if="eve.fileSuffix == '.zip'" src="../../../static/icon/mo.png" alt="">
                      <img v-if="eve.fileSuffix == '.tif'" src="../../../static/icon/mo2.png" alt=""> -->
                      <img class='fileTxt' v-if="!(eve.fileCloudPath == null)" src="../../../static/icon/proview_img.png" alt="" @click="fileTypeArr.some(ele => eve.fileSuffix === ele) ? maxImg(eve.fileCloudPath):''">
                      <span class='fileTxt' v-if="!(eve.fileCloudPath == null)" :title="eve.fileName" @click="fileTypeArr.some(ele => eve.fileSuffix === ele) ? maxImg(eve.fileCloudPath) : ''">{{ eve.fileName }}</span>
                    </p>
                    <p class="li-tr-more" style="width: 200px;">
                      <span :title="eve.fileRemark">{{ eve.fileRemark }}</span>
                    </p>
                  <p style="width: 120px;">{{ item.consumerPhone }}</p>
                    <p style="width: 100px;">￥{{ eve.productPrice }}</p>
                  </div>
                </div>
                <p class="li-tr-price" style="width: 120px;">
                  <span>￥{{ item.orderCountMoney }}</span>
                  <span>货款：￥{{ item.goodsMoney }}</span>
                  <span>运费：￥{{ item.moveMoney }}</span>
                  <span>优惠：￥{{ item.favorable }}</span>
                </p>
                <p style="width: 140px;">{{ item.anticipateShippingTime }}</p>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer v-if="showForm">
        <div class="bottom-payment">
          <div class="bottom-payment-title">
            <p></p>
            <p>支付方式</p>
            <p>余额：￥{{ accountBalance }}元</p>
          </div>
          <div class="bottom-payment-con">
            <p
              v-for="(item, index) in paymentList"
              :key="index"
              :class="isActiVe == index ? 'isActiVe' : ''"
              @click="checkImg(index)">
              <img :src="item.img" alt="">
              <img
                v-if="isActiVe == index ? 'isActiVe' : ''"
                style="width: 30px;height: 30px;"
                class="bottom-payment-con-selected"
                src="../../../static/icon/selected.png"
                alt="">
            </p>
          </div>
        </div>
        <div class="bottom-button">
          <p>
            <span>应付：</span>
            <span>￥{{ orderCountMoney }}</span>
          </p>
          <p class="toPay" @click="isClick && submit()">{{sumbitText}}</p>
        </div>
      </el-footer>
    </el-container>
    <hbw-diaLog
      v-if="dialog"
      title="确认支付"
      @change="dialogCancel($event)"
    >
      <div>
        <img :src="url" alt="">
        <p>请使用微信或支付宝扫一扫扫描二维码支付</p>
      </div>
    </hbw-diaLog>
    <el-dialog width="30%" :visible.sync="dialogVisible">
      <img width="100%" :src="newImg" alt="" />
    </el-dialog>
  </div>
</template>

<script>
  import {
    userBalance,
    getBetchOrderByIds,
    orderSubmit,
    getOrderMoney
  }from "../../api/connectorList";
  import { createSocket,sendWSPush,closeWs } from '../../utils/websocket'
  export default {
    props: ["id"],
    data() {
      return {
        accountBalance: null,
        isActiVe: '-1',
        paymentList: [
          {
            img: require('../../../static/icon/balance.png'),
          },
          {
            img: require('../../../static/icon/bankCard.png'),
          },
        ],
        orderData: null,
        showForm: false,
        userInfo: null,
        dialog:false,
        url: null,
        dialogVisible: false,
        newImg: '',
        sumbitText:'确认支付',
        isClick:true,
        fileTypeArr: ['.jpg','.JPG','.png','.PNG','.JPEG','.jpeg'],
        orderCountMoney: null,
      };
    },
    created() {
      this.userInfo = JSON.parse(localStorage.getItem("user"));
      this.userBalance()
    },
    mounted() {
      this.getById()
    },
    methods: {
      userBalance() {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        userBalance({userId:userInfo.id}).then((res) => {
          if(res.data.code == 200) {
            this.accountBalance = res.data.data.accountBalance;
          }
        })
      },
      async getById() {
        await getBetchOrderByIds([this.id]).then((res) => {
          if(res.data.code == 200) {
            this.orderData = res.data.data;
            this.showForm = true;
          }
        })
        // 获取订单金额
        await this.getOrderCountMoney();
      },
      getOrderCountMoney(){
        let orderId = [];
        this.orderData.map(item=>{
          return orderId.push(item.payOrderId)
        })
        getOrderMoney(orderId.join(',')).then(res=>{
          if(res.data.code == 200){
            this.orderCountMoney = res.data.data;
          }
        })
      },
      maxImg(file) {
        this.newImg = file
        // this.dialogVisible = true
        window.open(file+'?response-content-type=IMAGE/JPEG')
        // var name = "预览图片";
        // var iWidth = '860'; //弹出窗口的宽度;  
        // let url = file+'?response-content-type=IMAGE/JPEG';
        // var iHeight = window.innerHeight * 0.8;      //弹出窗口的高度;
        // var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;  
        // var iLeft = (window.screen.width - 10 - iWidth) / 2;  //获得窗口的水平位置;
        // window.open(url, name, 'height=' + iHeight + ',innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',menubar=yes,location=yes');
        // window.document.write ("<h1>这是新打开的窗口</h1>");  
      },
      checkImg(index){
        console.log(index)
        this.isActiVe = index
      },
      dialogCancel(val) {
        if(val == false) {
          this.open()
        }
      },
      open() {
        this.$confirm('确定要取消支付吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '已取消支付!'
          });
          this.dialog = false
          this.$router.push({ query: {} })
          this.$router.go(0)
        }).catch(() => {
          this.dialog = true
        });
      },
      submit() {
        let payOrderIdArr = [];
        let idsArr = [];
        if(!(this.id instanceof Array)){
          idsArr.push(this.id);
        } else{
          idsArr = this.id;
        }
        idsArr.map(item=>{
          return payOrderIdArr.push({
            "orderId": item
          })
        })
        if(this.isActiVe == '-1') {
          this.$message({
            message: "请选择支付方式",
            type: "warning",
          });
          return
        } else if(this.isActiVe == '0') {
          //余额支付
          let params = {
            "payType": '2',
            "userId": this.userInfo.id,
            "orders": payOrderIdArr
          }
          this.sumbitText = '支付中...';
          this.isClick = false;
          orderSubmit(params).then((res) => {
            this.isClick = true;
            this.sumbitText = '确认支付';
            if(res.data.code == 200) {
              //支付成功  订单列表出现  订单余额页隐藏
              this.$message({
                message: "支付成功",
                type: "success"
              });
              this.$router.push({ query: {} })
              this.$router.go(0)
            }
          }).catch((error) => {
            this.isClick = true;
            this.sumbitText = '确认支付';
          }).finally(()=>{
            this.isClick = true;
            this.sumbitText = '确认支付';
          })
        } else {
          createSocket()
            this.getsocketData = (e) => {
            let data = e && e.detail.data
            if(data){
              let json = JSON.parse(data);
              console.log(json)
              if(json.status == '03') {
                this.$message({
                  message: "支付成功",
                  type: "success"
                });
                this.$router.push({ query: {} })
                this.$router.go(0)
                closeWs()
              }
              if (json.event == 'open') {
                let msg = {"event":'open_screen',"to_user":this.userId}
                sendWSPush(msg)
              }
            }
          }
          window.addEventListener('onmessageWS', this.getsocketData)
          let params = {
            "payType": '1',
            "userId": this.userInfo.id,
            "orders": payOrderIdArr
          }
          orderSubmit(params).then((res) => {
            if(res.data.code == 200) {
              this.dialog = true
              this.url = res.data.msg
            }
          })
        }
      },
      goBack() {
        this.$router.push({ query: {} })
        this.$router.go(0)
      }
    },
  };
</script>

<style lang="less" scoped>
  .confirmOrder {
    display: flex;
    flex-direction: column;
    .confirmOrder-header{
      height: 48px!important;
      padding-left: 0px;
      padding-bottom: 10px;
    }
    .orderList {
      .orderList-li {
        background: #fff;
        padding-bottom: 20px;
        .li-title {
          height: 75px;
          padding: 0 20px;
          background: #f5f5f5;
          display: flex;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          p {
            font-size: 16px;
            color: #D54343;
          }
        }
        .li-nav {
          height: 50px;
          margin-top: 10px;
          padding: 0 20px;
          background: #f5f5f5;
          display: flex;
          align-items: center;
          span {
            font-size: 14px;
            color: #333;
            font-weight: 600;
          }
        }
        .li-name {
          height: 34px;
          background: #f5f5f5;
          display: flex;
          align-items: center;
          padding: 0 20px;
          margin-top: 10px;
          span {
            color: #999;
            font-size: 14px;
            margin-right: 26px;
          }
        }
        .li-tr {
          display: flex;
          align-items: center;
          padding: 0 20px;
          min-height: 110px;
          border: 1px solid #EBEBEB;
          font-size: 14px;
          .li-tr-img {
            display: flex;
            align-items: center;
            img {
              width: 40px;
              height: 40px;
              margin-right: 14px;
            }
            span {
              width: 150px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 2;
            }
          }
          .li-tr-more {
            span {
              width: 150px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 2;
            }
          }
          .li-tr-price {
            display: flex;
            flex-direction: column;
            span {
              font-size: 12px;
              color: #999;
            }
            span:first-child {
              color: #D54343;
              font-size: 18px;
            }
          }
        }
      }
    }
    .bottom-payment {
      margin: 14px -20px 0 -20px;
      padding: 5px 20px 42px 20px;
      background: #fff;
      .bottom-payment-title {
        padding: 20px 0;
        display: flex;
        align-items: center;
        p:first-child {
          width: 4px;
          height: 16px;
          background: #D54343;
        }
        p:nth-child(2) {
          color: #101010;
          font-size: 18px;
          font-weight: 600;
          margin: 0 20px 0 10px;
        }
        p:last-child {
          color: #999;
          font-size: 12px;
        }
      }
      .bottom-payment-con {
        display: flex;
        p {
          width: 200px;
          height: 75px;
          border: 1px solid #fff;
          position: relative;
          img {
            background-size: 100%;
            border: none;
          }
          span {
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 200px;
            text-align: center;
            font-size: 12px;
            color: #999;
          }
          .bottom-payment-con-selected {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 36px;
            height: 36px;
          }
        }
        p:first-child {
          margin-right: 10px;
        }
        .isActiVe {
          border: 1px solid #D54343;
        }
      }
    }
    .bottom-button {
      height: 80px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      p:first-child {
        font-size: 14px;
        color: #D54343;
        margin-right: 40px;
      }
      p:last-child {
        width: 240px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #D54343;
        color: #fff;
        font-size: 12px;
        border-radius: 6px;
      }
      .toPay{
        cursor: pointer;
      }
      .toPay:hover{
        background-color: #B72C2C;
      }
    }
  }
  /deep/ .el-header {
    display: flex;
    align-items: center;
    p:first-child {
      cursor: pointer;
      font-weight: 600;
    }
  }
  /deep/ .el-main {
    background: #fff;
  }
  /deep/ .el-footer{
    height: auto!important;
    margin-bottom: 100px;
  }
  .button:hover{
    background-color: #B72C2C;
  }
  .fileTxt{
    cursor: pointer;
  }
</style>
