import Login from '../components/logins/login.vue'
import WechatLogin from '../components/logins/wechatLogin.vue'
import successJump from '../components/successJump.vue'
import diaLog from '../components/diaLog.vue'
import rechargeLog from '../components/recharge/log.vue'
import recharge from '../components/recharge/recharge.vue'
import offerPlace from '../components/place/offerPlace.vue'
import offerPlaceNew from '../components/place/offerPlaceNew.vue'
import Btn from '../components/place/Btn.vue'
import placeUtil from '../components/place/placeUtil.vue'
import placeOrder from '../components/place/placeOrder.vue'
import orderPayment from '../components/order/orderPayment.vue'
import orderList from '../components/order/orderList.vue'
import orderDetail from '../components/order/orderDetail.vue'
import hbSteps from '../components/hbSteps.vue'
import Bing from '../components/logins/bing.vue'
import assForm from '../components/order/assForm.vue'
import assList from '../components/order/assList.vue'
import assDetail from '../components/order/assDetail.vue'
import myInvoiceList from '../components/invoice/myInvoiceList.vue'
import invoiceList from '../components/invoice/invoiceList.vue'
import invoiceForm from '../components/invoice/invoiceForm.vue'
import invoiceDetail from '../components/invoice/invoiceDetail.vue'
import invoicePay from '../components/invoice/invoicePay.vue'
import LoginWhole from '../components/logins/loginWhole.vue'
import goodsDetail from '../components/batchOrder/goodsDetail.vue'
import leftMenu from '../components/batchOrder/menuList.vue'
export default {
  install(Vue) {
  // 注册全局组件
    Vue.component('Login', Login)
    Vue.component('WechatLogin', WechatLogin)
    Vue.component('success-jump', successJump)
    Vue.component('hbw-diaLog', diaLog)
    Vue.component('Recharge-Log', rechargeLog)
    Vue.component('Recharge', recharge)
    Vue.component('offer-place', offerPlace)
    Vue.component('place-util', placeUtil)
    Vue.component('place-order', placeOrder)
    Vue.component('order-payment', orderPayment)
    Vue.component('order-List', orderList)
    Vue.component('order-Detail', orderDetail)
    Vue.component('hb-steps', hbSteps)
    Vue.component('Bing', Bing)
    Vue.component('ass-form', assForm)
    Vue.component('ass-list', assList)
    Vue.component('ass-detail', assDetail)
    Vue.component('myInvoice-list', myInvoiceList)
    Vue.component('invoice-list', invoiceList)
    Vue.component('invoice-form', invoiceForm)
    Vue.component('invoice-detail', invoiceDetail)
    Vue.component('invoice-pay', invoicePay)
    Vue.component('LoginWhole', LoginWhole)
    Vue.component('Btn', Btn)
    Vue.component('offer-place-new', offerPlaceNew)
    Vue.component('Goods-Detail', goodsDetail)
    Vue.component('leftMenu', leftMenu)
    
  }
}
