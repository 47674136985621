import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    goodsData:[],

    user: false,

    craftData:[],
    materialList:[],
    // 多选选项
    craftDataList:[],
    // 单选选项
    radioCodeList: [],
    navIndex: null,
    radioCodeListId1:[],// 材料显示的状态
    navconRightIndex:null,
    sizeListes : [],
    prodcutCode:'',
    priceParams:[]

  },
  getters: {
  },
  mutations: {
    // 登录
    login(state, user) {
      state.user = user;
      localStorage.setItem("userInfo", user);
    },
    // 退出
    logout(state, user) {
      state.user = "";
      localStorage.setItem("userInfo", null);
      localStorage.setItem("userInfo", "");
    },
    //保存全部数据
    saveGoodsData(state,goodsData){
      state.goodsData = goodsData;
    },
    //修改 id == 1时，type ==1的状态
    updateMaterialList(state,materialList){
      state.materialList = materialList;
    },
    //修改 id == 3时，type ==1的状态
    updateCraftData(state,craftData){
      state.craftData = craftData;
    },
    // 清空id = 1时，type ==1时的数据
    deleteMaterialList(state,materialList){
      state.materialList = materialList;
    },
    // 清空id = 3时，type ==1时的数据
    deleteCraftDatat(state,craftData){
      state.craftData = craftData;
    },
    addSizeList(state,sizeList){
      state.sizeListes = sizeList;
    },
    saveProdcutCode(state,code){
      state.prodcutCode = code;
    },
    savePriceParams(state,code){
      state.priceParams = code;
    },


  },
  actions: {
    logout(state, user) {
      state.user = "";
      localStorage.setItem("userInfo", null);
    }
  },
  modules: {
  }
})
