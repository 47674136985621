<template>
  <div class="orderDetail" v-if="showForm">
    <div class="orderDetail-header">
      <p @click="goBack">售后订单</p>
      <p> > 售后详情</p>
    </div>
    <div class="orderDetail-list">
      <!-- <div class="step">
        <hb-steps
          :active="active"
          :orderTime="orderTime"
          :assLiistShow="assLiistShow">
        </hb-steps>
      </div> -->
      <div class="list-title">
        <img src="../../../static/icon/warning.png" alt="">
        <span>当前订单状态：{{ orderData.tradeOrderStateName }}</span>
      </div>
      <div class="list-detail">
        <div class="list-detail-li">
          <span>售后状态：</span>
          <span style="color: #D54343;">{{ orderData.tradeOrderStateName }}</span>
        </div>
        <div class="list-detail-li" v-if="orderData.afterSalesFeedback">
          <span>售后反馈：</span>
          <span style="color: #D54343;">{{ orderData.afterSalesFeedback }}</span>
        </div>
        <div class="list-detail-li">
          <span>订单编号：</span>
          <span>{{ orderData.orderCode }}</span>
        </div>
        <div class="list-detail-li">
          <span>售后产品：</span>
          <span>已选{{ orderData.items.length }}个产品</span>
        </div>
        <div class="tab-nav">
          <span style="width: 600px;">产品明细</span>
          <span style="width: 140px;">产品价格</span>
          <span style="width: 200px;">重做/退款结果</span>
        </div>
        <div class="list-detail-tab">
          <div class="tab-tr" v-for="item in orderData.items" :key="item.id">
            <p>
              <span style="width: 600px;">{{ item.productDetail }}</span>
              <span style="width: 140px;">￥{{ item.productPrice }}</span>
              <span style="width: 200px;color: #D54343;" v-if="item.productReformCount">
                重做数量：{{ item.productReformCount }}
              </span>
              <span style="width: 200px;color: #D54343;" v-if="item.productRefundMoney">
                退款金额：{{ item.productRefundMoney }}
              </span>
            </p>
          </div>
        </div>
        <div class="list-detail-li">
          <span>售后类型：</span>
          <span>{{ orderData.afterSalesTypeName }}</span>
        </div>
        <div class="list-detail-li">
          <span>问题描述：</span>
          <span class="list-detail-li-text">{{ orderData.problemDesc }}</span>
        </div>
        <div class="list-detail-li">
          <span>上传凭证：</span>
          <p v-for="items in orderData.certificateFileUrl" :key="items.id">
            <img :src="items" alt="" @click="imageUrl(items)">
          </p>
        </div>
        <div class="list-detail-li">
          <span>联系电话：</span>
          <span>{{ orderData.consumerPhone }}</span>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import {
  getById
} from "../../api/connectorList";
export default {
  props: ["id"],
  data() {
    return {
      active: 0,
      orderTime: '',
      showForm: false,
      assLiistShow: true,
      orderData: [],
      dialogVisible: false,
      dialogImageUrl: ''
    };
  },
  mounted() {
    this.getById()
  },
  methods: {
    getById() {
      getById({ id: this.id }).then((res) => {
        if (res.data.code == 200) {
          this.orderData = res.data.data
          this.showForm = true
          this.orderTime = res.data.data.orderTime
          let state = res.data.data.tradeOrderState
          if (state == 35) {
            this.active = 0
          } else if (state == 20) {
            this.active = 2
          } else if (state == 21 || state == 22) {
            this.active = 3
          }
        }
      })
    },
    goBack() {
      this.$router.go(0)
    },
    imageUrl(filePath) {
      this.dialogImageUrl = filePath
      this.dialogVisible = true;
    },
  },
}
</script>
<style lang="less" scoped>
.orderDetail-header {
  display: flex;
  align-items: center;
  // padding: 14px 0;
  height: 30px;

  p:first-child {
    cursor: pointer;
    font-weight: 600;
  }
}

.orderDetail-list {
  padding: 30px 24px;
  background: #fff;
  margin: 14px 0 10px 0;

  .step {
    padding: 30px 30px;
  }

  .list-title {
    height: 76px;
    display: flex;
    align-items: center;
    background: #F5F5F5;
    padding: 0 22px;
    color: #D54343;
    font-size: 18px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    p {
      display: flex;
      align-items: center;
    }
  }

  .list-detail {
    padding: 12px 110px;
  }

  .list-detail-li {
    font-size: 14px;
    padding: 12px 0;
    display: flex;
    align-items: flex-start;

    span:first-child {
      color: #999;
    }

    span:last-child {
      color: #333;
    }

    .list-detail-li-text {
      width: 380px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    p {
      width: 146px;
      height: 146px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .tab-nav {
    width: 940px;
    height: 50px;
    margin-top: 10px;
    padding: 0 20px;
    overflow: hidden;
    background: #f5f5f5;
    display: flex;
    align-items: center;

    span {
      color: #333;
      font-weight: 600;
    }
  }

  .tab-tr {
    width: 938px;

    p {
      display: flex;
      align-items: center;

      span {
        padding: 15px 0;
      }
    }
  }
  .list-detail-tab{
    width: 938px;
    border: 1px solid #EBEBEB;
    padding: 0 20px;
  }
}
</style>