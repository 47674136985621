import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/default.css';
import Components from '@/utils/component'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '@/assets/font/font.css'
import { throttle } from '@/utils/util.js'

import uploader from 'vue-simple-uploader'
Vue.use(uploader)

var eventBus = new Vue()
Vue.prototype.eventBus = eventBus
Vue.prototype.throttle = throttle

Vue.use(VueQuillEditor)
Vue.use(Components)
Vue.use(ElementUI);


Vue.prototype.$axios = axios;
// 过滤器
import * as custom from './utils/util'
Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})

// 路由拦截器
router.beforeEach((to, from, next) => {
  if (to.matched.length != 0) {
    if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
      if (Boolean(localStorage.getItem("userInfo"))) { // 通过vuex state获取当前的user是否存在
        next();
      } else {
        next({
          path: '/loginIndex/login',
          query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
      }
    } else {
      if (Boolean(localStorage.getItem("userInfo"))) { // 判断是否登录
        if (to.path != "/" && to.path != "/loginIndex/login") { //判断是否要跳到登录界面
          next();
        } else {
          /**
           * 防刷新，如果登录，修改路由跳转到登录页面，修改路由为登录后的首页 
           */
          next({
            path: '/goods',
          })
        }
      } else {
        localStorage.setItem("temporaryUser","true");
        next();
      }
    }
  } else {
    next({
      path: '/loginIndex/login',
      query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
    })
  }
})


Vue.config.productionTip = false


new Vue({
  router,
  store,
  data: {
    Bus: new Vue()
  },
  render: h => h(App)

}).$mount('#app')
