/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */
const timestampToTime = (timestamp) => {
    let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-'
    let M =
        (date.getMonth() + 1 < 10 ?
            '0' + (date.getMonth() + 1) :
            date.getMonth() + 1) + '-'
    let D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    let h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    let m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':'
    let s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return Y + M + D + h + m + s
};
/**
 * 存储localStorage
 */
const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
const getStore = name => {
    if (!name) return;
    return window.localStorage.getItem(name);
}

/**
 * 删除localStorage
 */
const removeStore = name => {
    if (!name) return;
    window.localStorage.removeItem(name);
}

/**
 * 设置cookie
 **/
function setCookie(name, value, day) {
    let date = new Date();
    date.setDate(date.getDate() + day);
    document.cookie = name + '=' + value + ';expires=' + date;
};

/**
 * 获取cookie
 **/
function getCookie(name) {
    let reg = RegExp(name + '=([^;]+)');
    let arr = document.cookie.match(reg);
    if (arr) {
        return arr[1];
    } else {
        return '';
    }
};

/**
 * 删除cookie
 **/
function delCookie(name) {
    setCookie(name, null, -1);
};
/**
 * 码值转换
 * @param dictCode
 * @param dictValueList
 * @returns {[{trigger: string, message: string, required: boolean}]|*}
 */
const changeDictName = function (dictCode, dictValueList) {
    let dictName = dictCode;
    if (dictValueList != null && dictValueList != "") {
        for (let i = 0; i < dictValueList.length; i++) {
            let map = dictValueList[i];
            if (dictCode == map.value) {
                dictName = map.label;
            }
        }
    }
    return dictName;
}

/**
 * 批量码值转换 以逗号分割
 * @param dictCode
 * @param dictValueList
 * @returns {[{trigger: string, message: string, required: boolean}]|*}
 */
const changeDictNames = function (dictCode, dictValueList) {
    let dictName = dictCode;
    let resData = [];
    if (null == dictName || "" == dictName) {
        return null;
    }
    let codeData = dictName.split(',');
    if (null == codeData || codeData.length < 1) {
        return null;
    }
    if (dictValueList != null && dictValueList != "") {
        let status = false;
        for (let i = 0; i < dictValueList.length; i++) {
            if (status) {
                break;
            }
            let map = dictValueList[i];
            for (let k = 0; k < codeData.length; k++) {
                if ("0" == codeData[k]) {
                    resData = [];
                    resData.push("全部产品类别");
                    status = true;
                    break;
                }
                if (codeData[k] == map.value) {
                    resData.push(map.label)
                }
            }
        }
    }
    if (null == resData || resData.length < 1) {
        return null;
    }
    return resData.toString();
}

/**
 * 自定义 批量码值转换
 * @param dictCode
 * @param dictValueList
 * @param name
 * @param code
 * @returns {*}
 */
const divChangeDictName = function (dictCode, dictValueList, code, name) {
    let dictName = dictCode;
    let resData = [];
    if (null == dictName || "" == dictName) {
        return null;
    }
    let codeData = dictName.split(',');
    if (null == codeData || codeData.length < 1) {
        return null;
    }
    if (dictValueList != null && dictValueList != "") {
        let status = false;
        for (let i = 0; i < dictValueList.length; i++) {
            if (status) {
                break;
            }
            let map = dictValueList[i];
            for (let k = 0; k < codeData.length; k++) {
                if ("0" == codeData[k]) {
                    resData = [];
                    resData.push("中国");
                    status = true;
                    break;
                }
                if (codeData[k] == map[code]) {
                    resData.push(map[name])
                }
            }
        }
    }
    if (null == resData || resData.length < 1) {
        return null;
    }
    return resData.toString();
}
// 节流函数
let timer ;
let flag ;
function throttle(fun,wait){
    if(!flag){
        flag = true;
        timer = setTimeout(()=>{
            flag = false;
            fun();
        },wait)
    } 
}
/**
 * 导出 
 **/
export {
    changeDictName,
    changeDictNames,
    divChangeDictName,
    timestampToTime,
    setStore,
    getStore,
    removeStore,
    setCookie,
    getCookie,
    delCookie,
    throttle
}