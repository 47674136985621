<template>
  <div class="invoiceForm">
    <div class="invoiceForm-nav">
      <p @click="goBack">我的发票</p>
      <p>  >  开票详情</p>
    </div>
    <div class="invoiceForm-step">
      <hb-steps
        :active="active"
        :invoiceFormShow="invoiceFormShow">
      </hb-steps>
    </div>
    <div class="invoiceForm-tab">
      <div class="tab-title">
        <span></span>
        <span>订单信息</span>
        <span>待开票金额：￥{{ pageList.invoiceAmount }}，共选择{{ length }}条记录 </span>
      </div>
      <el-table
        :data="pageList.apiTradeOrderPageItemRespVOS"
        border
        style="width: 1100px;"
        :header-cell-style="{background:'#f5f5f5',color:'#333','font-weight':'600'}">
        <el-table-column
          prop="orderCode"
          label="订单编号"
          width="130">
        </el-table-column>
        <el-table-column
          prop="productCategoryName"
          label="产品类别"
          width="160">
        </el-table-column>
        <el-table-column
          label="产品明细">
          <template slot-scope="scope">
            <div v-for="(val,index) in scope.row.items" :key="index">
              <div>{{val.productDetail}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderCountMoney"
          label="订单金额"
          width="140">
          <template slot-scope="scope">￥{{ scope.row.orderCountMoney }}</template>
        </el-table-column>
      </el-table>
      <div style="height: 40px;"></div>
    </div>
    <div class="invoiceForm-form">
      <div class="tab-title">
        <span></span>
        <span>开票信息</span>
      </div>
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="发票金额：">
          <span style="color: #D54343;">￥{{ pageList.invoiceAmount }}</span>
        </el-form-item>
        <el-form-item label="税费：">
          <span style="color: #D54343;">￥{{ pageList.billingTax }}</span>
        </el-form-item>
        <el-form-item label="发票类型：">
          <span style="color: #333;font-size: 14px;">增值税电子普通发票</span>
        </el-form-item>
        <el-form-item label="抬头类型：">
          <span style="color: #333;font-size: 14px;" v-if="pageList.invoiceOwnership == '0'">企业</span>
          <span style="color: #333;font-size: 14px;" v-if="pageList.invoiceOwnership == '1'">个人</span>
        </el-form-item>
        <el-form-item label="发票抬头：">
          <span style="color: #333;font-size: 14px;">{{ pageList.invoiceTitle }}</span>
        </el-form-item>
        <el-form-item label="纳税人识别号：" v-if="pageList.taxpayerNum">
          <span style="color: #333;font-size: 14px;">{{ pageList.taxpayerNum }}</span>
        </el-form-item>
        <el-form-item label="地址" v-if="pageList.companyAddress">
          <span style="color: #333;font-size: 14px;">{{ pageList.companyAddress }}</span>
        </el-form-item>
        <el-form-item label="电话" v-if="pageList.companyPhone">
          <span style="color: #333;font-size: 14px;">{{ pageList.companyPhone }}</span>
        </el-form-item>
        <el-form-item label="开户行" v-if="pageList.openingBank">
          <span style="color: #333;font-size: 14px;">{{ pageList.openingBank }}</span>
        </el-form-item>
        <el-form-item label="开户行账号" v-if="pageList.bankAccount">
          <span style="color: #333;font-size: 14px;">{{ pageList.bankAccount }}</span>
        </el-form-item>
        <el-form-item label="身份证号：" v-if="pageList.memberIdCard">
          <span style="color: #333;font-size: 14px;">{{ pageList.memberIdCard }}</span>
        </el-form-item>
        <el-form-item label="收票邮箱：">
          <span style="color: #333;font-size: 14px;">{{ pageList.receiptMail }}</span>
        </el-form-item>
      </el-form>
      <div style="height: 120px;"></div>
    </div>
  </div>
</template>
<script>
  import {
    invoiceDeteil
  } from "../../api/connectorList";
  export default {
    props: {
      id: Number
    },
    data() {
      return {
        active: 0,
        invoiceFormShow: true,
        ruleForm: {},
        pageList: [],
        length: 0
      };
    },
    mounted() {
      this.getList()
    },
    methods: {
      goBack() {
        this.$router.go(0)
      },
      getList() {
        invoiceDeteil({id: this.id}).then((res) => {
          if(res.data.code == 200) {
            this.pageList = res.data.data
            this.length = res.data.data.apiTradeOrderPageItemRespVOS.length
            let active = res.data.data.billState
            if(active == 0) {
              this.active = 3
            } else if(active == 1) {
              this.active = 4
            }
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .invoiceForm {
    padding: 0px 20px 20px 20px;
    .invoiceForm-nav {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      height: 36px;
      p:first-child {
        cursor: pointer;
        font-weight: 600;
      }
    }
    .invoiceForm-step {
      background: #fff;
      padding: 30px 0;
    }
    .invoiceForm-tab {
      margin-top: 12px;
      background: #fff;
      padding: 0 20px;
    }
    .tab-title {
      height: 58px;
      display: flex;
      align-items: center;
      color: #333;
      font-size: 14px;
      span:first-child {
        width: 4px;
        height: 20px;
        background: #D54343;
      }
      span:nth-child(2) {
        font-weight: 600;
        margin: 0 30px 0 10px;
      }
    }
    .invoiceForm-form {
      margin-top: 12px;
      background: #fff;
      padding: 0 20px;
    }
  }
  /deep/ .el-form-item__label {
    width: 140px!important
  }
  /deep/ .el-form-item__content {
    margin-left: 140px!important
  }
  /deep/ .el-form-item__label {
    color: #999;
  }
</style>
