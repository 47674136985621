<template>
  <div>
    <div class="typeBox">
      <div
        class="typeItemBox"
        v-for="(item, index) in goodsData"
        :key="index"
      >
        <div
          class="typeItem flex-c"
          :style="item.id == 3 ? { 'align-items': 'flex-start' } : ''"
        >
          <div class="label" v-if="item.id == 3">{{ item.name }}</div>
          <div class="label" v-else>{{ item.oneName[0].name }}</div>

          <place-util
            v-if="item.id == 1"
            :type="item.oneName[0].beforeShowType.type"
            :materials="item.oneName[0]"
            :materialList = "item.oneName[0].children"
            :key="dateId"
            :parent="1"
            :id="item.id"
            :radioIndexId1="index"
            @priceParamsFrom="getPriceParam"
          ></place-util>
          <place-util
            v-if="item.id == 2"
            :type="item.oneName[0].beforeShowType.type"
            :sizeData="item.oneName[0]"
            :sizeParams="item.oneName[0]"
            :materials="item.oneName[0]"
            :parent="1"
            :key="dateId"
            @priceParamsFrom="getPriceParam"
          ></place-util>
          <div class="craftBox" v-if="item.id == 3">
            <div
              class="craftItemBox flex-c"
              v-for="(v, vIndex) in item.oneName"
              :key="vIndex"
            >
              <div class="flex-c" v-if="$store.state.craftData">
                <div class="craftTitle">{{ v.name }}</div>
                <place-util
                  :type="v.beforeShowType.type"
                  :craftData="$store.state.craftData[vIndex].children"
                  :sizeData ="item.oneName[vIndex]"
                  :radioIndex="vIndex"
                  :key="dateId"
                  :parent="0"
                  :id="item.id"
                  :mustStatus="item.mustStatus"
                  :level="item.level"
                  @priceParamsFrom="getPriceParam"
                ></place-util>
              </div>
            </div>
          </div>
          <div class="craftBox" v-if="item.id == 4">
            <div class="flex-c">
              <div v-if="item.oneName[0].beforeShowType.type ==4">
                <div style="margin-right: 15px">
                  <el-input
                    v-model="numInput" 
                    placeholder="请输入数量"
                    @input="numChange"
                  ></el-input>

               
                </div>
              </div>
              <div v-else>
                <place-util
                  ref="numChild"
                  :type="item.oneName[0].beforeShowType.type"
                  :materials="item.oneName[0]"
                  :numChangeIndex="numChangeIndex"
                  :sizeData ="item.oneName[0]"
                  :key="dateId"
                  :parent="0"
                  :id="item.id"
                  :numInput="numInput"
                  @priceParamsFrom="getPriceParam"
                ></place-util>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calculate">
        <div class="calculateBtn cursor" @click="calculate">计算价格</div>
      </div>

      <!-- 计算价格 -->
      <div>
        <div class="typeItemBox totalPriceBox" v-if="countPriceShow">
          <div class="typeItem flex-c">
            <div class="label">官网价格</div>
            <p style="color: #d54343">
              <span style="font-size: 12px">￥</span
              ><span style="font-size: 32px;font-family: FangZhengZhengZhunHei;">{{
                countPriceData.totalPrice
              }}</span>
            </p>
          </div>
          <div class="typeItem flex-c">
            <div class="label">人工接单</div>
            <p style="color: #9a9a9a; text-decoration: line-through">
              <span style="font-size: 14px"
                >￥{{ countPriceData.manOrders }}</span
              >
            </p>
          </div>
          <div class="typeItem flex-start">
            <div class="label">报价明细</div>
            <div class="priceDetail">
              <p style="font-weight: 600">{{ countPriceData.fileName }} - {{ countPriceData.aboutWeight }}</p>
              <p style="font-size: 12px; color: #d54343">
                {{ countPriceData.aboutTime }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <LoginWhole :visible.sync="dialogVisible" ref="viewLogin"></LoginWhole>
    </div>
  </div>
</template>



<script>
import { del } from 'vue';
import { countPrice } from "../../api/connectorList";
import { couponList } from '@/api/coupon';
export default {
  props: ["goodsData", "productData","numInput"],
  data() {
    return {
      userInfo: null,
      dateId: Date.now(),
      numInput: "",
      numChangeIndex: null,
      priceParams: {
        // 材料
        materialId: null,
        // 尺寸
        sizeId: null,
        // 自定义尺寸属性
        sizeType: [],
        // 工艺
        craftIds: [], 
        // 工艺多选
        craftIdsCheck: [],
        // 工艺单选
        craftIdsRadio: [],
        // 数量
        numberId: null,
        // 数量 自定义
        number: null,
      },
      childrenList:[],
      countPriceData: null,
      countPriceShow: false,
      dialogVisible: false,
      mustList:[],
    };
  },
  created() {},
  mounted() {
    this.$store.state.radioCodeList = [];
    this.userInfo = JSON.parse(localStorage.getItem("user"));
    console.log(this.goodsData)
    if (this.goodsData) {
      for (let i = 0; this.goodsData.length > i; i++) {
        // 当 id == 3 时候 type == 1 的情况
        if (this.goodsData[i].id == 3) {
          this.goodsData[i].oneName.map((val) => {
            val.children.map((data) => {
              data.checked = false;
              data.disabled = false;
            });
          });
          let craftData = [...this.goodsData[i].oneName];
          // 保存数据
          this.$store.commit('updateCraftData',craftData)
          for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
            this.$store.state.radioCodeList.push({ radioCode: null });
          }
        }else if(this.goodsData[i].id == 1){
          this.goodsData[i].oneName.map((val) => {
            val.children.map((data) => {
              data.checked = false;
              data.disabled = false;
            });
          });
          this.$store.state.craftDataId1 = [...this.goodsData[i].oneName];
          let materialList = [...this.goodsData[i].oneName];
          // 保存数据
          this.$store.commit('updateMaterialList',materialList)
          for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
            this.$store.state.radioCodeListId1.push({ radioCode: null });
          }
        }else if(this.goodsData[i].id == 2){
          if(this.goodsData[i].oneName[0].beforeShowType.type != 4){
            this.goodsData[i].oneName.map((val) => {
              val.children.map((data) => {
                data.checked = false;
                data.disabled = false;
              });
            });
             //保存到 vuex 
            let sizeList = [...this.goodsData[i].oneName[0].children]
            this.$store.commit('addSizeList',sizeList)
            this.sizeListes = [...this.goodsData[i].oneName[0].children]
          }
        }
      }
    }
  },
  watch: {
    goodsData: function (val) {
      this.countPriceShow = false;
      this.countPriceData = null;
      this.$emit("setCountPriceShow", false);
      this.$store.state.radioCodeList = [];
      for (let i = 0; val.length > i; i++) {
        // 当 id == 3 时候 type == 1 的情况
        if (this.goodsData[i].id == 3) {
          this.goodsData[i].oneName.map((val) => {
            val.children.map((data) => {
              data.checked = false;
              data.disabled = false;
            });
          });
          let craftData = [...this.goodsData[i].oneName];
          // 保存数据
          this.$store.commit('updateCraftData',craftData)
          for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
            this.$store.state.radioCodeList.push({ radioCode: null });
          }
        }else if(this.goodsData[i].id == 1){
          this.goodsData[i].oneName.map((val) => {
            val.children.map((data) => {
              data.checked = false;
              data.disabled = false;
            });
          });
          this.$store.state.craftDataId1 = [...this.goodsData[i].oneName];
          let materialList = [...this.goodsData[i].oneName];
          // 保存数据
          this.$store.commit('updateMaterialList',materialList)
          for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
            this.$store.state.radioCodeListId1.push({ radioCode: null });
          }
        }else if(this.goodsData[i].id == 2){
          if(this.goodsData[i].oneName[0].beforeShowType.type != 4){
            this.goodsData[i].oneName.map((val) => {
              val.children.map((data) => {
                data.checked = false;
                data.disabled = false;
              });
            });
             //保存到 vuex 
            let sizeList = [...this.goodsData[i].oneName[0].children]
            this.$store.commit('addSizeList',sizeList)
            this.sizeListes = [...this.goodsData[i].oneName[0].children]
          }
         
        }
      }
      this.dateId = Date.now();
    },
  },
  methods: {
    getPriceParam(val, type) {
      if (type == 1) {
        this.numInput = ''
        this.priceParams.materialId = val;
        this.$store.state.radioCodeList = []
        this.$store.state.craftDataList = []
        for (let i = 0; this.goodsData.length > i; i++) {
          if (this.goodsData[i].id == 3) {
            for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
              this.$store.state.radioCodeList.push({ radioCode: null });
            }
          }else if(this.goodsData[i].id == 1){
            for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
              this.$store.state.radioCodeListId1.push({ radioCode: null });
            }
          }
        }
        
        this.countPriceShow = false
        this.$emit("setCountPriceShow", this.countPriceShow, null);
      } else if (type == 2) {
        this.priceParams.sizeId = val.id
        let arr2 = [];
        this.$store.state.radioCodeList = []
        for (let i = 0; this.goodsData.length > i; i++) {
          if (this.goodsData[i].id == 3) {
            this.goodsData[i].oneName.map((val) => {
              val.children.map((data) => {
                data.checked = false;
                data.disabled = false;
              });
            });
            let craftData = [...this.goodsData[i].oneName];
            this.$store.commit('updateCraftData',craftData)
            for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
              this.$store.state.radioCodeList.push({ radioCode: null });
            }
          }else if(this.goodsData[i].id == 1){
            for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
              this.$store.state.radioCodeListId1.push({ radioCode: null });
            }
          }
        }
        
        if (val !== null ) {
          val.forEach((item) => {
            arr2.push(item.size);
          });
          this.priceParams.sizeType = arr2;
          this.priceParams.sizeId = null;
        } else {
          this.priceParams.sizeId = val;
          this.priceParams.sizeType = [];
        }

        this.countPriceShow = false
        this.$emit("setCountPriceShow", this.countPriceShow, null);

      } else if (type == 3) {
        this.priceParams.craftIdsCheck = this.priceParams.craftIdsCheck.concat(
          val
        );
      } else if (type == 4) {
        
        this.numInput = val.name - 0;   
      }else if (type == 5) {
        this.numInput = val;   
      }else if(type == 6){
        this.priceParams.materialId = val[0].id
        this.$store.state.radioCodeList = []
        this.$store.state.craftDataList = []
        for (let i = 0; this.goodsData.length > i; i++) {
          if (this.goodsData[i].id == 3) {
            this.goodsData[i].oneName.map((val) => {
              val.children.map((data) => {
                data.checked = false;
                data.disabled = false;
              });
            });
            let craftData = [...this.goodsData[i].oneName];
            this.$store.commit('updateCraftData',craftData)
            for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
              this.$store.state.radioCodeList.push({ radioCode: null });
            }
          }else if(this.goodsData[i].id == 1){
            for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
              this.$store.state.radioCodeListId1.push({ radioCode: null });
            }
          }else if(this.goodsData[i].id == 2){
          this.goodsData[i].oneName.map((val) => {
            val.children.map((data) => {
              data.checked = false;  
              data.disabled = false;
            });
          });
          //保存到 vuex 
          let sizeList = [...this.goodsData[i].oneName[0].children]
          this.$store.commit('addSizeList',sizeList)
          this.sizeListes = [...this.goodsData[i].oneName[0].children]
        }
        }
      }else if(type == 7){
        this.priceParams.materialId = val.id
        this.$store.state.radioCodeList = []
        for (let i = 0; this.goodsData.length > i; i++) {
          if (this.goodsData[i].id == 3) {
            this.goodsData[i].oneName.map((val) => {
              val.children.map((data) => {
                data.checked = false;
                data.disabled = false;
              });
            });
            let craftData = [...this.goodsData[i].oneName];
            this.$store.commit('updateCraftData',craftData)
            for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
              this.$store.state.radioCodeList.push({ radioCode: null });
            }
          }else if(this.goodsData[i].id == 1){
            for (let j = 0; this.goodsData[i].oneName.length > j; j++) {
              this.$store.state.radioCodeListId1.push({ radioCode: null });
            }
          }
        }
        this.countPriceShow = false
        this.$emit("setCountPriceShow", this.countPriceShow, null);
      }else if(type == 8){
        this.countPriceShow = false
        this.$emit("setCountPriceShow", this.countPriceShow, null);
      }
    },
    checkIn(arr,val){ 
      for(var i=0;i<arr.length;i++){
        if(arr[i].parentId == val.parentId){
            return i ;
        }
      }
      return -1; 
    },
    getNumIndex(val) {
      this.numInput = val
    },
    numChange(e) {
      this.numInput = e;
      if (e.length > 0) {
        this.goodsData[3].oneName[0].children.map((val, index) => {
          if (val.name == e) {
            this.numChangeIndex = index;
            
          }
        });
      }
      this.countPriceShow = false
        this.$emit("setCountPriceShow", this.countPriceShow, null);
    },
    calculate() {
      
      if(this.userInfo==undefined||this.userInfo==null||this.userInfo.id==null){
        if(this.dialogVisible){
          this.dialogVisible = false;
        }
        this.dialogVisible = true;
        return false;
      }

      // if(this.priceParams.materialId == null || this.priceParams.materialId == ""){
      //   this.$alert('材料属性不能为空', '温馨提示：', {
      //     confirmButtonText: '确定',
      //     callback: action => {
      //       this.$message({
      //         type: 'info',
      //         message: `action: ${ action }`
      //       });
      //     }
      //   });
      // }

      // console.log('尺寸：',this.priceParams.sizeType)
      // if(this.priceParams.sizeType.length <= 0 || (this.priceParams.sizeId == null || this.priceParams.sizeId == "")  ){
      //   this.$alert('尺寸属性不能为空', '温馨提示：', {
      //     confirmButtonText: '确定',
      //     callback: action => {
      //       this.$message({
      //         type: 'info',
      //         message: `action: ${ action }`
      //       });
      //     }
      //   });
      // }

      // console.log('数量：', this.numInput)
      // if(this.numInput == null || this.numInput == ""){
      //   this.$alert('数量不能为空', '温馨提示：', {
      //     confirmButtonText: '确定',
      //     callback: action => {
      //       this.$message({
      //         type: 'info',
      //         message: `action: ${ action }`
      //       });
      //     }
      //   });
      // }

      console.log('重复点击')
      this.handleList()
      
    },
    handleList(){
      let mustList = []
      let mustListLen  = []
      let mustListLenName = []
      let list = []
      for(let i = 0;i<this.goodsData.length;i++){
        if(this.goodsData[i].id == 3){
          for(let j = 0;j < this.goodsData[i].oneName.length;j++){
            console.log(this.goodsData[i].oneName)
            if(this.goodsData[i].oneName[j].mustStatus == "Y" && this.goodsData[i].oneName[j].level == 1 && this.goodsData[i].oneName[j].beforeShowType.type == "1"){
              mustListLen = mustListLen.concat(this.goodsData[i].oneName[j])
              mustListLenName = mustListLenName.concat(this.goodsData[i].oneName[j].name)
              console.log('哈哈哈',this.goodsData[i].oneName[j].name)
              for(let g= 0;g<this.goodsData[i].oneName[j].children.length;g++){
               console.log('')
                console.log('必填“',this.goodsData[i].oneName[j].children[g].name)
                if(this.goodsData[i].oneName[j].children[g].checked == true){
                  console.log('必填工艺选项：',this.goodsData[i].oneName[j].children[g].name)
                  list = list.concat(this.goodsData[i].oneName[j].children[g]) 
                  mustList = mustList.concat(this.goodsData[i].oneName[j].children[g]) 
                }
              }  
            }else{
              for(let g= 0;g<this.goodsData[i].oneName[j].children.length;g++){
                if(this.goodsData[i].oneName[j].children[g].checked == true){
                  list = list.concat(this.goodsData[i].oneName[j].children[g]) 
                }
              }
            }
          }
        }
      }

      let craftIdsRadio = []
        for(let k = 0;k <list.length;k++){
          craftIdsRadio.push({
            id:list[k].id,
            type: "",
            children: []
          })
        }
        this.showListLingo(mustListLen,mustList,mustListLenName,craftIdsRadio) 
    },

    showListLingo(mustListLen,mustList,mustListLenName,craftIdsRadio){
      let mustName = []
      if(mustListLen.length == mustList.length){
        
      }else{
        let len = mustList.length
        for(let i = 0;i<mustListLen.length;i++){
          for(let j = 0;j<mustList.length;j++){
            if(mustListLen[i].treeId == mustList[j].parentTreeId){
              mustName = mustName.concat(mustListLen[i].name) 
            }
          }
        }
        // console.log('选项：11111',craftIdsRadio)
        // mustListLenName = mustListLenName.filter(item => !mustName.includes(item));
        // this.$alert(mustListLenName + '  不能为空333', '温馨提示：', {
        //   confirmButtonText: '确定',
        //   callback: action => {
        //     this.$message({
        //       type: 'info',
        //       message: `action: ${ action }`
        //     });
        //   }
        // });
      }
      this.calculates(craftIdsRadio)

    },
    calculates(craftIdsRadio){
      let params = {
        memberId: this.userInfo.id,
        memberName: this.userInfo.name,
        prodcutName: this.productData.name,
        prodcutCode: this.productData.code,
        prodcutId: this.productData.id,
        floatRatio: this.productData.floatRatio,
        materialId: this.priceParams.materialId,
        sizeId: this.priceParams.sizeId,
        sizeType: this.priceParams.sizeType,
        craftIds: craftIdsRadio,
        numberId: this.priceParams.numberId,
        number: this.numInput,
      };
      countPrice(params).then((res) => {
        if(res.data.code == 200){
          this.countPriceData = res.data.data
          this.countPriceShow = true
          this.$emit("setCountPriceShow", this.countPriceShow, res.data.data);
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.typeBox {
  .typeItemBox {
    .typeItem {
      margin-bottom: 22px;
      .label {
        box-sizing: border-box;
        padding: 10px 0;
        width: 110px;
        background-color: rgba(255, 232, 232, 1);
        color: rgba(51, 51, 51, 1);
        text-align: center;
        border-radius: 4px;
        margin-right: 18px;
      }
      .craftBox {
        .craftItemBox {
          margin-bottom: 20px;
        }
        .craftTitle {
          border-radius: 4px;
          background-color: #f5f5f5;
          color: rgba(153, 153, 153, 1);
          text-align: center;
          padding: 10px 15px;
          font-size: 12px;
          margin-right: 20px;
        }
        .craftItemBox:last-child {
          margin-bottom: 0px;
        }
      }
      .priceDetail {
        padding: 10px 20px;
        background-color: #f8f8f8;
        width: 90%;
      }
    }
  }
  .totalPriceBox {
    margin-top: 30px;
  }
  .calculate {
    margin-top: 30px;
    margin-left: 128px;
    .calculateBtn {
      width: 140px;
      background-color: #d54343;
      color: #fff;
      text-align: center;
      padding: 10px 0;
      border-radius: 4px;
    }
    .calculateBtn:hover{
      background: #B72C2C;
    }
  }
}
</style>
