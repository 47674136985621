<template>
  <div class="utilBox">
    <!-- 按钮 -->
    <div v-if="type == 0" class="flex-c" style="flex-wrap: wrap">
      <!-- v-show="materials.defineId == 1" -->
      <div
        class="value flex-c cursor "
        v-for="(data, i) in materials.children"
        :key="data.id"
        :class="materialIsActiVe == i ? 'materialIsActiVe' : ''"
        @click="materialsClick(data, i)"
      >
        <el-tooltip  v-if="data.notes != null"
          class="item"
          effect="dark"
          placement="top"
        >
          <div slot="content" class="notes">
            {{ data.notes }}
          </div>
          <div class="valueMaterials flex-j-c">
            <div class="imgItem" v-show="'' != data.notes && null != data.notes ">
              <img
                v-if="materialIsActiVe == i"
                src="../../../static/icon/annotation.png"
                alt=""
              />
              <img
                v-else
                src="../../../static/icon/annotation-null.png"
                alt=""
              />
            </div>
            <div>{{ data.name }}</div>
            <div
              v-if="data.fileId != null"
              class="sample cursor"
              :class="materialIsActiVe == i ? 'noSample' : ''"
              @click="sampleOpen(data.fileId)"
            >
              示例图
            </div>
          </div>
        </el-tooltip>
        <div v-if="data.notes == null">
          <div slot="content">
            {{ data.notes }}
          </div>
          <div class="valueMaterials flex-j-c">
            <div class="imgItem" v-show="'' != data.notes && null != data.notes ">
              <img
                v-if="materialIsActiVe == i"
                src="../../../static/icon/annotation.png"
                alt=""
              />
              <img
                v-else
                src="../../../static/icon/annotation-null.png"
                alt=""
              />
            </div>
            <div>{{ data.name }}</div>
            <div
              v-if="data.fileId != null"
              class="sample cursor"
              :class="materialIsActiVe == i ? 'noSample' : ''"
              @click="sampleOpen(data.fileId)"
            >
              示例图
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <!-- 单选框 -->
    <!-- 区分：当 id == 1 时，type == 1;以及 id == 3 时，type == 1 -->
    <div v-if="id == 1">
      <div v-if="type == 1" class="flex-c" style="flex-wrap: wrap">
      <div class="sizeItemBox flex-c" style="margin-right: 40px">
        <el-radio-group
          v-model="$store.state.radioCodeListId1[radioIndexId1].radioCode"
          class="flex-c"
          @input="radioChangeId1"
        >
          <el-radio
            v-for="(data, j) in materialList"
            :key="j"
            :disabled="data.disabled"
            :label="data"
            ><div>{{ data.name }}</div>
          </el-radio
        ></el-radio-group>
      </div>
    </div>
    </div>
    <div v-else-if="id == 3">
      <div v-if="type == 1" class="flex-c" style="flex-wrap: wrap">
        <div class="sizeItemBox flex-c" style="margin-right: 40px">
          <el-radio-group
            v-model="$store.state.radioCodeList[radioIndex].radioCode"
            class="flex-c"
            @input="radioChange"
          >
            <el-radio
              v-for="(data, j) in $store.state.craftData[radioIndex].children"
              :key="j"
              :disabled="data.disabled"
              :mustStatus="mustStatus"
              :level="level"
              :label="data"
              ><div>{{ data.name }}</div>
                <div class="flex-c" style="font-size: 14px; margin-left: 15px">
                  <div
                    v-show="data.checked == true"
                    v-for="(item, o) in data.children"
                    :key="o"
                  >
                    <!-- 判断是否显示提示语 -->
                    <div v-if="item.notes == '' || item.notes == null">
                      <div
                        v-show="item.showType == 3"
                        class="flex-c cursor sizeItemBox"
                        style="margin-right: 15px"
                      >
                        <el-select
                          v-model="item.name"
                          size="small"
                          @change="radioCraftOptionBlur"
                          @visible-change="radioCraftOptionBlurItem($event,item.children,type,data,item)"
                        >
                          <el-option
                            v-for="(obj,index) in item.children"
                            :key="index"
                            :label="obj.name"
                            :value="index"
                            :disabled="obj.disabled"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div v-else>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top"
                        :content="item.notes"
                      >
                        <div
                          v-show="item.showType == 3"
                          class="flex-c cursor sizeItemBox"
                          style="margin-right: 15px"
                        >
                          <el-select
                            v-model="item.name"
                            :placeholder="item.name"
                            size="small"
                            @change="radioCraftOptionBlur"
                           @visible-change="radioCraftOptionBlurItem($event,item.children,type,data,item)"
                          >
                            <el-option
                              v-for="(obj,index) in item.children"
                              :key="index"
                              :label="obj.name"
                              :value="index"
                              :disabled="obj.disabled"
                            ></el-option>
                          </el-select>
                        </div>
                      </el-tooltip>
                    </div>
                    <div v-if="item.notes == '' || item.notes == null">
                      <div v-show="item.showType == 4" class="sizeItemBox flex-c">
                        <el-input
                          size="small"
                          v-model="item.sizeParams"
                          :placeholder="item.name"
                          @change="craftInputChange"
                        ></el-input>
                      </div>
                    </div>
                    <div v-else>
                      <el-tooltip
                      class="item"
                      effect="dark"
                      placement="top"
                      :content="item.notes"
                      :disabled="item.notes == ''"
                    >
                      <div v-show="item.showType == 4" class="sizeItemBox flex-c">
                        <el-input
                          size="small"
                          v-model="item.sizeParams"
                          :placeholder="item.name"
                          @change="craftInputChange"
                        ></el-input>
                      </div>
                    </el-tooltip>
                    </div>
                  </div>
                </div>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- 多选框 -->
    <div v-if="type == 2" class="flex-c" >
        <el-checkbox-group v-model="$store.state.craftDataList" class="flex-c" style="flex-wrap: wrap">
          <el-checkbox
            v-for="(data, i) in craftData"
            :label="data"
            :key="i"
            :disabled="data.disabled"
            @change="checkChange(data, i)"
          >
            <div>{{ data.name }}</div>
            <div class="flex-c" style="font-size: 14px; margin-left: 15px">
              <div
                v-show="data.checked == true"
                v-for="(item, o) in data.children"
                :key="o"
              >
                <!-- 判断是否显示提示语 -->
                <div  v-if="item.notes == '' || item.notes == null">
                  <div
                    v-show="item.showType == 3"
                    class="flex-c cursor sizeItemBox"
                    style="margin-right: 15px"
                  >
                    <el-select
                      v-model="item.name"
                      :placeholder="item.name"
                      size="small"
                      @change="radioCraftOptionBlur"
                      @visible-change="radioCraftOptionBlurItem($event,item.children,type,data,item)"
                    >
                      <el-option
                        v-for="(obj,index) in item.children"
                        :key="index"
                        :label="obj.name"
                        :value="index"
                        :disabled="obj.disabled"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div v-else>
                  <el-tooltip
                      class="item"
                      effect="dark"
                      placement="top"
                      :content="item.notes"
                      :disabled="item.notes == ''"
                    >
                      <div
                        v-show="item.showType == 3"
                        class="flex-c cursor sizeItemBox"
                        style="margin-right: 15px"
                      >
                      <el-select
                        v-model="item.name"
                        :placeholder="item.name"
                        size="small"
                        @change="radioCraftOptionBlur"
                        @visible-change="radioCraftOptionBlurItem($event,item.children,type,data,item)"
                      >
                        <el-option
                          v-for="(obj,index) in item.children"
                          :key="index"
                          :label="obj.name"
                          :value="index"
                          :disabled="obj.disabled"
                        ></el-option>
                    </el-select>
                  </div>
                </el-tooltip>
                </div>
                <div v-if="item.notes == '' || item.notes == null">
                  <div v-show="item.showType == 4" class="sizeItemBox flex-c">
                    <el-input
                      size="small"
                      v-model="item.sizeParams"
                      :placeholder="item.name"
                      @change="craftInputChange(item)"
                    ></el-input>
                  </div>
                </div>
                <div v-else>
                  <el-tooltip
                  class="item"
                  effect="dark"
                  placement="top"
                  :content="item.notes"
                  :disabled="item.notes == ''"
                >
                  <div v-show="item.showType == 4" class="sizeItemBox flex-c">
                    <el-input
                      size="small"
                      v-model="item.sizeParams"
                      :placeholder="item.name"
                      @change="craftInputChange(item)"
                    ></el-input>
                  </div>
                </el-tooltip>
                </div>
              </div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
    </div>
    <!-- 下拉框 -->
    <div v-if="id == 1">
      <div v-if="type == 3" style="flex-wrap: wrap">
        <el-select
          v-model="sizeOption"
          placeholder="请选择尺寸"
          @change="sizeOptionBlur"
        >
          <el-option
            v-for="(data, i) in materials.children"
            :label="data.name"
            :value="data.id"
            :key="i"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div v-else-if="id != 1">
      <div v-if="type == 3">
        <el-select
          v-model="sizeOption"
          placeholder="请选择尺寸"
          @change="sizeOptionBlur"
        >
          <el-option
            v-for="(data, i) in $store.state.sizeListes"
            :label="data.name"
            :value="data.id"
            :key="i"
            :disabled="data.disabled"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- 输入框 -->
    <div v-if="type == 4" class="flex-c" style="flex-wrap: wrap">
      <div
        class="sizeItemBox flex-c"
        v-for="(data, i) in sizeData.beforeShowType.label"
        :key="i"
      >
        <el-input
          v-model="sizeParams[i].size"
          :placeholder="data"
          style="width: 120px"
          @change="sizeInputChange"
        ></el-input>
        <div class="multiplication" style="margin: 0 10px">X</div>
      </div>
    </div>
   
    <!-- 输入+下拉 -->
    <div v-if="type == 41" class="flex-c" style="flex-wrap: wrap">
      <div
        class="flex-c"
        style="margin-right: 15px"
        v-if="sizeSelect == true"
      >
        <div
          class="sizeItemBox flex-c"
          v-for="(data, i) in sizeData.beforeShowType.label"
          :key="i"
        >
          <el-input
            v-model="sizeParams[i].size"
            :placeholder="data"
            style="width: 120px"
            :disabled="data.disabled"
            @input="sizeInputChange(sizeParams[i].size, i)"
          ></el-input>
          <div class="multiplication" style="margin: 0 10px">X</div>
        </div>
      </div>
      <div
        class="flex-c cursor"
        style="margin-right: 15px"
        v-if="sizeSelect == false"
      >
        <el-select
          v-model="sizeOption"
          placeholder="请选择尺寸"
          @change="sizeOptionBlur"
        >
          <el-option
            v-for="(data, i) in $store.state.sizeListes"
            :label="data.name"
            :value="data.id"
            :key="i"
            :disabled="data.disabled"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-checkbox v-model="sizeSelect" @change="sizeDefined"
          >自定义</el-checkbox
        >
      </div>
    </div>
    <!-- 输入+按钮 -->
    <div v-if="type == 42" class="flex-c" style="flex-wrap: wrap">
      <div class="flex-c" style="margin-right: 15px">
        <el-input
          v-model="numInput"
          placeholder="请输入数量"
          style="width: 120px"
          @change="getInputNumber"
        ></el-input>
      </div>
      <div
        class="value flex-c cursor values    123333"
        :class="sizeIsActiVe == i ? 'materialIsActiVe' : ''"
        v-for="(data, i) in sizeData.children"
        :key="i"
        style="margin-right: 15px"
        @click="sizeValueClick(data, i)"
      >
        <div class="valueMaterials sizeValue flex-j-c sizeValues">
          <div>{{ data.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { couponList } from '@/api/coupon';
import { getRelationsData,getAttrFile,getCraftRelationsByParam } from "../../api/connectorList";
export default {
  props: [
    "id",
    "type",
    "materials",
    "materialList",
    "sizeData",
    "craftData",
    "parent",
    "numChangeIndex",
    "radioIndex",
    "radioIndexId1",
    "level",
    "mustStatus",
    "numInput"
  ],
  data() {
    return {
      materialIsActiVe: 0,
      sizeParams: [],
      craftDataList: [],
      sizeSelect: false,
      sizeIsActiVe: null,
      sizeOption: null,
      craftOption: [],
      arr: [],
      numInput:'',
      selectedOptionId:'',//选中项下标
    };
  },
  watch: {
    materials: function (val) {
      this.relationsData(this.materials.children[0]);
    },
    sizeData: function (val) { 
      this.relationsData(this.materials.children[0]);
    },
    craftData: function (val) {
      this.craftData[0].id = -1
      this.relationsData(this.materials.children[0]);
    },
    numChangeIndex: function (val) {
      this.materialIsActiVe = val;
    },
  },
  created() {
    /** 尺寸数组增加对应参数 */
    if (this.sizeData.beforeShowType.label != null) {
      console.log(this.sizeData.beforeShowType)
      for (let i = 0; this.sizeData.beforeShowType.label.length > i; i++) {
        this.sizeParams.push({ size: "" });
      }
    }
    if (this.materials) {
      for (let i = 0; this.materials.children.length > i; i++) {
        if (
          null != this.materials.children[i].notes &&
          "" != this.materials.children[i].notes
        ) {
          this.materials.children[i].notesShow = true;
        } else {
          this.materials.children[i].notesShow = false;
        }
      }
    }
  },
  mounted() {
    if(this.type ==0){
      this.materialsClick(this.materials.children[0], 0);
    }
    if (this.parent == 1) {
    } else if (this.parent == 0) {
      this.$emit("numIndex", this.materialIsActiVe);
    }
    this.initializeCheckChange();
  },
  methods: {
    async relationsData(val,material) {
      let that = this;
      let arr = []
      // 默认加载材料选择第一个参数 以及选择第一个时所展示工艺禁选以及必选
      // this.materialList[0].checked = true;
      //判断当为材料时，数据清空；反之，累加
      let a 
      if(material == 1){
        a = []
      }else{
        a = JSON.parse(localStorage.getItem('arr'))
      }
      let b = [{priceId: val.price.id,ralationItem: val.id, parentTreeId: val.parentTreeId,name:val.name}]
      const c = [...a, ...b]
      let arry = []
      for(let item of c) {
        if(item.parentTreeId == val.parentTreeId) {
          if(item.ralationItem == val.id) {
            arry.push(item)
          }
        } else {
          arry.push(item)
        }
      }
      localStorage.setItem('arr',JSON.stringify(arry))
      let arry2 = []
      arry.map((item) => {
        arry2.push({priceId: item.priceId,ralationItem: item.ralationItem})
      })
      let parame = {
        prodcutCode: val.prodcutCode,
        itemDatas: arry2
      };
      const { data: res } = await getRelationsData(parame);
      let datas = res.data;
      // 禁选 以及 禁选长度
      let nRelations = datas.nRelations
      let nRelationsLen = datas.nRelations.length
      // 必选 以及 必选长度
      let yRelations = datas.yRelations
      let yRelationsLen = datas.yRelations.length
      if(nRelationsLen > 0){
        for(let y = 0; nRelationsLen > y; y++){
          if(nRelations[y].settingItem == 2){//尺寸的联动  禁选
            for(let s = 0;s<that.$store.state.sizeListes.length;s++){
              if(that.$store.state.sizeListes[s].id ==  nRelations[y].settingId ){
                that.$store.state.sizeListes[s].disabled = true
              }
            }
          }else if(nRelations[y].settingItem == 3){ // 工艺的联动
            for (let i = 0; that.$store.state.craftData.length > i; i++) {
              for (
                let o = 0;
                that.$store.state.craftData[i].children.length > o;
                o++
              ) {
                if (that.$store.state.craftData[i].children[o].id ==nRelations[y].settingId) {
                  that.$store.state.craftData[i].children[o].disabled = true;
                }
              }
            }
            
          }else{
            that.$store.state.sizeListes.map((data) => {
              data.checked = false;
              data.disabled = false;
            });
            that.$store.state.craftData = []
            for (let i = 0; that.$store.state.craftData.length > i; i++) {
              for (let o = 0;that.$store.state.craftData[i].children.length > o;o++) {
                that.$store.state.craftData[i].children[o].disabled = false;
              }
            }
          }
        }
      }else{
        that.$store.state.sizeListes.map((data) => {
          data.disabled = false;
        });
        for (let i = 0; that.$store.state.craftData.length > i; i++) {
          for (
            let o = 0;
            that.$store.state.craftData[i].children.length > o;
            o++
          ) {
            that.$store.state.craftData[i].children[o].disabled = false;
          }
        }
      }

    },
    checkChange(data, index) {
      console.log(data)
      let radioOptionList = []
      let arr = [];
      data.checked = !data.checked;
      for (let i = 0; this.$store.state.craftDataList.length > i; i++) {
        arr.push({ id: "", type: "", children: [] });
      }
      this.$store.state.craftDataList.map((item, index) => {
        arr.map((val, arrindex) => {
          if (index == arrindex) {
            val.id = item;
          }
        });
      });
      if(!data.checked == false){
        let material = 0
        this.relationsData(data,material);
          radioOptionList.push({
          id: data.id,
          type: "",
          children: []
        });
        this.$emit("priceParamsFrom", radioOptionList, 3)
      }else{
        console.log(this.$store.state.craftDataList)
      }
    },
    initializeCheckChange() {
      let arr = [];
      for (let i = 0; this.$store.state.craftDataList.length > i; i++) {
        arr.push({ id: "", type: "", children: [] });
      }
      this.$store.state.craftDataList.map((item, index) => {
        arr.map((val, arrindex) => {
          if (index == arrindex) {
            val.id = item;
          }
        });
      });
      this.$emit("priceParamsFrom", arr, 3);
    },
    // 点击自定义
    sizeDefined(e) {
      this.sizeSelect = e
      if (e == true) {
        this.sizeParams.map((val) => {
          val.size = null;
        });
        this.$emit("priceParamsFrom", this.sizeParams, 2);
      } else {
        this.sizeOption = null;
        this.$emit("priceParamsFrom", this.sizeOption, 2);
      }
    },
    // 输入框失去焦点
    sizeInputChange(e, index) {
      console.log('输入框：',e)
      for (let i = 0; this.sizeParams.length > i; i++) {
        if (this.sizeParams[i] == index) {
          this.sizeParams[i].size = e;
        }
      }
      this.$emit("priceParamsFrom", this.sizeParams, 2);
    },
    // 尺寸下拉失去焦点
    sizeOptionBlur(e) {
      console.log('下拉事件：',e)
      console.log(e)
      let material = this.parent
      if(this.id == 1){
        for (let i = 0;this.materials.children.length > i; i++) {
          if(this.materials.children[i].id == e){
            this.relationsData(this.materials.children[i],material);
            this.$emit("priceParamsFrom", this.materials.children[i], 7);
          }
        }
      }else{
        for (let i = 0;this.materials.children.length > i; i++) {
        console.log("尺寸：",this.materials.children[i])
          if(this.materials.children[i].id == e){
            this.relationsData(this.materials.children[i],material);
            this.$emit("priceParamsFrom", this.materials.children[i], 2);
          }
        }
      }
    },
    radioChangeId1(val) {
      let that = this
      for (let i = 0; this.materialList.length > i; i++) {
        if (this.materialList[i].id == val.id) {
          this.materialList[i].checked = !this.materialList[i].checked;
        } else {
          this.materialList[i].checked = false;
        }
      }
      let radioOptionList = [];
      radioOptionList.push({
        id: val.id,
        type: "",
        children: []
      });

      let material = that.parent 
      this.relationsData(val,material);   
      that.$emit("priceParamsFrom", radioOptionList, 6);
    },
    radioChange(val) {
      let that = this
      let a = JSON.parse(localStorage.getItem('arr'))
      let b = [{priceId: val.price.id,ralationItem: val.id, parentTreeId: val.parentTreeId,name:val.name}]
      const c = [...a, ...b]
      let arry = []
      for(let item of c) {
        if(item.parentTreeId == val.parentTreeId) {
          if(item.ralationItem == val.id) {
            arry.push(item)
          }
        } else {
          arry.push(item)
        }
      }
      localStorage.setItem('arr',JSON.stringify(arry))

      let arry2 = []
      arry.map((item) => {
        arry2.push({priceId: item.priceId,ralationItem: item.ralationItem})
      })
      let parame = {
        prodcutCode: val.prodcutCode,
        itemDatas: arry2
      };
      
      getRelationsData(parame).then((res) => {
        let datas = res.data.data
        let checkArr = [];
        let radioData = null;
        // 处理禁选必选事件
        for (let i = 0; that.$store.state.craftData.length > i; i++) {
          for (
            let o = 0;
            that.$store.state.craftData[i].children.length > o;
            o++
          ) {
            if (datas.nRelations.length > 0) {
              for (let y = 0; datas.nRelations.length > y; y++) {
                if (
                  that.$store.state.craftData[i].children[o].id ==
                  datas.nRelations[y].settingId
                ) {
                  that.$store.state.craftData[i].children[o].disabled = true;
                }
              }
            } else {
              that.$store.state.craftData[i].children[o].disabled = false;
            }
          
          }
        }
        that.$store.state.radioCode = radioData;
      })
      for (let i = 0; this.craftData.length > i; i++) {
        if (this.craftData[i].id == val.id) {
          this.craftData[i].checked = true;
        } 
      }
     
      this.$emit("priceParamsFrom", val, 8);
      
    },

    craftInputChange(e){
      this.$emit("priceParamsFrom", e, 9);
    },
    // 按钮事件
    materialsClick(val, index) {
      
      this.materialIsActiVe = index;
      this.materialIsActiVes = index
      let material = this.parent
      this.sizeSelect = true
      if (this.parent == 1) {
        console.log('点击按钮：',val)
        this.relationsData(val,material);
        this.$emit("priceParamsFrom", val.id, 1)

      } else if (this.parent == 0) {
        this.$emit("priceParamsFrom", val, 4);
        this.relationsData(val,material);
        
      }
    },

    //数量  输入框
    getInputNumber(e){
      this.$emit("priceParamsFrom", e,5);
    },
    sizeValueClick(val, index) {
      this.sizeIsActiVe = index;
      this.$emit("priceParamsFrom", val.name,5);
      this.numInput = val.name
    },
    // 示例图
    sampleOpen(id){
      getAttrFile({id:id}).then((res) => {
      })
    },
    //工艺 单选/多选-- 下拉框
    radioCraftOptionBlur(e){
      //保存 选中下拉框的下标
      this.selectedOptionId = e
    },
    //下拉框关闭事件
    radioCraftOptionBlurItem(e,item,type,data,items){
      if(!e){
        let index = this.selectedOptionId
        let priceId = item[index].price.id
        let ralationItem = item[index].id
        let parentId = items.id
        let grandpaId = data.id
        let dataList = {
          parentId:parentId,
          grandpaId:grandpaId,
          childrenId:ralationItem,
          len:data.children.length,
          name:data.name,
          names:item[index].name,
          type:type
        }
        this.$emit("priceParamsFrom", dataList, 8);

        let changeItems = []
      //判断：单选/多选 下面的子集;单选 type == 1;反之为2
      if(type == 1){
        let prodcutCode = localStorage.getItem('radioChangeItemProdcutCode') || this.$store.state.prodcutCode
        changeItems ={
          priceId: priceId,
          ralationItem: ralationItem,
          prodcutCode:prodcutCode
        }
      }else{
        let prodcutCode = localStorage.getItem('radioChangeItemProdcutCode') || this.$store.state.prodcutCode
        changeItems ={
          priceId: priceId,
          ralationItem: ralationItem,
          prodcutCode:prodcutCode
        }
      }
      this.dropdownContents(index,changeItems,data)
      }
     
    },
    // 下拉框选中选项的禁选/必选返回值
    dropdownContents(index,changeItems,data){
      let craftDataList = this.$store.state.craftData
      let nRelations = []
      getCraftRelationsByParam(changeItems).then((res) => {
        nRelations = res.data.data.nRelations
        if(nRelations.length > 0){
          for(let i = 0;i < craftDataList.length;i++){
            for(let j = 0; j < craftDataList[i].children.length;j++ ){
              if(craftDataList[i].children[j].id == data.id){
                for(let e =0;e<craftDataList[i].children[j].children.length;e++){
                  for(let h =0;h<craftDataList[i].children[j].children[e].children.length;h++){
                    this.$store.state.craftData[i].children[j].children[e].children[h].disabled = null
                    for(let n = 0; nRelations.length > n;n++){
                      if(craftDataList[i].children[j].children[e].children[h].id == nRelations[n].settingId){
                        this.$store.state.craftData[i].children[j].children[e].children[h].disabled = true
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.utilBox {
  .value {
    margin-right: 10px;
    border: 1px solid rgba(206, 206, 206, 1);
    border-radius: 4px;
    margin-top: 10px;
    .valueMaterials {
      position: relative;
      padding: 10px 20px;
      width: 150px;
      .imgItem {
        margin-right: 5px;
      }
      .sample {
        border-radius: 0px 4px 0px 4px;
        padding: 2px 5px;
        position: absolute;
        font-size: 10px;
        top: 0;
        right: 0;
        color: rgba(51, 51, 51, 1);
        background-color: #cecece;
      }
      .noSample {
        color: #fff;
        background-color: #d54343;
      }
    }
    .sizeValue {
      padding: 11px 30px;
    }
    .sizeValues{
      padding: 11px 0px;
      width: 100px;
    }
  }
  .values{
    position: relative;
    top: -5px;
  }
  /deep/.el-tooltip__popper {
    max-width: 200px !important;
    line-height: 32px !important;
  }

  .materialIsActiVe {
    border: 1px solid rgba(213, 67, 67, 1);
    color: rgba(213, 67, 67, 1);
  }
  .materialIsActiVes{
    border: 1px solid rgba(213, 67, 67, 1);
    color: rgba(213, 67, 67, 1);
  }

  .sizeItemBox:last-child {
    .multiplication {
      display: none;
    }
  }
  .butBg {
    background-color: #d54343;
  }
  .sizeItemBox {
    width: 150px;
    margin-right: 15px;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #d54343;
    border-color: #d54343;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: #d54343;
  }
  /deep/.el-checkbox__label {
    color: #333;
  }
  /deep/.el-radio__inner:hover {
    border-color: #d54343;
  }
  /deep/.el-radio__label {
    color: #333;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #d54343;
    border-color: #d54343;
  }
  /deep/.el-input__inner:focus {
    border-color: #d54343 !important;
  }
  /deep/.el-radio {
    display: flex;
    align-items: center;
  }
  /deep/.el-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  /deep/.el-checkbox__label {
    display: flex;
    align-items: center;
  }
  /deep/.el-radio__label {
    display: flex;
    align-items: center;
  }
  /deep/.el-tooltip__popper {
    max-width: 200px !important;
    line-height: 32px !important;
  }
}
</style>