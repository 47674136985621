<template>
  <div>
    <div class="contentBox">
      <div class="flex-c">
        <div class="vertical"></div>
        <p style="font-weight: 600;">账户信息</p>
      </div>
      <div>
        <div class="labelBox flex-c">
          <p class="label">充值账户：</p>
          <p>{{ userInfo.name }}</p>
        </div>
        <div class="labelBox flex-c">
          <p class="label">账户余额：</p>
          <p style="color: #d54343; font-size: 22px;font-family: 'FangZhengZhengZhunHei';">￥{{balance}}</p>
        </div>
      </div>
    </div>
    <div class="contentBox">
      <div class="flex-c">
        <div class="vertical"></div>
        <p style="font-weight: 600;">充值金额</p>
      </div>
      <div class="moneyBox flex-c">
        <div class="itemBox" v-for="(item, index) in list" :key="index">
          <div class="itemRecharge">
          <!-- <div> -->
            <div class="flex-c giveBox" v-if="item.amountGift !== null">
              <img src="../../../static/coupon/giveprice.png" alt="" />
              <p>{{ item.amountGift }}</p>
            </div>
            <div class="flex-c giveBox" v-if="item.giftCoupon !== null">
              <img src="../../../static/coupon/givecoupon.png" alt="" />
              <p>{{ item.giftCoupon }}</p>
            </div>
          </div>
          <div
            class="tab-item cursor"
            @click="priceTab(item, index)"
            :class="priceIndex == index ? 'tab-active' : ''"
          >
            <p style="font-family : 'FangZhengZhengZhunHei';">{{ item.amount }}</p>
            <div class="triangle" v-if="priceIndex == index">
              <img class="bottom-payment-con-selected" src="../../../static/icon/selected.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="customMoney">
        <p>自定义金额：</p>
        <el-input class="customIpt" v-model="input" @input="newInput" placeholder="自定义金额" />&nbsp;&nbsp;元
      </div>
    </div>
    <div class="contentBox">
      <div class="flex-c">
        <div class="vertical"></div>
        <p style="font-weight: 600;">支付方式</p>
      </div>
      <div class="bottom-payment-con">
        <p
          v-for="(item, index) in paymentList"
          :key="index"
          :class="isActiVe == index ? 'isActiVe' : ''"
          @click="payTab(index)">
          <img :src="item.img" alt="">
          <img
            v-if="isActiVe == index ? 'isActiVe' : ''"
            class="bottom-payment-con-selected"
            src="../../../static/icon/selected.png"
            alt="">
        </p>
      </div>
      <div class="patBtn">
        <p class="cursor pay" @click="pay">立即支付</p>
      </div>
    </div>
    <hbw-diaLog
      v-if="dialog"
      title="确认支付"
      @change="dialogCancel($event)"
    >
    <div>
      <p>充值金额：<span style="font-size: 20px;font-weight: 600;color: #d54343;">{{ this.input }}</span> 元</p>
      <img :src="url" alt="">
      <p>请使用微信或支付宝扫一扫扫描二维码支付</p>
    </div>
    </hbw-diaLog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import {priceList, payList, rechargePay, userBalance} from "../../api/connectorList";
import {closeWs, createSocket, sendWSPush} from "@/utils/websocket";
export default {
  data() {
    return {
      isActiVe: 0,
      dialog:false,
      priceId: null,
      price:null,
      priceIndex: 0,
      list: [],
      payId: null,
      payIndex: 0,
      payLists: [],
      userInfo:null,
      url:null,
        type:1,
      paymentList: [
        {
          img: require('../../../static/icon/bankCard.png'),
        }
      ],
      input: '',
      balance:null
    };
  },
  created(){
    this.userInfo = JSON.parse(localStorage.getItem('user'))
    console.log(this.userInfo,"this.userInfo");
    if(this.userInfo!=null&&this.userInfo.id!=undefined&&this.userInfo.id!=''){
      this.getUserBalance(this.userInfo.id);
    }
    this.getPriceList();
    this.getPayList();
  },
  mounted() {
  },
  methods: {
    getPriceList() {
      priceList().then((res) => {
        if (res.data.code == 200) {
          this.list = [...res.data.data];
          this.priceId = this.list[0].id;
          this.price = this.list[0].amount;
          this.input = this.list[0].amount;
        }
      });
    },
    getPayList() {
      payList({ name: "PayType" }).then((res) => {
        if (res.data.code == 200) {
          let arr = []
          arr.push({code: 1, payType: '通联收银台'})
          this.payLists = arr
          this.payId = '1'
        }
      });
    },
    dialogCancel(val) {
      this.dialog = val;
    },
    priceTab(data, index) {
      this.input = data.amount
      this.priceId = data.id;
      this.price = data.amount;
      this.priceIndex = index;
        this.type = 1;
    },
    payTab(index) {
      this.isActiVe = index;
    },
    newInput(val) {
      this.priceIndex = -1
      this.priceId = null
      this.input = val
        this.type=0
    },
    getUserBalance(id){
      userBalance({userId:id}).then((res) => {
        this.balance = res.data.data.accountBalance
      })
    },
    pay(){
      if(this.isActiVe == -1) {
        this.$message({
          message: "请选择支付方式",
          type: "warning",
        });
        return
      } else {
        let parames = {
          memberId:this.userInfo.id,
          type:this.type,
          rechargeManagerId:this.priceId,
          amount:this.input * 100,
          body:'充值'
        }
        createSocket()
        this.getsocketData = (e) => {
          let data = e && e.detail.data
          if(data){
            let json = JSON.parse(data);
            console.log(json,"json")
            if(json.status == '03') {
              this.$message({
                message: "支付成功",
                type: "success"
              });
              this.$router.push({ query: {} })
              this.$router.go(0)
              closeWs()
            }
            if (json.event == 'open') {
              let msg = {"event":'open_screen',"to_user":this.memberId}
              sendWSPush(msg)
            }
          }
        }
        window.addEventListener('onmessageWS', this.getsocketData)
        rechargePay(parames).then((res) => {
          console.log('支付成功'.res)
          if(res.data.code == 200) {
            this.dialog = true
            this.url = res.data.msg
            // this.$message({
            //   message: "支付成功",
            //   type: "success"
            // });
          }

        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
  .contentBox {
    padding: 19px 22px;
    background-color: #fff;
    margin-bottom: 16px;
    .labelBox {
      margin-top: 25px;
      .label {
        color: #999;
      }
    }
    .moneyBox {
      flex-wrap: wrap;
      margin-top: 5px;
      .itemBox {
        margin-right: 30px;
        margin-top: 10px;
        .giveBox {
          margin-bottom: 5px;
          color: #999;
          display: flex; 
          align-items: center;
          width: 235px;
          img {
            margin-right: 5px;
          }
        }
        .itemRecharge{
          min-height: 62px;
          // margin-bottom: 12px;
        }
      }
      .tab-item {
        position: relative;
        box-sizing: border-box;
        padding: 11px 0;
        border: 1px solid #bebebe;
        width: 235px;
        text-align: center;
        font-size: 20px;
        color: #333;
      }
      .tab-active {
        border: 1px solid rgba(213, 67, 67, 1);
        color: rgba(213, 67, 67, 1);
      }
      .triangle {
        position: absolute;
        right: 0;
        bottom: -5px;
        // width: 0;
        // height: 0;
        // // border-bottom: 26px solid #d54343;
        // // border-left: 26px solid transparent;
        // bottom: 0px;
        // right: 0;
        .check {
          width: 18px;
          height: 18px;
          position: absolute;
          bottom: -28px;
          left: -18px;
        }
      }

    }
    .patBtn {
      margin-top: 30px;
      color: #fff;
      .pay {
        border-radius: 4px;
        padding: 10px 0;
        text-align: center;
        width: 140px;
        background-color: rgba(213, 67, 67, 1);
      }
      .pay:hover{
        background-color: #B72C2C;
      }
    }
    .bottom-payment-con {
      margin-top: 20px;
      p {
        width: 200px;
        height: 75px;
        border: 1px solid #fff;
        position: relative;
        img {
          background-size: 100%;
          border: none;
        }
        .bottom-payment-con-selected {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 30px;
          height: 30px;
        }
      }
      .isActiVe {
        border: 1px solid #D54343;
      }
    }
    // 自定义金额
    .customMoney{
      display: flex;
      align-items: center;
      margin-top: 20px;
      color: #999;
      p{
        width: 100px;
      }
      .customIpt{
        margin-top: 0px;
        width: auto;
      }
    }
  }
  /deep/ .el-input {
    margin-top: 20px;
  }
  /deep/ .el-input__inner {
    width: 235px;
  }
  /deep/.el-input__inner:focus {
    border: #D54343 1px solid;
  }
</style>
