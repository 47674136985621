<template>
  <div class="assForm">
    <div class="assForm-header">
      <p @click="goBack">我的订单</p>
      <p>  >  申请售后</p>
    </div>
    <div class="step">
      <hb-steps
        :active="active"
        :assShow="assShow">
      </hb-steps>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ass-ruleForm">
      <el-form-item label="订单编号：" prop="orderCode">
        <el-input v-model="ruleForm.orderCode" disabled :placeholder="orderData.orderCode"></el-input>
      </el-form-item>
      <el-form-item label="售后产品：" prop="products">
        <span>已选{{ ruleForm.products.length }}个产品</span>
        <div class="list-nav">
          <span style="width: 570px;padding-left: 30px;">产品明细</span>
          <span style="width: 120px;text-align: center;">产品价格</span>
        </div>
        <div class="list-tr">
          <el-checkbox-group
            v-model="ruleForm.products"
            v-for="(item,index) in orderData.items"
            :key="index"
            @change="checked(item.id)">
            <el-checkbox :label="item">
              <p style="display: flex;width: 678px;">
                <span style="width: 550px;">{{ item.productDetail }}</span>
                <span style="width: 114px;text-align: center;" v-if="item.productPrice">￥{{ item.productPrice }}</span>
              </p>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <div style="color: #D54343;margin-left: 100px;margin-bottom: 10px;">售后客服会联系您共同协商处理结果，请认真填写以下信息，并保持电话畅通。</div>
      <el-form-item label="售后类型：" prop="region">
        <el-select
          v-model="ruleForm.region"
          placeholder="请选择售后类型"
          @change="selecT">
          <el-option
            v-for="item in assType"
            :key="item.id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="问题描述：" prop="desc">
        <el-input type="textarea" v-model="ruleForm.desc" placeholder="请详细描述您的问题，以便我们更快的给您处理售后"></el-input>
      </el-form-item>
      <el-form-item label="上传凭证：" prop="imgurl">
        <span style="color: #D54343;">为了快速为您解决售后问题，请上传成品出现问题的局部照片和整体照片等。</span>
        <el-upload
          :action="baseUrl + '/file/upload'"
          :headers="headers"
          list-type="picture-card"
          :limit="limit"
          :on-success="fileSuccess"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="beforeUpload">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div style="color: #D54343;
          width: 460px;
          height: 40px;
          border-radius: 4px;
          line-height: 40px;
          background-color: #fff3f3;
          margin-top: 20px;
          padding: 0 12px;
          display: flex;
          align-items: center;">
          <img style="width: 18px;height: 18px;margin-right: 10px;" src="../../../static/icon/warning.png" alt="">
          <span>支持jpg/png/tif格式的图片，不大于10M。最多上传5张。</span>
        </div>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </el-form-item>
      <el-form-item label="联系电话：" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="联系电话"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :disabled='submitDisabled' @click="throttle(()=>submitForm('ruleForm'),500)">提交申请</el-button>
        <div style="height: 100px;"></div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  //import project from "../../../public/project.config.json";
  import {
    getById,
    payList,
    createApplyAfterSale
  }from "../../api/connectorList";
  export default {
    props: ['assId'],
    data() {
      let checkPhone = (rule, value, callback) => {
        let reg = /^1[345789]\d{9}$/
        if (!reg.test(value)) {
          callback(new Error('请输入11位手机号'))
        } else {
          callback()
        }
      }
      return {
        active: 0,
        assShow: true,
        ruleForm: {
          orderCode: '',
          region: '',
          desc: '',
          phone: '',
          products: [],
          imgurl:'',
        },
        orderData: '',
        afterSalesType: 'AfterSalesType',
        assType: [],
        checkList: [],
        rules: {
          region: [
            { required: true, message: '请选择售后类型', trigger: 'change' }
          ],
          desc: [
            { required: true, message: '请填写问题描述', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: "请输入手机号", trigger: "blur" },
            { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
          ],
          products: [
            { required: true, message: "请选择产品", trigger: 'change' }
          ],
          imgurl: [{required: true,message: '请上传图片', trigger: 'change'}]
        },
        limit: 5,
        baseUrl: '',
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        fileListDate: [],
        dialogImageUrl: '',
        dialogVisible: false,
        orderDetailIds: [],
        afterType: '',
        submitDisabled: false, // 防止在重复提交
      };
    },
    created() {
      this.baseUrl = project.baseUrl;
    },
    mounted() {
      this.getById()
      this.payList()
    },
    methods: {
      goBack() {
        this.$router.go(0)
      },
      getById() {
        getById({id:this.assId}).then((res) => {
          if(res.data.code == 200) {
            this.orderData = res.data.data
          }
        })
      },
      payList() {
        payList({name:this.afterSalesType}).then((res) => {
          if(res.data.code == 200) {
            this.assType = res.data.data
          }
        })
      },
      selecT(item) {
        this.afterType = item.value
      },
      checked(id){
        this.orderDetailIds.push(id);
      },
      fileSuccess(res,file) {
        this.ruleForm.imgurl = res.data.fileName;
        this.fileListDate.push(res.data.id)
        this.$refs.ruleForm.validateField('imgurl')
      },
      beforeUpload(file) {
        var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        const fileTypeArr = ['png','PNG','jpg','JPG','jpeg','JPEG'];
        const extension = fileTypeArr.some(item => testmsg === item);
        if (!extension ) {
          this.$message({
            message: "只能上传jpg/png格式的图片!",
            type: "warning",
          });
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M ) {
          this.$message({
            message: "上传文件大小不能超过 10M!",
            type: "warning",
          });
        }
        return extension && isLt10M;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleRemove(file) {
        const fileId = file.response.data.id;
        const i = this.fileListDate.findIndex(x => x === fileId);
        this.fileListDate.splice(i, 1)
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.submitDisabled){
              return;
            }
            this.submitDisabled = true;
            let fileIds = this.fileListDate.toString()
            let afterSalesType = this.afterType.toString();
            let newArr = [...new Set(this.orderDetailIds)].join(',');
            let params = {
              orderId: this.assId,
              orderDetailIds: newArr,
              fileIds: fileIds,
              afterSalesType: afterSalesType,
              problemDesc: this.ruleForm.desc,
              phone: this.ruleForm.phone
            }
            createApplyAfterSale(params).then((res) => {
              if(res.data.code == 200) {
                this.$router.push({path: 'assOrder',query: {id:this.assId}})
              }
            })
          } else {
            return false;
          }
        })
      }
    },
  }
</script>
<style lang="less" scoped>
  .assForm-header {
    display: flex;
    align-items: center;
    height: 48px;
    p:first-child {
      cursor: pointer;
      font-weight: 600;
    }
  }
  .step {
    padding: 30px 30px;
    background: #fff;
  }
  .ass-ruleForm {
    background: #fff;
    padding: 0 40px;
    .list-nav {
      width: 690px;
      height: 50px;
      margin-top: 10px;
      padding: 0 20px;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      span {
        color: #333;
        font-weight: 600;
      }
    }
    .list-tr {
      width: 688px;
      // display: flex;
      // align-items: center;
      padding: 20px 20px;
      min-height: 100px;
      border: 1px solid #EBEBEB;
    }
  }
  /deep/ .el-input, .el-textarea {
    width: 730px;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #d54343;
    border-color: #d54343;
  }
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #d54343;
  }
  /deep/ .el-button--primary {
    color: #FFF;
    background-color: #D54343;
    border-color: #D54343;
  }
  /deep/ .el-button--primary:hover {
    background-color: #B72C2C;
    border-color: #B72C2C;
  }
</style>
