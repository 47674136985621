<template>
  <div class="invoicePay">
    <div class="invoicePay-nav">
      <p @click="goBack">我的发票</p>
      <p>  >  申请发票</p>
    </div>
    <div class="invoicePay-step">
      <hb-steps
        :active="active"
        :invoiceFormShow="invoiceFormShow">
      </hb-steps>
    </div>
    <div class="invoicePay-con">
      <p>
        <span>支付方式：</span>
        <img src="../../../static//icon/bankCardChecked.png" alt="">
      </p>
      <p>
        <span>税费：</span>
        <span style="color: #D54343;">￥{{ payList.billingTax/100 }}</span>
      </p>
      <p>
        <span></span>
        <span class="button" @click="submit">确认支付</span>
      </p>
    </div>
    <hbw-diaLog
      v-if="dialog"
      title="确认支付"
      @change="dialogCancel($event)"
    >
      <div>
        <img :src="url" alt="">
        <p>请使用微信或支付宝扫一扫扫描二维码支付</p>
      </div>
    </hbw-diaLog>
  </div>
</template>
<script>
  import {
    applyBill,
    payTaxes
  } from "../../api/connectorList";
  import { createSocket,sendWSPush,closeWs } from '../../utils/websocket'
  export default {
    props: ['payList'],
    data() {
      return {
        active: 2,
        invoiceFormShow: true,
        isActiVe: '-1',
        dialog:false,
        url: null
      };
    },
    created() {
      this.userInfo = JSON.parse(localStorage.getItem("user"));
    },
    methods: {
      goBack() {
        this.$router.go(0)
      },
      dialogCancel(val) {
        if(val == false) {
          this.open()
        }
      },
      open() {
        this.$confirm('确定要取消支付吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '已取消支付!'
          });
          this.dialog = false
          this.$router.go(0)
        }).catch(() => {
          this.dialog = true
        });
      },
      submit() {
        let params = this.payList
        console.log(params)
        createSocket()
          this.getsocketData = (e) => {
          let data = e && e.detail.data
          if(data){
            let json = JSON.parse(data)
            console.log('json',json)
            if(json.status == '03') {
              this.$message({
                message: "支付成功",
                type: "success"
              });
              // applyBill(params).then((res) => {
              //   if(res.data.code == 200) {
              //     this.$router.go(0)
              //     closeWs()
              //   }
              // })

                this.$router.go(0)
                closeWs()
            }
            if (json.event == 'open') {
              let msg = {"event":'open_screen',"to_user":this.userId}
              sendWSPush(msg)
            }
          }
        }
        window.addEventListener('onmessageWS', this.getsocketData)
        payTaxes(params).then((res) => {
          if(res.data.code == 200) {
            this.dialog = true
            this.url = res.data.msg
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .invoicePay {
    padding: 10px 0;
    .invoicePay-nav {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p:first-child {
        cursor: pointer;
        font-weight: 600;
      }
    }
    .invoicePay-step {
      background: #fff;
      padding: 50px 0 30px 0;
    }
    .invoicePay-con {
      margin-top: 12px;
      background: #fff;
      padding: 10px 0 200px 0;
      p {
        display: flex;
        align-items: center;
        margin: 50px 0;
        img {
          width: 200px;
          height: 74px;
        }
        span:first-child {
          width: 140px;
          text-align: right;
          font-size: 16px;
          color: #333;
        }
        .button {
          width: 120px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 12px;
          color: #fff;
          background: #d54343;
        }
      }
    }
  }
</style>
