<template>
  <div class="user">
    <div class="userBox flex-j-sb">
      <div class="userInfoBox InfoBox flex-j-sb">
        <div class="userInfo flex-j-sb">
          <div>
            <img src="../../../../static/icon/null_avatar.png" alt="" />
          </div>
          <div style="margin-left: 22px">
            <div style="width: 60px" class="certified flex-j-sb" v-show="'2' == memberAuth.authStatus">
              <img src="../../../../static/icon/star.png" alt="" />
              <p style="margin-left: 1px">已认证</p>
            </div>
            <div style="width: 60px" v-show="'2' != memberAuth.authStatus" class="certified noCertified flex-j-sb">
              <img src="../../../../static/icon/star_null.png" alt="" />
<!--              {{memberAuth.authStatus=='0'?'未认证':memberAuth.authStatus=='1'?'待审核':memberAuth.authStatus=='3'?'已驳回':''}}-->
              <p style="margin-left: 1px">未认证</p>
            </div>
            <p style="font-size: 20px">{{userInfo.name}}</p>
          </div>
        </div>
        <div class="infoBtn">
          <div v-if="memberAuth.authStatus!='2'" class="certification enterprise" @click="toAuth">企业认证</div>
          <div class="certification certificationEdit" @click="toAttestation">修改</div>
        </div>
      </div>
      <div class="moneyInfoBox InfoBox flex-j-sb">
        <div class="moneyInfo-item">
          <p class="money">￥{{balance}}</p>
          <p>账户余额</p>
        </div>
        <div class="moneyInfo-item">
          <p class="money">{{couponNum}}</p>
          <p>优惠券数量</p>
        </div>
        <div class="moneyInfo-item">
          <p class="recharge cursor" @click="toRecharge">充值</p>
          <p style="color: #D54343;">充值有惊喜哦！</p>
        </div>
      </div>
    </div>
    <div class="orderBox flex-j-sb">
        <div class="order-item" v-for="(item,index) in statistics" :key="item.id" @click="goToDetail(item.state)">
            <p class="num">{{item.num}}</p>
            <p>{{item.stateName}}</p>
        </div>
    </div>
  </div>
</template>

<script>
import { userBalance, userCoupon } from "../../../api/connectorList";
import { orderCount,enterprise } from "../../../api/user";
export default {
  data() {
    return {
      certified: false,
      userInfo:null,
      // 余额
      balance:null,
      couponNum:null,
      statistics:[],
      memberAuth:{
        id:'',
        memberId:'',
        companyName: "",
        checkStatus:'0',
        licImg: "",
        authStatus:'',
      }
    };
  },
  created(){
    this.userInfo = JSON.parse(localStorage.getItem('user'))
  },
  mounted(){
    this.getUserBalance(this.userInfo.id)
    this.getUserCoupon(this.userInfo.id)
    this.orderCount(this.userInfo.id);
    this.getEnterprise(this.userInfo.id);
    this.$store.state.navconRightIndex = 4
  },
  methods:{
    getEnterprise(id){
      enterprise({memberId:id}).then((res)=>{
            if(res.data.data!=null&&res.data.data.id!=null){
              this.memberAuth.id = res.data.data.id;
              this.memberAuth.memberId = res.data.data.memberId;
              this.memberAuth.companyName = res.data.data.companyName;
              this.memberAuth.authStatus = res.data.data.authStatus;
              this.memberAuth.licImg = res.data.data.licImg;
            }
      })
    },
    getUserBalance(id){
      userBalance({userId:id}).then((res) => {
        this.balance = res.data.data.accountBalance
      })
    },
    getUserCoupon(id){
      userCoupon({userId:id}).then((res) => {
        this.couponNum = res.data.data.unUseCount
      })
    },
    orderCount(id){
      orderCount({userId:id}).then((res) => {
        if(res!=null&&res.data!=null&&res.data.data!=null){
          this.statistics = res.data.data;
        }
      })
    },
    toRecharge(){
      this.$router.push({path:'recharge'})
    },
    //企业认证
    toAuth(){
      this.$router.push({path:'/userHome/attestation'})
    },
    //修改账号设置
    toAttestation(){
      this.$router.push({path:'/userHome/userSetting'})
    },
    // 跳转到指定订单页面
    goToDetail(state){
      this.eventBus.$emit('sendData','/userHome/myOrder');
      this.$router.push({
        path:'/userHome/myOrder',
        query: {
          state: state == null ? '5' : state
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.user {
  .userBox {
    .InfoBox {
      box-sizing: border-box;
      width: 49.5%;
      background: #fff;
    }
    .userInfoBox {
      padding: 35px 35px 35px 45px;

      .certified {
        padding: 5px 8px;
        border-radius: 4px;
        background-color: rgba(0, 184, 228, 0.19);
        color: rgba(0, 184, 228, 1);
        margin-bottom: 10px;
      }
      .noCertified {
        background-color: rgba(239, 239, 239, 1);
        color: rgba(154, 154, 154, 1);
      }
      .infoBtn {
        display: flex;
        flex-direction: row;
        .certification {
          padding: 8px 20px;
          background-color: rgba(0, 184, 228, 1);
          color: rgba(255, 255, 255, 1);
          text-align: center;
          border-radius: 4px;
        }
        .certificationEdit {
          margin-left: 10px;
          background-color: rgba(233, 157, 66, 1);
          cursor: pointer;
        }
        .certificationEdit:hover{
          background-color: #E6932F;
        }
        .enterprise{
          cursor: pointer;
        }
        .enterprise:hover{
          background-color: #00ACD5;
        }
      }
    }
    .moneyInfoBox {
      padding: 54px 63px 54px 75px;
      .moneyInfo-item {
        text-align: center;
        .money {
          font-family : 'FangZhengZhengZhunHei';
          font-size: 24px;
          color: #d54343;
          font-weight: 600;
          margin-bottom: 5px;
        }
        .recharge {
          padding: 8px 20px;
          background-color: rgba(213, 67, 67, 1);
          color: rgba(255, 255, 255, 1);
          text-align: center;
          border-radius: 4px;
          margin-bottom: 11px;
        }
        .recharge:hover {
          background: #B72C2C;
        }
      }
    }
  }
  .orderBox{
    box-sizing: border-box;
    padding: 42px 87px;
    margin-top: 15px;
    background: #fff;
    .order-item{
        text-align: center;
        cursor: pointer;
        .num{
            font-family : 'FangZhengZhengZhunHei';
            font-size: 30px;
            color: rgba(51, 51, 51, 1);
            margin-bottom: 11px;
        }
    }
  }
}
</style>
