<template>
  <div class="orderDetail" v-if="showForm">
    <div class="orderDetail-header">
      <p @click="goBack">{{$route.query.orderShow == 2 ? '售后订单':'我的订单'}}</p>
      <p>  >  订单详情</p>
    </div>
    <div class="orderDetail-list">
      <div class="step">
        <hb-steps
          :active="active"
          :orderTime="orderTime"
          :orderShow="orderShow">
        </hb-steps>
      </div>
      <div class="list-title">
        <p>
          <img src="../../../static/icon/warning.png" alt="">
          <span style="color: #D54343;" v-if="orderData.tradeOrderState == 10">当前订单状态：待付款</span>
          <span style="color: #D54343;" v-if="orderData.tradeOrderState == 11">当前订单状态：待分配</span>
          <span style="color: #D54343;" v-if="orderData.tradeOrderState == 12">当前订单状态：待生产</span>
          <span style="color: #D54343;" v-if="orderData.tradeOrderState == 13">当前订单状态：生产中</span>
          <span style="color: #D54343;" v-if="orderData.tradeOrderState == 14">当前订单状态：已完工</span>
          <span style="color: #D54343;" v-if="orderData.tradeOrderState == 15">当前订单状态：已发货</span>
          <span style="color: #D54343;" v-if="orderData.tradeOrderState == 16">当前订单状态：已完成</span>
          <span style="color: #D54343;" v-if="orderData.tradeOrderState == 17">当前订单状态：已取消</span>
        </p>
        <p>
          <span v-if="orderData.tradeOrderState == 10" @click="toPay">支付</span>
          <span v-if="orderData.tradeOrderState == 10 ||
            orderData.tradeOrderState == 11 ||
            orderData.tradeOrderState == 12
            " @click="cancellation">取消订单</span>
        </p>
        <p v-if="orderData.tradeOrderState == 16 && !orderData.productAfterStatus">
          <span @click="getAss(orderData.id,orderData.tradeOrderState)">申请售后</span>
        </p>
      </div>
      <div class="list-nav">
        <span style="width: 130px;">产品类别</span>
        <span style="width: 420px;">产品明细</span>
        <span style="width: 100px;">理论重量</span>
        <span style="width: 300px;">文件信息</span>
        <span style="width: 260px;">文件备注</span>
        <span style="width: 150px;">产品价格</span>
        <span style="width: 160px;">订单金额</span>
      </div>
      <div class="list-tr">
        <p style="width: 130px;">{{ orderData.productCategoryName }}</p>
        <div style="display: flex;flex-direction: column;padding: 20px 0px;">
          <div style="display: flex;align-items: center;margin-bottom: 8px;" v-for="(item, index) in orderData.items" :key="index">
            <p style="width: 420px;">{{ item.productDetail }}</p>
            <p style="width: 100px;">{{ item.theoryWeight }}kg</p>
            <p class="list-tr-img" style="width: 300px;">
              <!-- <img  :src="item.filePath" alt=""> -->
              <!-- <img v-if="item.fileSuffix == '.zip'" src="../../../static/icon/mo.png" alt="">
              <img v-if="item.fileSuffix == '.tif'" src="../../../static/icon/mo2.png" alt=""> -->
              <img class='fileTxt' v-if="!(item.fileCloudPath == null)" src="../../../static/icon/proview_img.png" alt="" @click="fileTypeArr.some(ele => item.fileSuffix === ele) ? maxImg(item.fileCloudPath) : ''">
              <span class='fileTxt' v-if="!(item.fileCloudPath == null)" :title="item.fileName" @click="fileTypeArr.some(ele => item.fileSuffix === ele)? maxImg(item.fileCloudPath) : ''">{{ item.fileName }}</span>
              <!-- <span :title="item.fileName">{{ item.fileName }}</span> -->
            </p>
            <p class="list-tr-more" style="width: 260px;">
              <span :title="item.fileRemark">{{ item.fileRemark }}</span>
            </p>
            <p style="width: 150px;">￥{{ item.productPrice }}</p>
          </div>
        </div>
        <p class="list-tr-price" style="width: 160px;">
          <span>￥{{ orderData.orderCountMoney }}</span>
          <span>货款：￥{{ orderData.goodsMoney }}</span>
          <span>运费：￥{{ orderData.moveMoney }}</span>
          <span>优惠：￥{{ orderData.favorable }}</span>
        </p>
      </div>
    </div>
    <div class="orderDetail-con">
      <div class="orderDetail-con-li">
        <div class="orderDetail-con-li-title">
          <span></span>
          <span>订单信息</span>
        </div>
        <p v-if="orderData.orderCode">
          <span>订单编号：</span>
          <span>{{ orderData.orderCode }}</span>
        </p>
        <p v-if="orderData.orderTime">
          <span>下单时间：</span>
          <span>{{ orderData.orderTime }}</span>
        </p>
        <p>
          <span>订单备注：</span>
          <span class="orderPhone" v-if="orderData.consumerPhone">{{ orderData.consumerPhone }}</span>
        </p>
      </div>
      <div class="orderDetail-con-li">
        <div class="orderDetail-con-li-title">
          <span></span>
          <span>收货信息</span>
        </div>
        <p v-if="orderData.distributionModeClassName">
          <span>交货方式：</span>
          <span>{{ orderData.distributionModeClassName }}</span>
        </p>
        <p v-if="orderData.consigneePerson">
          <span>收货人：</span>
          <span>{{ orderData.consigneePerson }}</span>
        </p>
        <p v-if="orderData.memberPhone">
          <span>手机：</span>
          <span>{{ orderData.memberPhone }}</span>
        </p>
        <p v-if="orderData.addressDesc">
          <span>收货地址：</span>
          <span>{{ orderData.addressDesc }}</span>
        </p>
        <p v-if="orderData.anticipateShippingTime">
          <span>预计出货时间：</span>
          <span>{{ orderData.anticipateShippingTime }}</span>
        </p>
      </div>
      <div class="orderDetail-con-li">
        <div class="orderDetail-con-li-title">
          <span></span>
          <span>配送信息</span>
        </div>
        <p v-if="orderData.dispatcherName || orderData.dispatcherPhone">
          <span>配送员：</span>
          <span style="color: #D54343;margin-right: 8px;">{{orderData.dispatcherName}}</span>
          <span style="color: #D54343;">{{ orderData.dispatcherPhone }}</span>
        </p>
        <p v-if="orderData.hwbNumber">
          <span>运单号：</span>
          <span style="color: #D54343;margin-right: 8px;">{{ orderData.hwbNumber }}</span>
        </p>
      </div>
    </div>
    <hbw-diaLog
      v-if="dialog"
      :title="addressTitle"
      @change="dialogCancel($event)">
      <div class="diaLog-con">
        <p>您确定要取消订单吗？</p>
        <p>
          <span @click="confirm">确定</span>
          <span @click="dialog = false">取消</span>
        </p>
      </div>
    </hbw-diaLog>
  </div>
</template>
<script>
  import {
    getById,
    cancelOrder
  }from "../../api/connectorList";
  export default {
    props: ["ids"],
    data() {
      return {
        active: 0,
        orderTime: '',
        showForm: false,
        orderData: '',
        orderShow: true,
        dialog: false,
        addressTitle: "取消订单确认",
        fileTypeArr: ['.jpg','.JPG','.png','.PNG','.JPEG','.jpeg']
      };
    },
    mounted() {
      this.getById()
    },
    methods: {
      getById() {
        getById({id:this.ids}).then((res) => {
          if(res.data.code == 200) {
            this.orderData = res.data.data
            this.showForm = true
            this.orderTime = res.data.data.orderTime
            let state = res.data.data.tradeOrderState
            if(state == 17) {
              this.active = 0
            } else if(state == 10) {
              this.active = 1
            } else if(state == 11 || state == 12) {
              this.active = 2
            } else if(state == 13) {
              this.active = 3
            } else if(state == 14) {
              this.active = 4
            } else if(state == 15) {
              this.active = 5
            } else if(state == 16) {
              this.active = 6
            }
          }
        })
      },
      cancellation() {
        this.dialog = true
      },
      dialogCancel(val) {
        this.dialog = val;
      },
      confirm() {
        cancelOrder({id:this.ids}).then((res) => {
          if(res.data.code == 200) {
            this.dialog = false
            this.$router.go(0)
          }
        })
      },
      toPay() {
        this.$emit('orderDetailShow', true, this.ids)
      },
      goBack() {
        if(this.$route.query.orderShow == 2){
          this.$router.go(-1)
        } else {
          this.$router.go(0)
        }
      },
      maxImg(file) {
        window.open(file+'?response-content-type=IMAGE/JPEG')
      },
      getAss(id,orderState) {
        this.$emit('orderDetailShow', false, id,orderState)
      }
    },
  }
</script>
<style lang="less" scoped>
.orderDetail{
  .orderDetail-header {
    display: flex;
    align-items: center;
    height: 34px;
    p:first-child {
      cursor: pointer;
      font-weight: 600;
    }
  }
  .orderDetail-list {
    padding: 30px 24px;
    background: #fff;
    margin: 14px 0 10px 0;
    box-sizing: border-box;
    .step {
      padding: 10px 0px 12px 0px;
    }
    .list-title {
      height: 76px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #F5F5F5;
      padding: 0 22px;
      p {
        display: flex;
        align-items: center;
      }
      p:first-child {
        color: #D54343;
        font-size: 18px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      p:last-child span {
        width: 100px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 12px;
        border-radius: 30px;
        cursor: pointer;
      }
      p:last-child span:first-child {
        background: #D54343;
        color: #fff;
        margin-right: 12px;
      }
      p:last-child span:last-child {
        background: #FFF9F2;
        border: 1px solid #E67E01;
        color: #E67E01;
      }
    }
    .list-nav {
      height: 50px;
      margin-top: 10px;
      padding: 0 20px;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        color: #333;
        font-weight: 600;
      }
    }
    .list-tr {
      display: flex;
      align-items: center;
      padding: 0 20px;
      min-height: 110px;
      border: 1px solid #EBEBEB;
      font-size: 14px;
      .list-tr-img {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          margin-right: 14px;
        }
        span {
          width: 200px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 2;
        }
      }
      .list-tr-more {
        span {
          width: 200px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 2;
        }
      }
      .list-tr-price {
        display: flex;
        flex-direction: column;
        span {
          font-size: 12px;
          color: #999;
        }
        span:first-child {
          color: #D54343;
          font-size: 18px;
        }
      }
    }
  }
  .orderDetail-con {
    min-height: 210px;
    display: flex;
    justify-content: space-between;
    .orderDetail-con-li {
      background: #fff;
      width: 33%;
      .orderDetail-con-li-title {
        height: 56px;
        padding: 0 22px;
        display: flex;
        align-items: center;
        span:first-child {
          width: 4px;
          height: 16px;
          background: #D54343;
          margin-right: 10px;
        }
        span:last-child {
          font-size: 14px;
          color: #333;
          font-weight: 600;
        }
      }
      p {
        padding: 0 22px;
        font-size: 12px;
        min-height: 22px;
        line-height: 22px;
        color: #999;
        word-wrap:break-word;
        word-break:break-all;
        .orderPhone {
          color: #000;
        }
      }
    }
  }
  .diaLog-con {
    font-size: 14px;
    p:first-child {
      font-weight: 600;
      color: #101010;
      padding: 40px 0 60px 0;
    }
    p:last-child {
      display: flex;
      justify-content: flex-end;
      span {
        width: 84px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
      span:first-child {
        background: #D54343;
        color: #fff;
        margin-right: 18px;
      }
      span:last-child {
        background: #efefef;
        color: #333;
      }
    }
  }
  .fileTxt{
    cursor: pointer;
  }
}
</style>
